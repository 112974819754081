<template>
    <Head title="Create Service Type" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.service-types.index')" class="breadcrumb-link">Service Types</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="edit-service-type-form" class="grid grid-cols-6 gap-4">
        <div class="col-span-6 grid grid-cols-2 gap-4">
            <text-input v-model="form.name" class="col-span-2 md:col-span-1" id="name" name="name" label="Name" :errors="props.errors.name" :disabled="props.serviceCount > 0" />
            <text-input v-model="form.type_description" class="col-span-2 md:col-span-1" id="type_description" name="type_description" label="Type Description" :errors="props.errors.type_description" />

            <text-input title="This prefix helps people identify this type of service." v-model="form.service_id_prefix" class="col-span-2 md:col-span-1" id="service_id_prefix" name="service_id_prefix" label="Service ID Prefix" :errors="props.errors.service_id_prefix" :disabled="props.serviceCount > 0" mark-as-required />

            <select-input v-model="form.line_item_category_id" class="col-span-2 md:col-span-1" id="line_item_category_id" name="line_item_category_id" label="Line Item Category" :errors="props.errors.line_item_category_id" mark-as-required >
                <option v-for="category in props.lineItemCategories" :key="category.id" :value="category.id">{{ category.display_name }}</option>
            </select-input>
            <select-input v-model="form.waste_generation_type" class="col-span-2 md:col-span-1" id="waste_generation_type" name="waste_generation_type" label="Waste Generation Type" :errors="props.errors.waste_generation_type" :disabled="props.serviceCount > 0" mark-as-required >
                <option v-for="(decorated, type) in props.wasteGenerationTypes" :key="type" :value="type">{{ decorated }}</option>
            </select-input>


            <text-input-with-variables v-model="form.service_description" :variables="descriptionVariables" class="col-span-2" label="Service Description" :errors="errors.service_description" mark-as-required/>
            <div class="col-span-2">ex: {{ preview.service_description }}</div>

            <text-input-with-variables label="Client Line Item Header Label" class="col-span-2" v-model="form.client_line_item_header_label" :variables="descriptionVariables" :errors="props.errors.client_line_item_header_label" />
            <div class="col-span-2">ex: {{ preview.client_line_item_header_label }}</div>
        </div>

        <hr v-if="form.waste_generation_type === 'nothing'" class="col-span-6 border-1 my-8" />

        <div v-if="form.waste_generation_type === 'nothing'" class="col-span-6 grid grid-cols-6 gap-4">
            <toggle-switch-input class="col-span-2" v-model="form.bin" label="Service has a bin" />
            <toggle-switch-input class="col-span-2" v-model="form.pickup_schedule" label="Service has a pickup schedule" />
            <toggle-switch-input class="col-span-2" v-model="form.service_schedule_info" label="Service has a schedule" />
        </div>

        <hr class="col-span-6 border-1 my-8" />

        <div class="col-span-6 grid grid-cols-6 gap-4">
            <toggle-switch-input class="md:col-span-3 col-span-6" v-model="form.base_charges" label="This service has a base/flat charge" />
            <div class="md:col-span-3 col-span-6">
                <text-input-with-variables label="Client Line Item Description" v-model="form.client_line_item_description" :variables="descriptionVariables" :errors="props.errors.client_line_item_description" :disabled="props.serviceCount > 0" />
                <div>ex: {{ preview.client_line_item_description }}</div>
            </div>

<!--            <text-input mark-as-required class="md:col-span-3 col-span-6" v-if="form.base_charges && $page.props.permissions.accessRevenueManagement" label="Client Line Item Description" v-model="form.client_line_item_description" :errors="props.errors.client_line_item_description" />-->
        </div>

        <div class="col-span-6 grid grid-cols-6 gap-4">
            <toggle-switch-input class="col-span-3" v-model="form.per_unit_charges" label="This service has a per unit charge" />
        </div>

        <div class="col-span-6 grid grid-cols-6 gap-4">
            <toggle-switch-input class="col-span-3" v-model="form.per_occurrence_charges" label="This service has a per occurrence charge" />
        </div>

        <div class="flex flex-row justify-end mt-4">
            <loading-button :loading="state.value === 'saving'" class="btn btn-orange">
                Save Changes
            </loading-button>
        </div>
    </form>
</template>

<script setup>
    import { router, Head } from '@inertiajs/vue3';
    import {reactive, ref, inject, onMounted, watch} from 'vue';

    // Components
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ToggleSwitchInput from "@/Shared/ToggleSwitchInput.vue";
    import TextInputWithVariables from "@/Shared/TextInputWithVariables.vue";
    import {debounce} from "lodash-es";
    import axios from "axios";

    // Inject
    const route = inject('route');

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        serviceType: {
            type: Object,
            required: true
        },

        descriptionVariables: {
            type: Array,
            required: true
        },

        fields: {
            type: Object,
            required: true
        },

        lineItemCategories: {
            type: Array,
            required: true
        },

        wasteGenerationTypes: {
            type: Object,
            required: true
        }
    });

    // State
    const form = reactive({
        "name": null,
        "type_description": null,
        "service_description": null,
        "service_id_prefix": null,
        "line_item_category_id": null,
        'waste_generation_type': null,
        'base_charges': false,
        'client_line_item_description': null,
        'per_unit_charges': false,
        'per_occurrence_charges': false,
        'bin': false,
        'pickup_schedule': false,
        'service_schedule_info': false,
    });
    const state = ref('passive');
    const mounted = ref(false);

    const preview = reactive({
        service_description: '',
        client_line_item_header_label: '',
        client_line_item_description: '',
    });

    // Mount
    onMounted(() => {
        mounted.value = true;
    });

    // Methods
    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';
            router.post(route('tenant-settings.service-types.store'), form, {
                preserveScroll: true,
                onFinish: () => {
                    state.value = 'passive'
                },
            });
        }
    }

    // Watchers
    watch(() => form.waste_generation_type, (newValue) => {
        if (newValue === 'metered' || newValue === 'unmetered') {
            form.bin = true;
            form.pickup_schedule = true;
            form.service_schedule_info = true;
        } else {
            form.bin = false;
            form.pickup_schedule = false;
            form.service_schedule_info = false;
        }
    });


    watch(() => form.service_description, debounce((newValue) => {
        axios.get(route('tenant-settings.json.service-types.preview', {'template': newValue})).then((response) => {
            preview.service_description = response.data.value;
        });
    }, 1000))

    watch(() => form.client_line_item_header_label, debounce((newValue) => {
        axios.get(route('tenant-settings.json.service-types.preview', {'template': newValue})).then((response) => {
            preview.client_line_item_header_label = response.data.value;
        });
    }, 1000))

    watch(() => form.client_line_item_description, debounce((newValue) => {
        axios.get(route('tenant-settings.json.service-types.preview', {'template': newValue})).then((response) => {
            preview.client_line_item_description = response.data.value;
        });
    }, 1000))
</script>
