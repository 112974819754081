<template>
    <div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
            <date-input
                class="md:col-span-3"
                v-model="termination_date"
                label="Termination Date"
                helpText="When should this service be terminated?"
                :min="minimumActualDate"
                :errors="errors['termination_date']"
                mark-as-required
            />

            <div class="md:col-span-3" v-if="termination_date && termination_date !== today">
                <div class="rounded-md bg-yellow-50 p-4" v-if="termination_date && termination_date < today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-yellow-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-yellow-700">
                                NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).
                            </p>
                        </div>
                    </div>
                </div>
                <div class="rounded-md bg-blue-50 p-4" v-if="termination_date && termination_date > today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-blue-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-blue-700">
                                NOTE: This will only set the termination date on the service. The service will remain active until the termination date is reached. You may cancel this change at any time prior to the chosen date by removing the termination date from this service.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="[termination_date && termination_date === today ? 'md:col-span-3' : 'col-span-1 md:col-span-6']">
                <select-input v-model="change_reason" label="Reason for Change" :errors="errors['change_reason']" :markAsRequired="true">
                    <option>Savings</option>
                    <option>Other</option>
                </select-input>
            </div>
        </div>

        <div class="mt-8">
            <textarea-input rows="4" v-model="internal_change_notes" label="Internal Change Notes"/>
        </div>

        <template v-if="showPriorPeriodAdjustment">
            <hr class="my-8" />

            <div class="rounded-md bg-yellow-50 p-4 mb-8">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <icon name="exclamation-triangle" class="size-5 text-yellow-400 fill-current" />
                    </div>
                    <div class="ml-3 flex-1 text-base">
                        <h3 class="font-medium text-yellow-800">Prior Period Adjustment</h3>
                        <div class="mt-2 text-yellow-700">
                            <div class="pr-2">
                                You are changing the termination date to
                                <span class="font-medium">{{ termination_date }}</span>.
                                <span v-if="lastBilledClientLineItemDetails?.lastLineItemBilled">
                                    The service was already billed
                                    <span class="font-medium">{{ $filters.format_money(lastBilledClientLineItemDetails?.lastLineItemBilled.total_amount) }}</span>
                                    for fiscal period
                                    <span class="font-medium">{{ $filters.format_date(lastBilledClientLineItemDetails?.lastLineItemBilled.origin_date) }}</span>.
                                </span>
                                <p class="mt-1">Please enter an amount and description in the boxes below if you wish to bill a prior period adjustment for this change.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="my-4">
                <money-input v-model="ppa_amount" label="Prorated Amount" :defaultCurrency="$page.props.currency" />
            </div>

            <div class="my-4">
                <textarea-input v-model="ppa_description" label="Line Item Description" rows="4" />
            </div>
        </template>
    </div>

    <loading-button class="rounded-md bg-d-orange-500 px-3 py-2 mt-12 text-base font-semibold text-white shadow-sm hover:bg-d-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-d-orange-500 disabled:opacity-50" @click="submitChange" :disabled="!termination_date || !change_reason">
        Submit Change
    </loading-button>
</template>

<script setup>
    import axios from "axios";
    import { format, startOfToday } from "date-fns";
    import { router, usePage } from '@inertiajs/vue3';
    import { ref, inject, watch, computed, onMounted } from 'vue';

    import Icon from '@/Shared/Icon.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import MoneyInput from "@/Shared/MoneyInput.vue";
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';

    /**
     * Props
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        service: {
            type: Object,
            required: true
        },

        who: {
            type: String,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        }
    });

    /**
     * Injected
     */
    const page = usePage();
    const route = inject('route');

    onMounted(() => {
        if (suggestPriorPeriodAdjustment()) {
            updateLastBilledClientLineItemDetails();
        }
    });

    /**
     * Data
     */
    const saving = ref(false);
    const termination_date = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const change_reason = ref(null);
    const change_requester = ref(props.who);
    const internal_change_notes = ref(null);
    const today = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const lastBilledClientLineItemDetails = ref(null);
    const ppa_amount = ref(null);
    const ppa_description = ref(null);

    /**
     * Methods
     */
    function submitChange() {
        saving.value = true;

        let routeName = termination_date.value < today.value ? 'services.service-termination-changes.store' : 'services.service-termination-date-changes.store';

        router.post(route(routeName, [props.service.id]), {
            'termination_date': termination_date.value,
            'change_reason': change_reason.value,
            'change_requester': props.who,
            'internal_change_notes': internal_change_notes.value,
            'ppa_amount': showPriorPeriodAdjustment ? ppa_amount.value : null,
            'ppa_description': showPriorPeriodAdjustment ? ppa_description.value : null,
        }, {
            onFinish: () => saving.value = false
        });
    }

    function suggestPriorPeriodAdjustment() {
        const conditions = [
            page.props.permissions.accessRevenueManagement,
            !props.service.is_pass_through,
            ['Monthly', 'Quarterly', 'Yearly'].includes(props.service.client_billing_frequency),
            props.service.service_schedule_type !== 'One Time',
            props.service.serviceType.enabled_type_fields.includes('base_charges'),
            termination_date.value !== props.service.termination_date
        ];

        return conditions.every(condition => condition === true);
    }

    function updateLastBilledClientLineItemDetails() {
        axios.get(`/json/services/${props.service.id}/last-billed-client-line-item-details?maxOriginDate=${termination_date.value}`).then(response => {
            lastBilledClientLineItemDetails.value = response.data
        });
    }

    /**
     * Computed
     */
    const minimumActualDate = computed(() => {
        if (props.service.effective_date > props.earliestServiceActivity.actual_date) {
            return props.service.effective_date;
        }

        return props.earliestServiceActivity.actual_date;
    });

    const showPriorPeriodAdjustment = computed(() => {
        return suggestPriorPeriodAdjustment()
            && lastBilledClientLineItemDetails.value
            && lastBilledClientLineItemDetails.value?.lastBillingPeriodEnd > termination_date.value
    });

    /**
     * Watchers
     */
    watch(() => termination_date, () => {
        if (suggestPriorPeriodAdjustment()) {
            updateLastBilledClientLineItemDetails();
        }
    });
</script>
