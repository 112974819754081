<template>
    <div :class="$attrs.class">
        <label v-if="label" :class="hideLabel ? 'sr-only' : 'form-label mb-4'" :for="props.id" :aria-required="markAsRequired">
            <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
        </label>

        <QuillyEditor
            ref="editor"
            v-model="content"
            :class="$attrs.editorClass"
            :id="props.id"
            :options="props.options ?? defaultOptions()"
            @update:modelValue="onModelChange"
        />

        <p v-if="helpText" class="form-help-text">{{ helpText }}</p>

        <div v-if="errors" class="form-error-text">{{ errors }}</div>
    </div>
</template>

<script setup>
/**
 * Quilly Editor Events
 *
 * @update:modelValue="(value) => console.log('HTML model updated:', value)"
 * @text-change="({ delta, oldContent, source }) => console.log('text-change', delta, oldContent, source)"
 * @selection-change="({ range, oldRange, source }) => console.log('selection-change', range, oldRange, source)"
 * @editor-change="(eventName) => console.log('editor-change', `eventName: ${eventName}`)"
 * @focus="(quill) => console.log('focus', quill)"
 * @blur="(quill) => console.log('blur', quill)"
 * @ready="(quill) => console.log('ready', quill)"
*/

    import {ref, watch, onMounted} from 'vue';

    import Quill from 'quill';
    import { QuillyEditor } from "vue-quilly";
    import { v4 as uuid } from 'uuid';

    const emit = defineEmits(['update:modelValue']);
    const props = defineProps({
        id: {
            type: String,
            default() {
                return `quill-editor-${uuid()}`;
            },
        },
        options: {
            type: Object,
            default: null,
        },
        modelValue: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        helpText: {
            type: String,
            default: ''
        },
        variables: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: String,
            default: '',
        },
        markAsRequired: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hideLabel: {
            type: Boolean,
            default: false
        },
    });

    const editor = ref(QuillyEditor);
    const content = ref(props.modelValue);

    // Quill instance
    let quill = Quill || null;
    onMounted(() => {
        quill = editor.value?.initialize(Quill)
    });

    function defaultOptions() {
        return {
            theme: 'snow', // styles imported in global app.css file
            modules: {
                toolbar: {
                    container: buildContainer(),
                    handlers: buildHandlers()
                },
            },
            placeholder: 'Add some notes...',
            readOnly: false,
        }
    }

    function buildContainer() {
        let container = [
            ['bold', 'italic', 'underline', 'strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'color': []}, {'background': []}],
            [{'font': []}],
            [{'align': []}],
        ];

        if (props.variables) {
            container.push([{'variables': props.variables}]);
        }

        return container;
    }

    function buildHandlers()
    {
        return {
            'variables': function(value) {
                if (quill) {
                    const cursorPosition = quill.getSelection().index;
                    quill.insertText(cursorPosition, value);
                    quill.setSelection(cursorPosition + value.length);
                }
            }
        }
    }

    // This bubbles up and changes the parent component's model value
    function onModelChange(value) {
        emit('update:modelValue', value);
    }

    // If another source updates the parent value, update the contents of quill
    watch(() => props.modelValue, (newValue, oldValue) => {
        content.value = newValue;
    });
</script>

<style>
    .ql-container.ql-snow {
        border: 1px solid rgb(209 213 219) !important; /* border */
        border-top-width: 0 !important; /* border-t-0 */
        border-radius: 0 0 0.375rem 0.375rem; /* rounded-b-md */
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05); /* shadow-sm */
    }

    .ql-toolbar.ql-snow {
        border: 1px solid rgb(209 213 219) !important; /* border */
        border-radius: 0.375rem 0.375rem 0 0; /* rounded-t-md */
    }

    .ql-variables .ql-picker-label:before {
        content: 'Insert Variable';
        padding-right: 2em;
    }
    .ql-variables .ql-picker-item:before {
        content: attr(data-value);
    }
</style>
