<template>
    <Head title="Client Accounts" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Client Accounts</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :search-id="'search-client-accounts'"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('client-accounts.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="clientAccounts.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ clientAccounts.from }} - {{ clientAccounts.to }} out of {{ clientAccounts.total }} Client Accounts</div>

        <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
            <inertia-link data-cy="client-accounts.index.create-link" :href="$route('client-accounts.create')" class="btn btn-orange mr-2">
                New
            </inertia-link>

            <loading-button :loading="state === 'exporting'" class="btn btn-gray" @click="exportData" :disabled="!clientAccounts.data.length">
                Export to CSV
            </loading-button>
        </div>
    </div>

    <div>
        <div class="overflow-x-auto">
            <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <sortable-table-header field="account_number" :filters="filters" route-name="client-accounts.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Account Number</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Name')" field="name" :filters="filters" route-name="client-accounts.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Name</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Client Company')" field="client_companies.name" :filters="filters" route-name="client-accounts.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Client Company</sortable-table-header>
                        <th scope="col" class="w-36 px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="clientAccount in clientAccounts.data" :key="clientAccount.id">
                        <td>
                            <inertia-link data-cy="client-accounts.index.show-link" :href="$route('client-accounts.show', [clientAccount.id])" class="link text-left">
                                {{ clientAccount.account_number }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Name')">
                            {{ clientAccount.name }}
                        </td>
                        <td v-show="form.selected_headers.includes('Client Company')">
                            <inertia-link :href="$route('client-companies.show', clientAccount.client_company_id)" class="link">
                                {{ clientAccount.clientCompany.name }}
                            </inertia-link>
                        </td>
                        <td class="text-right">
                            <inertia-link :href="$route('client-accounts.show', [clientAccount.id])" class="link mr-3" title="View this Client Account">
                                View
                            </inertia-link>

                            <inertia-link :href="$route('client-accounts.edit', [clientAccount.id])" class="link" title="Edit this Client Account">
                                Edit
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="clientAccounts.links" />
    </div>

    <div v-if="!clientAccounts.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="address-book" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Client Accounts Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="client-account-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.client_company_ids" multiple>
                        <ComboboxLabel class="form-label" for="clientCompanies">Client Companies</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="client_company_ids" name="client_company_ids" class="form-input" @change="clientCompanyComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="clientCompanies.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="clientCompany in filteredClientCompaniesOptions" :key="clientCompany.id" :value="clientCompany.id" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ clientCompany.name }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import { throttle } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from "@/Shared/SlideOver.vue";
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ColumnSelector from '@/Shared/ColumnSelector.vue';
    import IndexSearchForm from '@/Shared/IndexSearchForm.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Head,
            Icon,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            ColumnSelector,
            IndexSearchForm,
            SortableTableHeader,
            CopyToClipboardButton,

            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            clientAccounts: {
                type: Object,
                required: true
            },

            clientCompanies: {
                type: Array,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    client_company_ids: this.filters.client_company_ids,
                    selected_headers: this.filters.selected_headers,
                },
                headers: [],
                excludedHeaders: ['Account Number', 'Actions'],
                state: 'passive',
                mounted: false,
                clientCompanyComboBoxQuery: '',
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            this.filtersInUse = this.getFiltersUsed;


            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.client-accounts.index'), this.queryFilters, {
                    onFinish: () => {
                        this.state = 'passive';
                    }
                });
            },

            getTableHeaders() {
                const table = this.$refs.table;// Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
                this.headers = headers;
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('client-accounts.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredClientCompaniesOptions() {
                return this.clientCompanyComboBoxQuery === ''
                    ? this.clientCompanies
                    : this.clientCompanies.filter((clientCompany) => {
                        return clientCompany.toLowerCase().includes(this.clientCompanyComboBoxQuery.toLowerCase());
                    });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.client_company_ids,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>
