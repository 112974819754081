<template>
    <Head title="Client Invoices" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Revenue - Client Invoices</span>
        </nav>
    </Teleport>

    <horizontal-sub-nav :permissions="$page.props.permissions" current-tab="invoices" />

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :search-id="'search-client-invoices'"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('client-invoices.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="my-4">
        <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="invoices.data.length" class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ invoices.from }} - {{ invoices.to }} out of {{ invoices.total }} Client Invoices</div>

            <div class="inline-block col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
                <div class="flex items-center">
                    <loading-button :loading="state === 'queueing'" class="btn btn-gray mr-2 sm:my-0" @click="queueClientInvoiceAutoApprovals" :disabled="!invoices.data.length">
                        Queue Invoice Approvals
                    </loading-button>

                    <loading-button :loading="state === 'e-mailing'" class="btn btn-gray mr-2 sm:my-0" @click="emailAllApproved">
                        Email All Approved
                    </loading-button>

                    <loading-button :loading="state === 'exporting'" class="btn btn-gray mr-2 sm:my-0" @click="exportData" :disabled="!invoices.data.length">
                        Export to CSV
                    </loading-button>
                </div>
            </div>
        </div>

        <div class="mt-8">
            <div class="overflow-x-auto">
                <div v-if="invoices.data.length && selectedInvoices.length" class="bg-d-orange-100/35 rounded px-4 py-1.5 flex items-center">
                    <span v-if="selectedInvoices.length === 1" class="mr-8 text-d-orange-900">{{  `${selectedInvoices.length} item selected` }}</span>
                    <span v-else class="mr-8 text-d-orange-900">{{  `${selectedInvoices.length} items selected` }}</span>

                    <div class="flex space-x-3">
                        <loading-button :loading="state === 'bulkApproving'" class="btn btn-gray btn-sm disabled:cursor-not-allowed disabled:opacity-30" @click="bulkApprove" :disabled="!canApprove">
                            <icon name="thumbs-up" class="inline stroke-current size-3 mr-2" />
                            Approve
                        </loading-button>
                        <loading-button :loading="state === 'bulkUnapproving'" class="btn btn-gray btn-sm disabled:cursor-not-allowed disabled:opacity-30" @click="bulkUnapprove" :disabled="!canUnapprove">
                            <icon name="thumbs-down" class="inline stroke-current size-3 mr-2" />
                            Unapprove
                        </loading-button>
                        <loading-button :loading="state === 'bulkHolding'" class="btn btn-gray btn-sm disabled:cursor-not-allowed disabled:opacity-30" @click="bulkPlaceOnHold" :disabled="!canPlaceOnHold">
                            <icon name="pause-circle" class="inline stroke-current size-3 mr-2" />
                            Place On Hold
                        </loading-button>
                        <loading-button :loading="state === 'bulkEmailing'" class="btn btn-gray btn-sm disabled:cursor-not-allowed disabled:opacity-30" @click="bulkEmail" :disabled="!canEmail">
                            <icon name="paper-plane" class="inline stroke-current size-3 mr-2" />
                            Email
                        </loading-button>
                        <form :action="$route('client-invoices.bulk-download')" method="POST">
                            <input type="hidden" name="_token" :value="csrfToken">
                            <input v-for="(invoice_id, index) in selectedInvoices" type="hidden" :name="`invoice_ids[${index}]`" :value="invoice_id">
                            <button type="submit" class="btn btn-gray btn-sm disabled:cursor-not-allowed disabled:opacity-30" :disabled="!canDownload">
                                <icon name="download-arrow" class="inline fill-current size-3 mr-2" />
                                Download
                            </button>
                        </form>
                        <loading-button :loading="state === 'bulkDeleting'" class="btn btn-red btn-sm disabled:cursor-not-allowed disabled:opacity-30" @click="bulkDelete" :disabled="!canDelete">
                            <icon name="trash" class="inline fill-current size-3 mr-2" />
                            Delete
                        </loading-button>
                    </div>
                </div>

                <table class="table table-condensed" ref="table">
                    <thead>
                        <tr>
                            <th scope="col" class="relative px-7 sm:w-12 sm:px-6">
                                <input type="checkbox"
                                    class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6"
                                    :checked="invoices.data.length && (indeterminate || selectedInvoices.length === invoices.data.length)"
                                    :indeterminate="indeterminate"
                                    :disabled="!invoices.data.length"
                                    @change="selectedInvoices = $event.target.checked ? invoices.data.map((invoice) => invoice.id) : []"
                                />
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1">PDF</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Invoice Number')" field="invoice_number" :filters="filters" route-name="client-invoices.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Invoice Number</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Invoice Date')" field="invoice_date" :filters="filters" route-name="client-invoices.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Invoice Date</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Due At')" field="due_at" :filters="filters" route-name="client-invoices.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Due At</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Client Company')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Client Company</th>
                            <th v-show="form.selected_headers.includes('Client Account')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Client Account</th>
                            <th v-show="form.selected_headers.includes('Billed')" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Billed</th>
                            <th v-show="form.selected_headers.includes('Due')" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Due</th>
                            <th v-show="form.selected_headers.includes('Status')" scope="col" class="w-48 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                            <th scope="col" class="w-36 px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="invoice in invoices.data" :key="invoice.id">
                            <td class="relative px-7 sm:w-12 sm:px-6">
                                <div v-if="selectedInvoices.includes(invoice.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                                <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="invoice.id" v-model="selectedInvoices" />
                            </td>
                            <td>
                                <a :href="$route('client-invoices.pdf', invoice.id)" class="action-link p-1" target="_blank">
                                    <icon v-if="invoice.current_pdf_document" name="file-pdf" class="inline fill-current size-4 text-red-500 hover:text-red-600" />
                                    <icon v-else name="eye" class="inline stroke-current size-4 text-gray-500 hover:text-gray-600" />
                                </a>
                            </td>
                            <td v-show="form.selected_headers.includes('Invoice Number')">
                                <inertia-link data-cy="client-invoices.index.show-link" :href="$route('client-invoices.show', [invoice.id])" class="link" title="View This Client Invoice">
                                    {{ invoice.invoice_number }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Invoice Date')" >
                                {{ $filters.format_date(invoice.invoice_date) }}
                            </td>
                            <td v-show="form.selected_headers.includes('Due At')">
                                {{ invoice.due_at ? $filters.format_date(invoice.due_at) : 'Due Upon Receipt' }}
                            </td>
                            <td v-show="form.selected_headers.includes('Client Company')">
                                <inertia-link :href="$route('client-invoices.index', { search: invoice.clientAccount.clientCompany.name })" class="link" :title="invoice.clientAccount.clientCompany.name">
                                    {{ truncate(invoice.clientAccount.clientCompany.name) }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Client Account')">
                                <inertia-link :href="$route('client-accounts.edit', invoice.clientAccount.id )" target="_blank" class="link" :title="invoice.clientAccount.name">
                                    {{ truncate(invoice.clientAccount.name) }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Billed')" class="text-right">
                                {{ $filters.format_money(invoice.total_amount_billed) }}
                            </td>
                            <td v-show="form.selected_headers.includes('Due')" class="text-right">
                                {{ $filters.format_money(invoice.total_amount_due) }}
                            </td>
                            <td v-show="form.selected_headers.includes('Status')">
                                {{ invoice.status }}
                            </td>
                            <td class="text-right">
                                <inertia-link data-cy="client-invoices.index.show-link" :href="$route('client-invoices.show', [invoice.id])" class="link" title="View This Client Invoice">
                                    View
                                </inertia-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <pagination v-if="invoices.data.length > 0" :links="invoices.links" />

        <div v-if="!invoices.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="file-invoice" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Client Invoices found.</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="client-invoice-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-4 items-center">
                <date-input class="col-span-1" label="Start Date" v-model="form.start_date" />
                <date-input class="col-span-1" label="End Date" v-model="form.end_date" />

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel class="form-label" for="statuses">Statuses</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="statuses" name="statuses" class="form-input" @change="statusComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="statuses.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="status in filteredStatusOptions" :key="status" :value="status" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ status }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import { groupBy, throttle, truncate } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import DateInput from '@/Shared/DateInput.vue';
    import SlideOver from '@/Shared/SlideOver.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import IndexSearchForm from "@/Shared/IndexSearchForm.vue";
    import HorizontalSubNav from '../Revenue/HorizontalSubNav.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import BooleanCheckboxInput from "@/Shared/BooleanCheckboxInput.vue";
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';

    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            Head,
            DateInput,
            SlideOver,
            MoneyInput,
            Pagination,
            SelectInput,
            LoadingButton,
            ColumnSelector,
            IndexSearchForm,
            HorizontalSubNav,
            SortableTableHeader,
            BooleanCheckboxInput,
            CopyToClipboardButton,

            // Tailwind UI combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            csrfToken: {
                type: String,
                required: true,
            },

            invoices: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },

            filters: {
                type: Object,
                required: true
            },

            statuses: {
                type: Array,
                required: true,
            },

            isEmailing: {
                type: Boolean,
                required: true,
            }
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    start_date: this.filters.start_date,
                    end_date: this.filters.end_date,
                    per_page: this.filters.per_page,
                    selected_headers: this.filters.selected_headers,
                    statuses: this.filters.statuses,
                },
                selectedInvoices: [],
                statusComboBoxQuery: '',
                headers: [],
                excludedHeaders: ['PDF', 'Actions'],
                state: this.isEmailing ? 'e-mailing' : 'passive',
                mounted: false,
                filtersInUse: 0,
            };
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();

            Echo.join(`client_invoices.index.${this.$page.props.authUser.current_tenant_id}`)
                .listen('.App\\Events\\ClientInvoice\\CreateSingleClientInvoiceHasFinished', ($e) => {
                    this.$toast.success($e.message);
                    this.$inertia.reload({preserveState: true});
                });
        },

        unmounted() {
            Echo.leave(`client_invoices.index.${this.$page.props.authUser.current_tenant_id}`);
        },

        methods: {
            groupBy,

            truncate,

            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.client-invoices.index'), this.queryFilters, {
                    onFinish: () => {
                        this.state = 'passive';
                    }
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                this.headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
            },

            queueClientInvoiceAutoApprovals() {
                if (!window.confirm("You are about to Approve all invoices, Are you sure you want to proceed?")) {
                    return;
                }

                if (this.state === 'passive') {
                    this.state = 'approving';

                    this.$inertia.post(this.$route('client-invoices.queue-auto-approvals'), {}, {
                        onFinish: () => {
                            this.state = 'passive';
                        }
                    });
                }
            },

            emailAllApproved() {
                if (this.state === 'passive') {
                    this.state = 'e-mailing';

                    this.$inertia.post(this.$route('client-invoices.email-all-approved'), {},{
                        onFinish: () => {
                            this.state = 'passive';
                        }
                    });
                }
            },

            bulkApprove() {
                if (this.state === 'passive') {
                    this.state = 'bulkApproving';

                    this.$inertia.post(this.$route('client-invoices.bulk-approve'), {invoice_ids: this.selectedInvoices}, {
                        onFinish: () => this.state = 'passive',
                        onSuccess: () => this.selectedInvoices = [],
                    });
                }
            },

            bulkUnapprove() {
                if (this.state === 'passive') {
                    this.state = 'bulkUnapproving';

                    this.$inertia.post(this.$route('client-invoices.bulk-unapprove'), {invoice_ids: this.selectedInvoices}, {
                        onFinish: () => this.state = 'passive',
                        onSuccess: () => this.selectedInvoices = [],
                    });
                }
            },

            bulkPlaceOnHold() {
                if (this.state === 'passive') {
                    this.state = 'bulkHolding';

                    this.$inertia.post(this.$route('client-invoices.bulk-hold'), {invoice_ids: this.selectedInvoices}, {
                        onFinish: () => this.state = 'passive',
                        onSuccess: () => this.selectedInvoices = [],
                    });
                }
            },

            bulkEmail() {
                if (this.state === 'passive') {
                    this.state = 'bulkEmailing';

                    this.$inertia.post(this.$route('client-invoices.bulk-email'), {invoice_ids: this.selectedInvoices}, {
                        onFinish: () => this.state = 'passive',
                        onSuccess: () => this.selectedInvoices = [],
                    });
                }
            },

            bulkDelete() {
                let count = this.selectedInvoices.length;
                let confirm = window.confirm(`Are you sure you want to delete the selected ${count} invoices?`);

                if (confirm && this.state === 'passive') {
                    this.state = 'bulkDeleting';

                    this.$inertia.post(this.$route('client-invoices.bulk-delete'), {invoice_ids: this.selectedInvoices}, {
                        onFinish: () => this.state = 'passive',
                        onSuccess: () => this.selectedInvoices = [],
                    });
                }
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('client-invoices.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

        },

        watch: {
            invoices: function() {
                this.shownLineItems = {};
                this.shownSalesTaxes = {};

                this.invoices.data.forEach(invoice => this.shownLineItems[invoice.id] = true);
                this.invoices.data.forEach(invoice => this.shownSalesTaxes[invoice.id] = true);
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.statuses,
                    form.start_date,
                    form.end_date,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.statuses
                    : this.statuses.filter((status) => {
                        return status.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            indeterminate() {
                return this.selectedInvoices.length > 0 && this.selectedInvoices.length < this.invoices.data.length;
            },

            canEmail() {
                return this.selectedInvoices.every((id) => {
                    return this.$can('email', this.invoices.data.find((invoice) => invoice.id === id));
                });
            },
            canDownload() {
                return this.selectedInvoices.every((id) => {
                    return this.$can('download', this.invoices.data.find((invoice) => invoice.id === id));
                });
            },
            canDelete() {
                return this.selectedInvoices.every((id) => {
                    return this.$can('delete', this.invoices.data.find((invoice) => invoice.id === id));
                });
            },
            canApprove() {
                return this.selectedInvoices.every((id) => {
                    return this.$can('approve', this.invoices.data.find((invoice) => invoice.id === id));
                });
            },
            canUnapprove() {
                return this.selectedInvoices.every((id) => {
                    return this.$can('unapprove', this.invoices.data.find((invoice) => invoice.id === id));
                });
            },
            canPlaceOnHold() {
                return this.selectedInvoices.every((id) => {
                    return this.$can('placeOnHold', this.invoices.data.find((invoice) => invoice.id === id));
                });
            },
        }
    }
</script>
