<template>
    <Head title="Incidents" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Incidents</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :filters-in-use="filtersInUse"
        :search-id="'search-incidents'"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('incidents.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="w-full my-4">
        <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="incidents.data.length" class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ incidents.from }} - {{ incidents.to }} out of {{ incidents.total }} Incidents</div>

            <div class="flex col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
                <inertia-link :href="$route('incidents.create')" class="btn btn-orange mr-2">
                    New
                </inertia-link>

                <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!incidents.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

        <div>
            <div class="overflow-x-auto">
                <table class="table table-condensed" ref="table">
                    <thead>
                    <tr>
                        <sortable-table-header field="incidents.incident_number" :filters="filters" route-name="incidents.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Incident Number</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Type')" field="incident_types.name" :filters="filters" route-name="incidents.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Type</sortable-table-header>
                        <th v-show="form.selected_headers.includes('Service')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">
	                        <span v-tippy="'This column displays an icon if the associated service is monitored by Pioneer.'">
			                    <icon name="info-circle" class="w-4 h-4 fill-current text-gray-400" />
                                <span class="sr-only">Monitored by Pioneer</span>
		                    </span>
                        </th>
	                    <sortable-table-header v-show="form.selected_headers.includes('Service')" field="service_id" :filters="filters" route-name="incidents.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Service</sortable-table-header>
	                    <th v-show="form.selected_headers.includes('Equipment Label')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Equipment Label</th>
                        <sortable-table-header v-show="form.selected_headers.includes('Location')" field="locations.name" :filters="filters" route-name="incidents.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Location</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Client Company')" field="client_companies.name" :filters="filters" route-name="incidents.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Client Company</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Status')" field="status" :filters="filters" route-name="incidents.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Status</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Priority')" field="priority" :filters="filters" route-name="incidents.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Priority</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Reason')" field="incident_reasons.name" :filters="filters" route-name="incidents.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Reason</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Due On')" field="due_on" :filters="filters" route-name="incidents.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Due On</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Assigned To')" field="assigned_to_id" :filters="filters" route-name="incidents.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Assigned To</sortable-table-header>
                        <th scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                    </tr>
                    </thead>

                    <tbody class="divide-gray-200 bg-white">
                    <tr v-for="incident in incidents.data" :key="incident.id">
                        <td>
                            <inertia-link data-cy="incidents.index.show-link" :href="$route('incidents.show', [incident.id])" class="link">
                                {{ incident.display_id }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Type')">{{ incident.type.name }}</td>
	                    <td v-show="form.selected_headers.includes('Service')">
		                    <pioneer-indicator v-if="incident.service?.compactorMonitor" :compactor-monitor="incident.service.compactorMonitor"/>
	                    </td>
                        <td v-show="form.selected_headers.includes('Service')" class="">

                            <inertia-link v-if="incident.service_id" :href="$route('services.show', [incident.service_id])" class="link">
                                {{ incident.service_id }}
                            </inertia-link>
                        </td>
	                    <td v-show="form.selected_headers.includes('Equipment Label')"> {{ incident.service?.label }}</td>
                        <td v-show="form.selected_headers.includes('Location')">
                            <inertia-link :href="$route('locations.show', [incident.location_id])" class="link">
                                {{ incident.location.name }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Client Company')">
                            <inertia-link :href="$route('client-companies.show', [incident.location.client_company_id])" class="link">
                                {{ incident.location.clientCompany.name }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Status')">
                            {{ incident.status }}</td>
                        <td v-show="form.selected_headers.includes('Priority')">
                            <span :class="{'text-red-500': incident.priority === 'High', 'text-orange-400': incident.priority === 'Medium'}">{{ incident.priority }}</span>
                        </td>
                        <td v-show="form.selected_headers.includes('Reason')">
                            {{ incident.reason.name }}</td>
                        <td v-show="form.selected_headers.includes('Due On')">
                            {{ incident.due_on }}</td>
                        <td v-show="form.selected_headers.includes('Assigned To')">
                            <template v-if="incident.assignedTo" class="mt-1 text-sm text-gray-900">
                                <img v-if="incident.assignedTo?.avatar_thumbnail_url" alt="Assigned To Avatar" :src="incident.assignedTo?.avatar_thumbnail_url" class="inline border border-gray-400 rounded-full mr-4 h-4 w-4" />

                                <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2 w-6 h-6">
                                                    <span class="text-md font-medium leading-none text-white uppercase">{{ incident.assignedTo?.first_name[0] }}{{ incident.assignedTo?.last_name[0] }}</span>
                                                </span>
                                <span>{{ incident.assignedTo?.name }}</span>
                            </template>
                            <template v-else>Unassigned</template>
                        </td>
                        <td class="text-right">
                            <span class="inline-block">
                                <inertia-link :href="$route('incidents.show', [incident.id])" class="link mr-3" title="View This Incident">
                                    View
                                </inertia-link>
                            </span>
                            <span class="inline-block">
                                <inertia-link :href="$route('incidents.edit', [incident.id])" class="link" title="Edit This Incident">
                                    Edit
                                </inertia-link>
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <pagination :links="incidents.links" />
        </div>

        <div v-if="!incidents.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="tasks" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Incidents Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="incident-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel class="form-label" for="statuses">Statuses</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="statuses" name="statuses" class="form-input" @change="statusComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <Icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="statuses.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="status in filteredStatusOptions" :key="status" :value="status" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ status }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <Icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.priorities" multiple>
                        <ComboboxLabel class="form-label" for="priorities">Priority</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="priorities" name="priorities" class="form-input" @change="priorityComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <Icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="priorities.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="priority in filteredPriorityOptions" :key="priority" :value="priority" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ priority }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <Icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.type_ids" multiple>
                        <ComboboxLabel class="form-label" for="type_ids">Type</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="type_ids" name="type_ids" class="form-input" @change="typeComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <Icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="types.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="type in filteredTypeOptions" :key="type.id" :value="type.id" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ type.name }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <Icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.reason_ids" multiple>
                        <ComboboxLabel class="form-label" for="reason_ids">Reason</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="reason_ids" name="reason_ids" class="form-input" @change="reasonComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <Icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="reasons.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="reason in filteredReasonOptions" :key="reason" :value="reason" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ reason }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <Icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.assigned_to" multiple>
                        <ComboboxLabel class="form-label" for="assigned_to">Assigned To</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="assigned_to" name="assigned_to" class="form-input" @change="assignedToComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <Icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="employees.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="employee in filteredEmployeeOptions" :key="employee" :value="employee" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ employee.name }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <Icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.due_times" multiple>
                        <ComboboxLabel class="form-label" for="due_times">Due Time</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="due_times" name="due_times" class="form-input" @change="dueTimeComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <Icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="dueTimes.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="dueTime in filteredDueTimeOptions" :key="dueTime.text" :value="dueTime" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ dueTime }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <Icon name="heroicon-check" class="size-5 fill-current" />
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import { throttle } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    // Import Components
    import Logo from "@/Shared/Logo.vue";
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from "@/Shared/SlideOver.vue";
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import IndexSearchForm from "@/Shared/IndexSearchForm.vue";
    import PioneerIndicator from "@/Shared/PioneerIndicator.vue";
    import SortableTableHeader from "@/Shared/SortableTableHeader.vue";

    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Head,
	        Logo,
            Icon,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            ColumnSelector,
            IndexSearchForm,
	        PioneerIndicator,
            SortableTableHeader,

            // Tailwind UI combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            incidents: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },

            priorities: {
                type: Array,
                required: true
            },

            statuses: {
                type: Array,
                required : true
            },

            reasons: {
                type: Array,
                required : true
            },

            dueTimes: {
                type: Array,
                required : true
            },

            employeesList: {
                type: Array,
                required: true
            },

            types: {
                type: Array,
                required: true
            },
        },

        data() {
            // Tailwind Headless UI combo box can detect selected items if they're passed in as a prop.
            // This appears to be happening because the employees prop (now called employeesList) is being converted to type 'reactive'.
            // Converting them back to a plain object seems to fix this (again, not sure how these components are tracking objects to know what's currently selected).
            let employees = this.employeesList.map(employee => ({...employee}));

            return {
                form: {
                    search: this.filters.search,
                    priorities: this.filters.priorities,
                    statuses: this.filters.statuses,
                    type_ids: this.filters.type_ids,
                    reason_ids: this.filters.reason_ids,
                    due_times: this.filters.due_times,
                    assigned_to: employees.filter(employee => this.filters.assigned_to_ids.includes(`${employee.id}`)),
                    sort_direction: this.filters.sort_direction,
                    sort_by: this.filters.sort_by,
                    per_page: this.filters.per_page,
                    selected_headers: this.filters.selected_headers,
                },
                employees: employees,
                statusComboBoxQuery: '',
                priorityComboBoxQuery: '',
                typeComboBoxQuery: '',
                reasonComboBoxQuery: '',
                dueTimeComboBoxQuery: '',
                assignedToComboBoxQuery: '',
                saving: false,
                headers: [],
                excludedHeaders: ['Incident Number', 'Actions', 'Monitored by Pioneer'],
                mounted: false,
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            closeIncident(incident) {
                let confirmed = confirm('Are you sure you wish to close this incident?');

                if (confirmed) {
                    this.$inertia.post(this.$route('incidents.close', [incident.id]));
                }
            },

            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.incidents.index'), this.queryFilters, {
                    onFinish: () => { this.saving = false; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                this.headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('incidents.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            }
        },

        computed: {
            queryFilters() {
                let {assigned_to, ...query} = this.form;
                query.assigned_to_ids = this.form.assigned_to.map(assignedTo => assignedTo.id);

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.statuses
                    : this.statuses.filter((status) => {
                        return status.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            filteredPriorityOptions() {
                return this.priorityComboBoxQuery === ''
                    ? this.priorities
                    : this.priorities.filter((priority) => {
                        return priority.toLowerCase().includes(this.priorityComboBoxQuery.toLowerCase());
                    });
            },

            filteredReasonOptions() {
                return this.reasonComboBoxQuery === ''
                    ? this.reasons
                    : this.reasons.filter((reason) => {
                        return reason.toLowerCase().includes(this.reasonComboBoxQuery.toLowerCase());
                    });
            },

            filteredTypeOptions() {
                return this.typeComboBoxQuery === ''
                    ? this.types
                    : this.types.filter((type) => {
                        return type.toLowerCase().includes(this.typeComboBoxQuery.toLowerCase());
                    });
            },

            filteredDueTimeOptions() {
                return this.dueTimeComboBoxQuery === ''
                    ? this.dueTimes
                    : this.dueTimes.filter((dueTime) => {
                        return dueTime.toLowerCase().includes(this.dueTimeComboBoxQuery.toLowerCase());
                    });
            },

            filteredEmployeeOptions() {
                return this.assignedToComboBoxQuery === ''
                    ? this.employees
                    : this.employees.filter((employee) => {
                        return employee.name.toLowerCase().includes(this.assignedToComboBoxQuery.toLowerCase());
                    });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.priorities,
                    form.statuses,
                    form.type_ids,
                    form.reason_ids,
                    form.due_times,
                    form.assigned_to,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }

        }
    }
</script>
