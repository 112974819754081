<template>
    <Head :title="`Service Type - ${serviceType.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.service-types.index')" class="breadcrumb-link">Service Types</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{serviceType.name}} ({{serviceType.id}})</span>
        </nav>
    </Teleport>

    <div>
        <div class="flex justify-between">
            <h1 class="text-2xl">{{serviceType.name}}</h1>
            <button v-if="serviceType.service_count === 0" @click.prevent="edit" class="btn btn-orange">Edit</button>

            <button disabled v-if="serviceType.service_count > 0" class="btn btn-orange" title="This service type has been used to create services. It can no longer be edited.">Edit</button>
        </div>

        <p class="text-gray-600">{{serviceType.type_description}}</p>

        <dl class="grid grid-cols-2 mt-6 gap-6">
            <div class="col-span-2 md:col-span-1">
                <dt class="font-semibold">Service ID Prefix</dt>
                <dd>{{serviceType.service_id_prefix}}</dd>
            </div>
            <div class="col-span-2 md:col-span-1">
                <dt class="font-semibold">Line Item Category</dt>
                <dd>{{serviceType.lineItemCategory.display_name}}</dd>
            </div>
            <div class="col-span-2 md:col-span-1">
                <dt class="font-semibold">Waste Generation Type</dt>
                <dd>{{serviceType.decorated_waste_generation_type}}</dd>
            </div>
            <div class="col-span-2 md:col-span-1">
                <dt class="font-semibold">Service Description</dt>
                <dd>{{serviceType.service_description}}</dd>
                <dd>ex: {{preview.service_description}}</dd>
            </div>
            <div class="col-span-2 md:col-span-1">
                <dt class="font-semibold">Client Line Item Header Label</dt>
                <dd>{{serviceType.client_line_item_header_label}}</dd>
                <dd>ex: {{preview.client_line_item_header_label}}</dd>
            </div>
            <div class="col-span-2 md:col-span-1">
                <dt class="font-semibold">Client Line Item Description</dt>
                <dd>{{serviceType.client_line_item_description}}</dd>
                <dd>ex: {{preview.client_line_item_description}}</dd>
            </div>
            <div class="col-span-2 md:col-span-1">
                <dt class="font-semibold">Number of Services</dt>
                <dd>{{props.serviceCount}}</dd>
            </div>
        </dl>

        <hr class="col-span-6 border-1 my-8"/>

        <div v-if="serviceType.enabled_type_fields.includes('base_charges')" class="col-span-2 md:col-span-1">
            <dt class="font-semibold">Has Base Charges</dt>
        </div>

        <div v-if="serviceType.enabled_type_fields.includes('per_unit_charges')" class="col-span-2 md:col-span-1">
            <dt class="font-semibold">Has Per Unit Charges</dt>
        </div>

        <div v-if="serviceType.enabled_type_fields.includes('per_occurrence_charges')" class="col-span-2 md:col-span-1">
            <dt class="font-semibold">Has Per Occurrence Charges</dt>
        </div>

        <div v-if="serviceType.enabled_type_fields.includes('bin')" class="col-span-2 md:col-span-1">
            <dt class="font-semibold">Has a Bin</dt>
        </div>

        <div v-if="serviceType.enabled_type_fields.includes('service_schedule_info')" class="col-span-2 md:col-span-1">
            <dt class="font-semibold">Has a Service Schedule</dt>
        </div>

        <div v-if="serviceType.enabled_type_fields.includes('pickup_schedule')" class="col-span-2 md:col-span-1">
            <dt class="font-semibold">Has a Pickup Schedule</dt>
        </div>

        <div v-if="props.serviceType.is_archived" class="col-span-2 flex">
            <div class="flex-shrink-0">
                <icon name="info-circle" class="h-6 w-6 text-blue-400 fill-current" />
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-blue-700">This service type has been archived. No new services can be created from it.</p>
            </div>
        </div>

        <div class="mt-10 grid-cols-2 grid gap-4">

        </div>
    </div>
</template>

<script setup>
    import { router, Head } from '@inertiajs/vue3';
    import {reactive, ref, inject, onMounted, watch} from 'vue';

    // Components
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ToggleSwitchInput from "@/Shared/ToggleSwitchInput.vue";

    // Inject
    const route = inject('route');

    // Props
    const props=defineProps({
        serviceType: {
            type: Object,
            required: true
        },

        serviceCount: {
            type: Number,
            required: true
        },

        preview: {
            type: Object,
            required: true
        },

        fields: {
            type: Object,
            required: true
        }
    });

    // State
    const fieldValues=reactive(Object.keys(props.fields).map((field) => {
        return {
            [field]: props.serviceType.enabled_type_fields.includes(field)
        }
    }).reduce(function(result, current) {
        return Object.assign(result, current);
    }, {}));
    const mounted=ref(false);

    // Mount
    onMounted(() => {
        mounted.value = true;
    });

    // Methods
    function edit() {
        router.visit(route('tenant-settings.service-types.edit', [props.serviceType.id]));
    }
</script>
