<template>
    <Head title="Rover Credentials" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Rover Credentials</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :search-id="'search-vendor-portal-credentials'"
        :clear-filters-route="$route('vendor-portal-credentials.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="w-full my-4">
        <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="vendorPortalCredentials.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">
                Showing {{ vendorPortalCredentials.from }} - {{ vendorPortalCredentials.to }} out of {{ vendorPortalCredentials.total }} Rover Credentials
            </div>

            <div v-if="$can('create')" class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-2 xl:col-end-5">
                <inertia-link :href="$route('vendor-portal-credentials.create')" class="btn btn-orange mr-2">
                  New
                </inertia-link>
                <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!vendorPortalCredentials.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

        <div>
            <div class="overflow-x-auto">
                <table class="table table-condensed" ref="table">
                    <thead>
                        <tr>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Username</th>
                            <th v-show="form.selected_headers.includes('Vendor')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Vendor</th>
                            <th v-show="form.selected_headers.includes('Status')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Status</th>
                            <th v-show="form.selected_headers.includes('Subscribed')" scope="col" class="w-40 px-3 py-3.5 text-right font-semibold text-gray-900">Subscribed</th>
                            <th v-show="form.selected_headers.includes('Unsubscribed')" scope="col" class="w-40 px-3 py-3.5 text-right font-semibold text-gray-900">Unsubscribed</th>
                            <th scope="col" class="w-36 px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="vendorPortalCredential in vendorPortalCredentials.data" :key="vendorPortalCredential.id" >
                            <td>
                                <inertia-link data-cy="vendor-portal-credentials.index.show-link" :href="$route('vendor-portal-credentials.show', vendorPortalCredential.id)" class="link mr-3" title="View This Rover Credential">
                                    {{ vendorPortalCredential.username }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Vendor')">
                                {{ vendorPortalCredential.vendorPortal.vendor_name }}
                            </td>
                            <td v-show="form.selected_headers.includes('Status')">
                                <div class="flex items-center gap-x-2" :title="vendorPortalCredential.status">
                                    <div :class="[statusIndicators[vendorPortalCredential.status], 'flex-none rounded-full p-1']">
                                        <div class="h-1.5 w-1.5 rounded-full bg-current" />
                                    </div>
                                    {{ statusNames[vendorPortalCredential.status] }}
                                </div>
                            </td>
                            <td v-show="form.selected_headers.includes('Subscribed')" class="text-right">
                                <inertia-link v-if="vendorPortalCredential.subscribedAccountsCount > 0" :href="$route('vendor-portal-accounts.index', {search: vendorPortalCredential.id, subscribed: 'only'})" class="link">
                                    {{ vendorPortalCredential.subscribedAccountsCount }}
                                </inertia-link>
                                <template v-else>0</template>
                            </td>
                            <td v-show="form.selected_headers.includes('Unsubscribed')" class="text-right">
                                <inertia-link v-if="vendorPortalCredential.unsubscribedAccountsCount > 0" :href="$route('vendor-portal-accounts.index', {search: vendorPortalCredential.id, subscribed: 'except'})" class="link">
                                    {{ vendorPortalCredential.unsubscribedAccountsCount }}
                                </inertia-link>
                                <template v-else>0</template>
                            </td>
                            <td class="text-right">
                                <inertia-link v-if="$can('view', vendorPortalCredential)" :href="$route('vendor-portal-credentials.show', vendorPortalCredential.id)" class="link mr-3" title="View This Rover Credential">
                                    View
                                </inertia-link>

                                <inertia-link v-if="$can('update', vendorPortalCredential)" :href="$route('vendor-portal-credentials.edit', vendorPortalCredential.id)" class="link" title="Edit This Rover Credential">
                                    Edit
                                </inertia-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="vendorPortalCredentials.links" />
        </div>

        <div v-if="!vendorPortalCredentials.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="file-alt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Rover Credentials Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="vendor-portal-credential-filter-form" name="vendor-portal-credential-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <label for="status" class="sr-only">Status</label>

                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel class="form-label" for="statuses">Statuses</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="statuses" name="statuses" class="form-input" @change="statusComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="statuses.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="status in filteredStatusOptions" :key="status.id" :value="status.text" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ status.text }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    //
    import { throttle } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from '@/Shared/SlideOver.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import IndexSearchForm from '@/Shared/IndexSearchForm.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';

    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Head,
            Icon,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            IndexSearchForm,
            SortableTableHeader,

            // Tailwind UI combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            vendorPortalCredentials: {
                type: Object,
                required: true
            },

            statuses: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        data() {
            return {
                form: {
                    per_page: this.filters.per_page,
                    search: this.filters.search,
                    statuses: this.filters.statuses,
                    selected_headers: this.filters.selected_headers,
                },
                saving: false,
                statusComboBoxQuery: '',
                headers: [],
                excludedHeaders: ['Username', 'Actions'],
                mounted: false,
                statusIndicators: {
                    'Valid': 'text-green-400 bg-green-400/10',
                    'Validating': 'text-yellow-400 bg-yellow-400/10',
                    'Discovering Accounts Failed': 'text-yellow-400 bg-yellow-400/10',
                    'Authentication Failed - Invalid Credentials': 'text-rose-400 bg-rose-400/10',
                    'Authentication Failed - Other': 'text-rose-400 bg-rose-400/10',
                    'Vendor Portal Deprecated': 'text-gray-400 bg-gray-400/10',
                    'Inactive': 'text-gray-400 bg-gray-400/10',
                },
                statusNames: {
                    'Valid': 'Valid',
                    'Validating': 'Validating',
                    'Discovering Accounts Failed': 'Discovering Accounts Failed',
                    'Authentication Failed - Invalid Credentials': 'Authentication Failed - Invalid Credentials',
                    'Authentication Failed - Other': 'Authentication Failed - Other',
                    'Vendor Portal Deprecated': 'Rover Deprecated',
                    'Inactive': 'Inactive',
                },
                filtersInUse: 0,
            }
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.vendor-portal-credentials.index'), this.queryFilters, {
                    onFinish: () => { this.saving = false; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('vendor-portal-credentials.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = {...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.statuses
                    : this.statuses.filter((status) => {
                        return status.text.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.statuses,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>
