<template>
    <Head :title="`Service Termination Date Change - ${serviceActivity.service_id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">
                {{ serviceActivity.service_id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Service Termination Date Change</span>
        </nav>
    </Teleport>

    <div class="mt-6 mb-12 text-base lg:text-lg max-w-screen-lg mx-auto">
        <div class="md:px-12">
            <div class="flex justify-between items-center my-6">
                <h1 class="text-2xl font-bold">Service Termination Date Change</h1>

                <div class="flex items-center gap-x-4">
                    <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="btn btn-gray gap-x-2">
                        Back to Service
                    </inertia-link>
                    <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
                </div>
            </div>

            <div class="rounded-md bg-blue-50 p-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <icon name="info-circle" class="size-5 text-blue-400 fill-current" />
                    </div>
                    <div class="ml-3 flex-1">
                        <p class="text-base text-blue-700">
                            Service termination date changes cannot be edited. If you need to change the termination date on this service, please delete this change and enter a new service termination change from the service edit screen.
                        </p>
                    </div>
                </div>
            </div>

            <div>
                <div class="relative mt-12">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-start">
                        <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Details</span>
                    </div>
                </div>

                <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4 px-4">
                    <div>
                        <label class="form-label">Change Requester</label>
                        <div class="text-base mt-2">{{ decoratedChangeRequester }}</div>
                    </div>

                    <div>
                        <label class="form-label">Termination Date</label>
                        <div class="text-base mt-2">{{ $filters.format_date(serviceActivity.serviceSnapshot.termination_date) }}</div>
                    </div>

                    <div>
                        <label class="form-label">Change Reason</label>
                        <div class="text-base mt-2">{{ serviceActivity.reason }}</div>
                    </div>

                    <div>
                        <label class="form-label">Internal Change Notes</label>
                        <div class="text-base mt-2">{{ serviceActivity.internal_change_notes }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/**
 * This page is a Wizard to help user along with service changes
 */
import Icon from '@/Shared/Icon.vue';
import {Head, router, usePage} from '@inertiajs/vue3';
import {ref, inject, computed, onMounted} from 'vue';

/**
 * Props
 */
const props = defineProps({
    errors: {
        type: Object,
        default: () => ({})
    },

    serviceActivity: {
        type: Object,
        required: true
    },

    serviceCreationActivity: {
        type: Object,
        required: true
    },
});

/**
 * Injected
 */
const route = inject('route');
const page = usePage();

onMounted(() => {
    mounted.value = true;
});

/**
 * Data
 */
const saving = ref(false);
const mounted = ref(false);

/**
 * Methods
 */
function deleteServiceChange() {
    if (window.confirm('Are you sure you want to delete this service termination date change?')) {
        router.delete(route('services.service-termination-date-changes.destroy', {
            serviceId: props.serviceActivity.service_id,
            serviceActivityId: props.serviceActivity.id
        }));
    }
}

/**
 * Computed
 */
const decoratedChangeRequester = computed(() => {
    switch (props.serviceActivity.change_requester) {
        case 'broker':
            return page.props?.authUser?.tenant_name ?? 'Our Company';
        case 'client':
            return props.serviceActivity.serviceSnapshot.location.clientCompany.name;
        case 'vendor':
            return props.serviceActivity.serviceSnapshot.vendor.name ?? '';
        default:
            return props.serviceActivity.change_requester
    }
});
</script>
