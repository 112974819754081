<template>
    <Head title="Locations" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Locations</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :filters-in-use="filtersInUse"
        :search-id="'search-locations'"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('locations.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="w-full my-4">
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="locations.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ locations.from }} - {{ locations.to }} out of {{ locations.total }} Locations</div>

            <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
                <inertia-link :href="$route('locations.create')" class="btn btn-orange mr-2">
                    New
                </inertia-link>

                <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!locations.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

        <div>
            <div class="overflow-x-auto">
                <transition
                    enter-active-class="transition-height ease-in-out duration-500"
                    enter-from-class="h-0"
                    enter-to-class="h-full"
                    leave-active-class="transition-height ease-in-out duration-500"
                    leave-from class="h-full"
                    leave-to-class="h-0"
                >
                    <div v-show="locations.data.length && selectedLocationIds.length > 0" class="bg-white flex h-10 items-center sm:left-14">
                        <span v-if="selectedLocationIds.length === 1" class="mr-8">{{  `${selectedLocationIds.length} item selected` }}</span>
                        <span v-else class="mr-8">{{  `${selectedLocationIds.length} items selected` }}</span>

                        <div class="flex space-x-3">
                            <button type="button" @click="archiveSelectedLocations" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyValidLocationsSelected">Archive</button>
                            <button type="button" @click="unarchiveSelectedLocations" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyArchivedLocationsSelected">Unarchive</button>
                        </div>
                    </div>
                </transition>
                <table class="table table-condensed" ref="table">
                    <thead>
                        <tr>
                            <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                                <input
                                    type="checkbox"
                                    :disabled="!locations.data.length"
                                    class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6"
                                    :checked="locations.data.length && (indeterminate || selectedLocationIds.length === locations.data.length)"
                                    :indeterminate="indeterminate"
                                    @change="selectedLocationIds = $event.target.checked ? locations.data.map((location) => location.id) : []"
                                />
                            </th>
                            <sortable-table-header field="locations.name" :filters="filters" route-name="locations.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Name</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Client Company')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Client Company</th>
                            <th v-show="form.selected_headers.includes('Client Account')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Client Account</th>
                            <th v-show="form.selected_headers.includes('Address')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Address</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Archived At')" scope="col" field="locations.archived_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="locations.index">Archived At</sortable-table-header>
                            <th scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr :class="{'table-row-deleted':location.deleted_at}" v-for="location in locations.data" :key="location.id">
                            <td class="relative px-7 sm:w-12 sm:px-6">
                                <div v-if="selectedLocationIds.includes(location.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                                <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="location.id" v-model="selectedLocationIds" />
                            </td>
                            <td>
                                <inertia-link data-cy="locations.index.show-link" v-if="!location.deleted_at" :href="$route('locations.show', location.id)" class="link" target="_blank">
                                    {{ location.name }}
                                </inertia-link>
                                <span v-else>{{ location.name }}</span>
                            </td>
                            <td v-show="form.selected_headers.includes('Client Company')">
                                <inertia-link :href="$route('client-companies.show', location.client_company_id)" class="link" target="_blank">
                                    {{ location.clientCompany.name }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Client Account')">
                                <template v-if="location.client_account_id">
                                    <inertia-link :href="$route('client-accounts.show', location.client_account_id)" class="link" target="_blank">
                                        {{ location.clientAccount.name }}
                                    </inertia-link>
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td v-show="form.selected_headers.includes('Address')">
                                {{ location.address.full_address }}
                            </td>
                            <td v-show="form.selected_headers.includes('Archived At')">
                                {{ $filters.format_date_time(location.archived_at) }}
                            </td>
                            <td class="text-right">
                                <template v-if="!location.deleted_at">
                                    <inertia-link :href="$route('locations.show', [location.id])" class="link mr-3" title="View This Location">
                                        View
                                    </inertia-link>
                                    <inertia-link :href="$route('locations.edit', [location.id])" class="link" title="Edit This Location">
                                        Edit
                                    </inertia-link>
                                </template>
                                <template v-else>Deleted</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="locations.links" />
        </div>

        <div v-if="!locations.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="map-marker-alt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Locations Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="location-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <select-input id="client-accounts" class="col-span-1" v-model="form.client_accounts" label="Client Account">
                    <option value="except">Hide Missing</option>
                    <option value="include">Include Missing</option>
                    <option value="only">Only Missing</option>
                </select-input>

                <select-input id="archived" name="archived" class="col-span-1" v-model="form.archived" label="Archived">
                    <option value="except">Hide Archived</option>
                    <option value="include">Include Archived</option>
                    <option value="only">Only Archived</option>
                </select-input>

                <select-input id="deleted" class="col-span-1" v-model="form.with_deleted" label="Deleted">
                    <option value="exclude">Exclude</option>
                    <option value="include">Include</option>
                    <option value="only">Only</option>
                </select-input>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import { throttle } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from '@/Shared/SlideOver.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ColumnSelector from '@/Shared/ColumnSelector.vue';
    import IndexSearchForm from '@/Shared/IndexSearchForm.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';

    export default {
        components: {
            Head,
            Icon,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            ColumnSelector,
            IndexSearchForm,
            SortableTableHeader,
        },

        props: {
            locations: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    client_accounts: this.filters.client_accounts,
                    with_deleted: this.filters.with_deleted ?? 'except',
                    selected_headers: this.filters.selected_headers,
                    archived: this.filters.archived,
                },
                saving: false,
                headers: [],
                excludedHeaders: ['Name', 'Actions'],
                mounted: false,
                filtersInUse: 0,
                selectedLocationIds: [],
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }
            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.locations.index'), this.queryFilters, {
                    onFinish: () => { this.saving = false; }
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('locations.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },

            archiveSelectedLocations() {
                this.$inertia.post(this.$route('locations.archive-selected'), {
                    location_ids: this.selectedLocationIds,
                }, {
                    onSuccess: () => this.selectedLocationIds = []
                });
            },

            unarchiveSelectedLocations() {
                this.$inertia.post(this.$route('locations.unarchive-selected'), {
                    location_ids: this.selectedLocationIds,
                }, {
                    onSuccess: () => this.selectedLocationIds = []
                });
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form}

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.client_accounts,
                    form.with_deleted,
                    form.archived
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            },

            indeterminate() {
                return this.selectedLocationIds.length > 0 && this.selectedLocationIds.length < this.locations.data.length;
            },

            onlyValidLocationsSelected() {
                return this.selectedLocationIds.every((id) => {
                    const location = this.locations.data.find((location) => location.id === id);

                    if (!location || location.archived_at || location.deleted_at) {
                        return false;
                    }

                    const activeServicesCount = location.active_services ? location.active_services.length : 0;
                    const activeIncidentsCount = location.active_incidents ? location.active_incidents.length : 0;
                    const pendingServicesCount = location.pending_services ? location.pending_services.length : 0;

                    return (activeServicesCount + activeIncidentsCount + pendingServicesCount) === 0;
                });
            },

            onlyArchivedLocationsSelected() {
                return this.selectedLocationIds.every((id) => {
                    const location = this.locations.data.find((location) => location.id === id);
                    return location && location.archived_at;
                });
            },
        }
    }
</script>
