<template>
    <Head :title="`Edit Vendor Pricing Change - ${props.serviceActivity.service_id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="route('services.show', props.serviceActivity.service_id)" class="breadcrumb-link">
                {{ props.serviceActivity.service_id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Vendor Pricing Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 mb-12 text-base lg:text-lg">
        <div class="md:px-12">
            <div class="flex justify-between items-center my-6">
                <h1 class="text-2xl font-bold">Edit Vendor Pricing Change</h1>

                <div class="flex items-center gap-x-4">
                    <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="btn btn-gray gap-x-2">
                        Cancel Edit
                    </inertia-link>
                    <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
                </div>
            </div>

            <fieldset class="border-t border-gray-300">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Change Details
                </legend>

                <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-3 my-4 px-4">
                    <select-input
                        :disabled="true"
                        v-model="form.change_requester"
                        :errors="errors['change_requester']"
                        label="Requested By"
                        mark-as-required
                    >
                        <option value="vendor">{{ props.serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                    </select-input>

                    <revert-input v-model="form.actual_date" label="Effective Date" mark-as-required>
                        <date-input :min="props.serviceCreationActivity.actual_date" v-model="form.actual_date" help-text="Date of vendor pricing change" :errors="errors['actual_date']"/>
                    </revert-input>

                    <revert-input v-model="form.change_reason" label="Why are you making this change?" mark-as-required>
                        <select-input v-model="form.change_reason">
                            <option>Annual Price Increase</option>
                            <option>Other</option>
                            <option>Savings</option>
                        </select-input>
                    </revert-input>
                </div>
            </fieldset>

            <fieldset class="border-t border-gray-300">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Pricing
                </legend>

                <div class="grid gap-x-4 gap-y-6 grid-cols-2 my-4 px-4">
                    <revert-input
                        v-if="serviceHasVendorBaseCharge"
                        class="col-span-2 md:col-span-1"
                        label="Vendor Base Charge"
                        v-model="form.vendor_base_charge"
                    >
                        <money-input
                            v-model="form.vendor_base_charge"
                            :defaultCurrency="currency"
                            placeholder="Vendor Base Charge"
                            :errors="errors['vendor_base_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="serviceHasVendorPerUnitCharge"
                        class="col-span-2 md:col-span-1"
                        label="Vendor Per Unit Charge"
                        :help-text="vendorPerUnitHelpText"
                        v-model="form.vendor_per_unit_charge"
                    >
                        <money-input
                            v-model="form.vendor_per_unit_charge"
                            :defaultCurrency="currency"
                            placeholder="Vendor Per Unit Charge"
                            :errors="errors['vendor_per_unit_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="serviceHasVendorPerOccurrenceCharge"
                        class="col-span-2 md:col-span-1"
                        label="Vendor Per Occurrence Charge"
                        help-text="Haul Amount"
                        v-model="form.vendor_per_occurrence_charge"
                    >
                        <money-input
                            v-model="form.vendor_per_occurrence_charge"
                            :defaultCurrency="currency"
                            placeholder="Per Occurrence Charge"
                            :errors="errors['vendor_per_occurrence_charge']"
                        />
                    </revert-input>

                    <revert-input
                        v-if="serviceHasUnitsIncludedPerOccurrence"
                        class="col-span-2 md:col-span-1"
                        label="Units Included Per Occurrence"
                        help-text="Tons Included Per Haul"
                        v-model="form.units_included_per_occurrence"
                        mark-as-required
                    >
                        <text-input
                            v-model="form.units_included_per_occurrence"
                            type="number"
                            min="0"
                            :errors="errors['units_included_per_occurrence']"
                        />
                    </revert-input>
                </div>
            </fieldset>

            <div class="relative mt-12">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Other</span>
                </div>
            </div>

            <div class="px-4 mt-4 mb-8">
                <revert-input v-model="form.internal_change_notes" class="col-span-2" label="Internal Change Notes">
                    <textarea-input v-model="form.internal_change_notes"/>
                </revert-input>
            </div>

            <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason">
                Submit Change
            </loading-button>
        </div>
    </div>
</template>

<script setup>
/**
 * This page is a Wizard to help user along with service changes
 */
import DateInput from '@/Shared/DateInput.vue';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import MoneyInput from '@/Shared/MoneyInput.vue';
import RevertInput from '@/Shared/RevertInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import TextInput from "@/Shared/TextInput.vue";
import {format, startOfToday} from "date-fns";
import {Head, router} from '@inertiajs/vue3';
import {inject, computed, onMounted, ref} from "vue";

/**
 * Props
 */
const props = defineProps({
    errors: {
        type: Object,
        default: () => ({})
    },

    serviceActivity: {
        type: Object,
        required: true
    },

    serviceCreationActivity: {
        type: Object,
        required: true
    },
})

/**
 * Injected
 */
const route = inject('route')

onMounted(() => {
    mounted.value = true;
});

/**
 * Data
 */
const saving = ref(false);
const today = ref(format(startOfToday(), 'yyyy-MM-dd'));
const mounted = ref(false);
const currency = ref(props.serviceActivity.serviceSnapshot.vendor.currency);
const form = ref({
    actual_date: props.serviceActivity.actual_date,
    change_reason: props.serviceActivity.reason,
    change_requester: props.serviceActivity.change_requester,
    internal_change_notes: props.serviceActivity.internal_change_notes,
    vendor_base_charge: props.serviceActivity.serviceSnapshot.vendor_base_charge ?? null,
    vendor_per_unit_charge: props.serviceActivity.serviceSnapshot.vendor_per_unit_charge ?? null,
    vendor_per_occurrence_charge: props.serviceActivity.serviceSnapshot.vendor_per_occurrence_charge ?? null,
    units_included_per_occurrence: props.serviceActivity.serviceSnapshot.units_included_per_occurrence ?? null,
})

/**
 * Methods
 */
function submitChange() {
    saving.value = true;

    router.put(route('services.vendor-pricing-changes.update', [props.serviceActivity.service_id, props.serviceActivity.id]), {
        ...form.value,
    }, {
        onFinish: () => saving.value = false
    });
}

function deleteServiceChange() {
    if (window.confirm('Are you sure you want to delete this vendor pricing change?')) {
        router.delete(route('services.vendor-pricing-changes.destroy', {
            serviceId: props.serviceActivity.service_id,
            serviceActivityId: props.serviceActivity.id
        }));
    }
}

/**
 * Computed
 */
const serviceHasVendorBaseCharge = computed(() => {
    return props.serviceActivity.service?.serviceType?.enabled_type_fields.includes('base_charges');
});

const serviceHasVendorPerUnitCharge = computed(() => {
    return props.serviceActivity.service?.serviceType?.enabled_type_fields.includes('per_unit_charges');
});

const serviceHasVendorPerOccurrenceCharge = computed(() => {
    return props.serviceActivity.service?.serviceType?.enabled_type_fields.includes('per_occurrence_charges');
});

const serviceHasUnitsIncludedPerOccurrence = computed(() => {
    return props.serviceActivity.service?.serviceType?.enabled_type_fields.includes('units_included_per_occurrence');
});

const vendorPerUnitHelpText = computed(() => {
    return serviceHasVendorPerUnitCharge ?
        "(Disposal Amount Per Ton)" :
        "";
});
</script>
