<template>
    <div class="w-full">
        <div class="grid grid-cols-6 gap-x-4 gap-y-6 mb-4">
            <div class="col-span-2">
                <date-input v-model="startDateInput" :label="startDateLabel"/>
            </div>

            <div class="col-span-2">
                <label class="form-label inline-flex items-center gap-2">
                    Repeat every
                    <icon name="repeat-alt" class="fill-current size-4"/>
                </label>
                <div class="grid grid-cols-4 gap-1">
                    <select-input id="interval" class="col-span-2" v-model="intervalInput">
                        <option v-for="number in Array.from({length: 99}, (_, i) => i + 1)" :key="number" :value="number">{{ number }}</option>
                    </select-input>

                    <select-input id="freq" class="col-span-2" v-model="frequencyInput">
                        <option value="DAILY">{{ intervalInput === 1 ? 'Day' : 'Days' }}</option>
                        <option value="WEEKLY">{{ intervalInput === 1 ? 'Week' : 'Weeks' }}</option>
                        <option value="MONTHLY">{{ intervalInput === 1 ? 'Month' : 'Months' }}</option>
                        <option value="YEARLY">{{ intervalInput === 1 ? 'Year' : 'Years' }}</option>
                    </select-input>
                </div>
            </div>

            <text-input class="col-span-2" min="0" v-model="averageInput" type="number" :label="weeklyAverageLabel" :disabled="selectedWeekdays.length > 0 || this.frequencyInput==='DAILY'" @change="userSetAverageInput"/>
        </div>

        <div v-if="showDayPicker" class="grid grid-cols-7 my-6">
            <label class="form-label col-span-7 mb-2">Days of the Week</label>
            <label class="form-label"><input type="checkbox" class="form-checkbox mr-1" v-model="selectedWeekdays" :value="0"> Mon</label>
            <label class="form-label"><input type="checkbox" class="form-checkbox mr-1" v-model="selectedWeekdays" :value="1"> Tue</label>
            <label class="form-label"><input type="checkbox" class="form-checkbox mr-1" v-model="selectedWeekdays" :value="2"> Wed</label>
            <label class="form-label"><input type="checkbox" class="form-checkbox mr-1" v-model="selectedWeekdays" :value="3"> Thu</label>
            <label class="form-label"><input type="checkbox" class="form-checkbox mr-1" v-model="selectedWeekdays" :value="4"> Fri</label>
            <label class="form-label"><input type="checkbox" class="form-checkbox mr-1" v-model="selectedWeekdays" :value="5"> Sat</label>
            <label class="form-label"><input type="checkbox" class="form-checkbox mr-1" v-model="selectedWeekdays" :value="6"> Sun</label>
        </div>
        <div class="flex flex-col gap-1 w-full" v-else>
            <label class="form-label">Schedule:</label>
            <div>{{ rRule.toText() }}</div>
        </div>

        <div class="mt-4">
            <button class="bg-gray-50 rounded-md py-3.5 w-full inline-flex justify-center items-center gap-2 link" :class="{'rounded-b-none': showComputedSchedule}" @click="showComputedSchedule = !showComputedSchedule">
                {{ showComputedSchedule ? 'Hide' : 'Show' }} Computed Schedule
                <icon :name="showComputedSchedule ? 'chevron-up' : 'chevron-down'" class="inline fill-current size-3"/>
            </button>
        </div>
        <div class="overflow-hidden rounded-b-lg bg-gray-50 border-t" v-if="showComputedSchedule">
            <div class="px-4">
                <ul class="divide-y divide-gray-200 max-w-sm mx-auto py-4">
                    <li :key="scheduledDay" v-for="scheduledDay in nextScheduledDays" class="text-center py-3">
                        <div class="min-w-0 tracking-tight font-mono">
                            <p class="text-base text-gray-900">{{ formatDate(scheduledDay, "EEE, MMM dd, yyyy") }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/Shared/Icon.vue';
import TextInput from '@/Shared/TextInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import {RRule, rrulestr} from 'rrule';
import DateInput from '@/Shared/DateInput.vue';
import {format, formatISO9075, parse} from 'date-fns';
import {v4 as uuid} from 'uuid';

export default {
    components: {SelectInput, Icon, DateInput, TextInput},
    inheritAttrs: false,

    emits: [
        'update:modelValue',
        'update:weeklyAverage',
        'update:description'
    ],

    props: {
        id: {
            type: String,
            default() {
                return `rrule-input-${uuid()}`;
            },
        },

        modelValue: { // this is an rrule object that is structured like what the PHP library expects
            required: true,
            default: null
        },

        weeklyAverage: { // this is the number of times per week the schedule will occur, which can be derived from a user-entered value or an automatically generated one
            type: Number,
            default: null
        },

        description: { // this is the human-readable description of the rrule for use in other UI components
            type: String,
            default: null,
        },

        label: {
            type: String,
            default: 'Schedule',
        },

        startDateLabel: {
            type: String,
            default: "Start Date",
        },

        weeklyAverageLabel: {
            type: String,
            default: "Average Per Week",
        },

        startWithSchedule: {
            type: Boolean,
            default: false
        },

        errors: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            startDateInput: this.modelValue && this.modelValue.dtstartiso9075 ? format(new Date(this.modelValue.dtstartiso9075), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
            intervalInput: this.modelValue && this.modelValue.interval ? this.modelValue.interval : 1,
            frequencyInput: this.modelValue && this.modelValue.freq ? this.modelValue.freq : 'WEEKLY',
            averageInput: this.weeklyAverage,
            averageInputFromUser: this.weeklyAverage && (this.showDayPicker && this.selectedWeekdays.length===0 || !this.showDayPicker), // if the weekly average is already set at page load (i.e. on an edit page) and there are no weekdays selected, then assume the user entered it and don't mess with it. If there are weekdays selected, they cannot have entered it.
            selectedWeekdays: this.modelValue && this.modelValue.byday ? this.translateWeekdaysToNumbers(this.modelValue.byday.split(",")) : [],
            showComputedSchedule: this.startWithSchedule,
        };
    },

    methods: {
        formatDate(value, toDateFormat = 'MM/dd/yyyy', fromDateFormat = 'yyyy-MM-dd') {
            // helper method for UI
            return format(parse(value, fromDateFormat, new Date), toDateFormat);
        },

        translateWeekdaysToNumbers(days){
            // the php library uses two character strings for weekdays, the JS ones use numbers - convert for UI presentation
            return days.map(day => {
                switch (day) {
                    case 'MO':
                        return 0;
                    case 'TU':
                        return 1;
                    case 'WE':
                        return 2;
                    case 'TH':
                        return 3;
                    case 'FR':
                        return 4;
                    case 'SA':
                        return 5;
                    case 'SU':
                        return 6;
                }
            });
        },

        translateWeekdaysToStrings(days){
            // the php library uses two character strings for weekdays, the JS ones use numbers - convert for storage
            return days.map(day => {
                switch (day) {
                    case 0:
                        return 'MO';
                    case 1:
                        return 'TU';
                    case 2:
                        return 'WE';
                    case 3:
                        return 'TH';
                    case 4:
                        return 'FR';
                    case 5:
                        return 'SA';
                    case 6:
                        return 'SU';
                }
            });
        },

        setStartDate(value) {
            // other components can instruct this one to update its start date, e.g. when the user sets the service effective date
            this.startDateInput = value;
        },

        userSetAverageInput() {
            // the user has entered a value, so we should not auto generate it anymore
            this.averageInputFromUser = true
        },

        updateDescription() {
            // the alternate rrulestr method below is used because the main RRule object does not properly order weekdays sometimes
            this.$emit('update:description',
                this.rRule && this.weeklyAverage
                    ? rrulestr(this.rRule.toString()).toText()
                        + (this.frequencyInput!=="DAILY" && this.selectedWeekdays.length===0
                            ? ", " + this.weeklyAverage + (this.weeklyAverage!=1?" times":" time") + " per week"
                            : ""
                        )
                    : 'No schedule set'
            );
        }
    },

    watch: {
        frequencyInput(newValue) {
            if (newValue !== 'WEEKLY') {
                this.selectedWeekdays = []
            }
        },

        averageInput(newValue) {
            if (newValue == null || newValue == "") {
                // if the user clears the field out, take that as a sign they want the auto generated values back
                this.averageInputFromUser = false
            }
            this.$emit('update:weeklyAverage', newValue === '' ? 0 : (isNaN(parseFloat(newValue)) ? 0 : parseFloat(newValue)))
        },

        weeklyAverage() {
            this.updateDescription();
        },

        rRule: {
            handler: function(newValue) {
                this.updateDescription();

                if (!this.averageInputFromUser || this.selectedWeekdays.length > 0 || this.frequencyInput === 'DAILY') {
                    // the average per week value can either be entered by the user or be auto generated whenever the rrule changes. If the user has not entered it, it is okay to generate a new value whenever something changes. If there are weekdays selected, the field is disabled, so it should always be auto generated. Otherwise assume the user entered the value and don't mess with it.
                    this.averageInput = this.calculatedWeeklyAverage
                    this.averageInputFromUser = false
                }

                let phpRRule = {
                    rfcstring: newValue.toString(),
                    freq: this.frequencyInput,
                    interval: this.intervalInput,
                    byday: newValue.options.byweekday ? this.translateWeekdaysToStrings(newValue.options.byweekday) : null,
                    dtstart: newValue.options.dtstart,
                    dtstartiso9075: newValue.options.dtstart ? formatISO9075(new Date(
                        newValue.options.dtstart.getUTCFullYear(),
                        newValue.options.dtstart.getUTCMonth(),
                        newValue.options.dtstart.getUTCDate(),
                        newValue.options.dtstart.getUTCHours(),
                        newValue.options.dtstart.getUTCMinutes(),
                        newValue.options.dtstart.getUTCSeconds(),
                    )) : null
                }

                this.$emit('update:modelValue', {...phpRRule});
            },
            immediate: true, // run this watcher immediately on page load so there is always a model value generated from the fields
        }
    },

    computed: {
        showDayPicker() {
            return this.frequencyInput === 'WEEKLY'
        },

        rRule() {
            // this is the js-side rrule object, not to be confused with the php-side one (generated in the watcher for this property)
            return new RRule({
                freq: RRule[this.frequencyInput],
                interval: this.intervalInput,
                byweekday: this.selectedWeekdays.length ? this.selectedWeekdays : undefined,
                dtstart: new Date(this.startDateInput)
            });
        },

        nextScheduledDays() {
            // generates the next several scheduled days for display in the UI
            let currentDate = new Date(this.startDateInput);

            let limitedRRule = new RRule({
                freq: RRule[this.frequencyInput],
                interval: this.intervalInput,
                byweekday: this.selectedWeekdays.length ? this.selectedWeekdays : undefined,
                dtstart: currentDate,
                count: 10
            })

            return limitedRRule.all().map((date) => {
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                return format(date, "yyyy-MM-dd");
            }).slice(0, 10);
        },

        calculatedWeeklyAverage() {
            // this is the value expected for the number of days selected, used if the user has not entered a value manually
            let currentDate = new Date(this.startDateInput);
            let oneYearFromToday = new Date(currentDate.getUTCFullYear() + 1, currentDate.getUTCMonth(), currentDate.getUTCDate() - 1, 0, 0, 0);

            let limitedRRule = new RRule({
                freq: RRule[this.frequencyInput],
                interval: this.intervalInput,
                byweekday: this.selectedWeekdays.length ? this.selectedWeekdays : undefined,
                dtstart: currentDate,
                until: oneYearFromToday
            })

            let weeklyIntervalMultiplier = 1;
            if (this.intervalInput === 1) {
                weeklyIntervalMultiplier = 1;
            } else if (this.intervalInput > 1) {
                weeklyIntervalMultiplier = 1 / this.intervalInput;
            }

            if (this.frequencyInput === 'WEEKLY') {
                if (this.selectedWeekdays.length) {
                    return Math.max(1, this.selectedWeekdays.length) * weeklyIntervalMultiplier;
                } else {
                    return 0;
                }
            } else {
                return limitedRRule.all().length / 52;
            }
        }
    }
}
</script>
