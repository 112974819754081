<template>
    <Head title="Webhooks" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Webhooks</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="webhooks" />

                <div class="lg:col-span-9 px-4 sm:px-6">
                    <div class="flex my-4 items-center">
                        <div class="w-1/2">
                            <div class="my-2 mb-4">
                                <div class="input-group mt-0">
                                    <span class="input-group-item-left">
                                        <icon name="search" class="size-4 text-gray-400 fill-current" />
                                    </span>

                                    <input type="text" v-model="form.search" placeholder="Search" class="input-group-field">
                                </div>
                            </div>
                        </div>

                        <div class="ml-auto">
                            <inertia-link :href="$route('tenant-settings.webhooks.create')" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="flex my-4 items-center">
                        <button type="button" @click="showWebhookSecret = !showWebhookSecret" class="btn btn-gray">
                            {{ showWebhookSecret ? 'Hide' : 'Show' }} Secret
                        </button>

                        <loading-button class="btn btn-orange ml-4" :loading="state === 'refreshing'" :disabled="state !== 'passive'" @click.prevent="refreshSecret">
                            Refresh
                        </loading-button>
                    </div>
                    <div class="mt-4 mr-4">
                        <span class="font-semibold text-gray-900">Webhook Secret:</span> {{ showWebhookSecret ? webhookSecret : '********' }}
                    </div>

                    <div v-if="webhooks.data.length" class="mt-10">
                        <div class="my-2">
                            Showing <span class="font-semibold text-gray-900">{{ webhooks.data.length }}</span> out of <span class="font-semibold text-gray-900">{{ webhooks.total }}</span> Webhooks
                        </div>

                        <div class="overflow-x-auto">
                            <table class="table table-condensed">
                                <thead>
                                <tr>
                                    <sortable-table-header field="event" class="font-semibold" :filters="filters" route-name="tenant-settings.webhooks.index">Event</sortable-table-header>
                                    <sortable-table-header field="url" class="font-semibold" :filters="filters" route-name="tenant-settings.webhooks.index">Url</sortable-table-header>
                                    <th></th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr v-for="webhook in webhooks.data" :key="webhook.id">
                                    <td>
                                        <inertia-link class="link" :href="$route('tenant-settings.webhooks.edit', [webhook.id])">
                                            {{ webhook.event }}
                                        </inertia-link>
                                    </td>
                                    <td>
                                        {{ webhook.url }}
                                    </td>
                                    <td class="text-right">
                                        <span class="inline-block">
                                            <inertia-link :href="$route('tenant-settings.webhooks.edit', [webhook.id])" class="link text-lg" title="Edit Webhook">
                                                <icon name="edit" class="fill-current w-5 h-5" />
                                            </inertia-link>
                                        </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <pagination :links="webhooks.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="email" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Webhooks Found</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup>
import {throttle, pickBy} from 'lodash-es';
import { router } from '@inertiajs/vue3';
import { ref, reactive, inject, watch, onMounted } from 'vue';

// Components
import { Head } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';
import Pagination from '@/Shared/Pagination.vue';
import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
import SubNav from '../SubNav.vue';
import ToggleSwitchInput from "@/Shared/ToggleSwitchInput.vue";
import LoadingButton from "@/Shared/LoadingButton.vue";

// Inject
const route = inject('route');
const state = ref('passive');

// Props
const props = defineProps({
    webhooks: {
        type: Object,
        required: true
    },

    webhookSecret: {
        type: String,
        required: true
    },

    filters: {
        type: Object,
        required: true
    }
});

// State
const form = reactive({search: props.filters.search});
const mounted = ref(false);
const showWebhookSecret = ref(false);

// Mount
onMounted(() => {
    mounted.value = true
});

function refreshSecret() {
    if (state.value !== 'passive') {
        return;
    }

    const confirmed = confirm('Are you sure you want to refresh your Webhook Secret?');

    if (confirmed) {
        state.value = 'refreshing';

        router.post(route('tenant-settings.webhook-secret.update'), null, {
            onFinish: () => {state.value = 'passive'}
        });
    }
}

// Watch
watch(form, throttle(function() {
    let query = pickBy(form);
    query = Object.keys(query).length ? query : {remember: 'forget'};

    router.get(route('tenant-settings.webhooks.index'), query, {preserveState: true});
}, 150), {deep: true});
</script>
