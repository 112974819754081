<template>
    <section class="sticky-note" :id="id" aria-labelledby="sticky-note-title">
        <div>
            <div class="rounded-md shadow-md px-4 py-5 sm:px-6 bg-yellow-50">
                <div class="flex justify-between items-center pb-2 border-b border-amber-200">
                    <h2 id="sticky-notes-title" class="text-lg leading-6 font-medium text-gray-900">Sticky Notes</h2>
                    <button type="button" @click="editStickyNote" v-if="!editingNotes">
                        <icon name="pencil" class="h-4 w-4 fill-current text-amber-300 hover:text-amber-500"/>
                    </button>
                </div>
                <div id="sticky_notes_content" class="cursor-pointer pt-2" v-if="!editingNotes">
                    <p v-if="props.content" class="sticky-note" v-html="props.content"/>
                    <p v-else class="leading-tight italic">There are no sticky notes on this entry.</p>
                </div>
                <div v-else>
                    <form>
                        <div class="quill-container border-b border-amber-200 focus-within:border-amber-600">
                            <quill-editor
                                label="Edit Sticky Note"
                                v-model="stickyNoteContent"
                                :hideLabel="hideLabel"
                                :errors="error"
                                :options="quillOptions"
                            />
                        </div>
                        <div class="flex justify-end mt-4">
                            <div v-if="!savingNotes" class="flex items-center space-x-2">
                                <button type="button" @click="editingNotes = false" class="btn text-amber-700 ring-1 ring-amber-300 hover:ring-amber-700 hover:text-amber-900">
                                    Cancel
                                </button>
                                <button type="button" @click="saveStickyNote" class="btn bg-amber-100 text-amber-700 hover:bg-amber-300 hover:text-amber-900">
                                    Save
                                </button>
                            </div>
                            <div v-else class="inline-flex gap-2 text-amber-600">
                               Saving... <icon name="spinner" class="h-6 w-6 fill-current animate-spin"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import {onMounted, onBeforeUnmount, ref, inject, watch} from 'vue';
    import { router } from '@inertiajs/vue3';
    import {v4 as uuid} from "uuid";

    import QuillEditor from "./QuillEditor.vue";
    import Icon from "./Icon.vue";

    inject('route');

    const emit = defineEmits(['saveStickyNote', 'saved']);
    const props = defineProps({
        content: {
            type: String,
            required: false,
            default: '',
        },
        hideLabel: {
            type: Boolean,
            required: false,
            default: true,
        },
        savingRoute: {
            type: String,
            required: true,
        },
    });

    const id = `sticky-note-${uuid()}`;
    const stickyNoteContent = ref(props.content);
    const editingNotes = ref(false);
    const savingNotes = ref(false);
    const mounted = ref(false);
    const error = ref('');

    const quillOptions = {
        theme: "snow",
            placeholder: "Add your sticky note...",
            modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
            ],
        },
    };

    onMounted(() => {
        document.addEventListener('mousedown', handleStickyNoteClick);
    });

    onBeforeUnmount(() => {
        document.removeEventListener('mousedown', handleStickyNoteClick);
    });

    function saveStickyNote() {
        savingNotes.value = true;

        let formData = new FormData();
        formData.append('sticky_notes', stickyNoteContent.value);

        router.post(props.savingRoute, formData, {
            onError: () => error.value = 'An error occurred while saving the sticky note. Please try again later.',
            onSuccess: () => {
                editingNotes.value = false;
                emit('saved', stickyNoteContent.value);
            },
            onFinish: () => savingNotes.value = false,
        });
    }

    function editStickyNote() {
        editingNotes.value = true;
    }

    function handleStickyNoteClick(e) {
        let stickyNote = document.querySelector('#'+id);
        stickyNote.contains(e.target) && !editingNotes.value && editStickyNote()
    }
</script>

<style>
    .sticky-note .quill-container .ql-toolbar.ql-snow {
        border: 0 !important;
    }

    .sticky-note .quill-container .ql-editor {
        border: 0;
        border-top: 1px solid rgb(253 230 138 / var(--tw-border-opacity));
    }
    .sticky-note .quill-container .ql-editor:focus {
        border: 0;
        border-top: 1px solid rgb(217 119 6 / var(--tw-border-opacity));
    }

    .sticky-note .quill-container .ql-container.ql-snow {
        border: 0 !important;
        box-shadow: none !important;
    }
</style>
