<template>
    <Head title="Client Contracts" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Client Contracts</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :search-id="'search-client-contracts'"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('client-contracts.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="clientContracts.data.length" class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ clientContracts.from }} - {{ clientContracts.to }} out of {{ clientContracts.total }} Client Contracts</div>

        <div class="flex col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
            <inertia-link v-if="$page.props.permissions.accessClientContracts" :href="$route('client-contracts.create')" class="btn btn-orange mr-2">New</inertia-link>

            <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!clientContracts.data.length">
                Export to CSV
            </loading-button>
        </div>
    </div>

    <div>
        <div class="overflow-x-auto">
             <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <sortable-table-header field="id" :filters="filters" route-name="client-contracts.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Id</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Company')" field="client_companies.name" :filters="filters" route-name="client-contracts.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Company</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Integration Date')" field="integration_date" :filters="filters" route-name="client-contracts.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Integration Date</sortable-table-header>
                        <th v-show="form.selected_headers.includes('Total Services')" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Total Services</th>
                        <th v-show="form.selected_headers.includes('Active Services')" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Active Services</th>
                        <th v-show="form.selected_headers.includes('Terminating Services')" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Terminating Services</th>
                        <th scope="col" class="w-36 px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="clientContract in clientContracts.data" :key="clientContract.id" >
                        <td>
                            <inertia-link data-cy="client-contracts.index.show-link" :href="$route('client-contracts.show', clientContract.id)" class="link">{{ clientContract.id }}</inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Company')">
                            <inertia-link :href="$route('client-companies.show', clientContract.client_company_id)" class="link" title="Show Company">
                                {{ clientContract.clientCompany.name }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Integration Date')">
                            {{ $filters.format_date(clientContract.integration_date) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Total Services')" class="text-right">
                            {{ clientContract.services.length }}
                        </td>
                        <td v-show="form.selected_headers.includes('Active Services')" class="text-right">
                            {{ clientContract.active_services_count }}
                        </td>
                        <td v-show="form.selected_headers.includes('Terminating Services')" class="text-right">
                            {{ clientContract.terminating_services_count }}
                        </td>
                        <td class="text-right">
                            <inertia-link :href="$route('client-contracts.show', clientContract.id)" class="link mr-3" title="View This Client Contract">
                                View
                            </inertia-link>
                            <inertia-link :href="$route('client-contracts.edit', clientContract.id)" class="link" title="Edit This Client Contract">
                                Edit
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="clientContracts.links" />
    </div>

    <div v-if="!clientContracts.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="file-signature" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Contracts Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="client-contract-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-4 items-center">
                <div class="col-span-1">
                    <label class="form-label">Integration Date</label>

                    <date-input v-model="form.integration_date_begin_date" :errors="errors.integration_date_begin_date" />

                    <span class="inline-block ml-3 mt-2 text-base text-gray-900">through</span>

                    <date-input v-model="form.integration_date_end_date" :errors="errors.integration_date_end_date" class="col-span-1" />
                </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import { throttle } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import Modal from '@/Shared/Modal.vue';
    import { Head } from '@inertiajs/vue3';
    import TextInput from '@/Shared/TextInput.vue';
    import DateInput from "@/Shared/DateInput.vue";
    import SlideOver from "@/Shared/SlideOver.vue";
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ColumnSelector from '@/Shared/ColumnSelector.vue';
    import IndexSearchForm from '@/Shared/IndexSearchForm.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';

    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            Head,
            Modal,
            DateInput,
            TextInput,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            ColumnSelector,
            IndexSearchForm,
            SortableTableHeader,

            // Tailwind UI Combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            clientContracts: {
                type: Object,
                required: true
            },

            filters: {
                Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    selected_headers: this.filters.selected_headers,
                    integration_date_begin_date: this.filters.integration_date_begin_date,
                    integration_date_end_date: this.filters.integration_date_end_date,
                },
                amendmentForm: {
                    clientContract: null,
                    reason: 'Contract change',
                    right_fit_location_id: null
                },
                headers: [],
                excludedHeaders: ['Id', 'Actions'],
                state: 'passive',
                saving: false,
                mounted: false,
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.client-contracts.index'), this.queryFilters, {
                    onFinish: () => {
                        this.saving = false;
                    }
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
                this.headers = headers;
            },

            showAddAmendmentModal(clientContract) {
                this.amendmentForm.clientContract = {...clientContract};

                this.$refs.addAmendmentModal.show();
            },

            emptyModal() {
                this.amendmentForm = {
                    clientContract: null,
                    reason: 'Contract change',
                    right_fit_location_id: null
                };
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('client-contracts.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.integration_date_begin_date,
                    form.integration_date_end_date,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        },
    }

</script>
