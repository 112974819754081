<template>
    <Head title="Revenue Dashboard" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Revenue - Dashboard</span>
        </nav>
    </Teleport>

    <horizontal-sub-nav current-tab="dashboard" />

    <div class="my-6 grid grid-cols-4 gap-6">
        <sub-nav class="col-span-4 sm:block md:col-span-1" current-nav="dashboard" :fiscal-period="fiscalPeriod" :numberOf="numberOf" />

        <div class="col-span-4 md:col-span-3">
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="px-4 sm:px-6 lg:px-8">
                <div class="sm:flex sm:items-center">
                    <div class="sm:flex-auto">
                        <h1 class="text-xl font-semibold text-gray-900">
                            All Revenue Line Item Sources
                        </h1>
                        <p class="mt-2 text-sm text-gray-700">

                        </p>
                    </div>

                    <div v-if="otherUsers.length > 0" class="flex -space-x-2 overflow-hidden mb-1">
                        <template v-for="user in otherUsers" :key="user.id">
                            <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.name">
                            <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                                <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
                            </span>
                        </template>
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-end">
                    <form action="" id="fiscal-period-form" class="w-auto">
                        <select-input name="current-fiscal-period" id="current-fiscal-period" v-model="current_fiscal_period_id" label="Fiscal Period">
                            <option v-for="fiscalPeriod in fiscalPeriods" :key="fiscalPeriod.id" :value="fiscalPeriod.id">
                                {{ fiscalPeriod.year }}-{{ fiscalPeriod.month }}
                            </option>
                        </select-input>
                    </form>

                    <div class="inline-block col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
                        <div class="flex items-end gap-4">
                             <inertia-link :href="$route('client-invoices.create', {fiscal_period_id: fiscalPeriod.id})" class="btn btn-gray">
                                Create Invoice
                            </inertia-link>

                            <loading-button :loading="state === 'generating-invoices'" type="button" @click="createClientLineItems" class="btn btn-gray">
                                Batch Create Invoices
                            </loading-button>
                        </div>
                    </div>
                </div>

                <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="p-5">
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <!-- Heroicon name: outline/scale -->
                                    <svg class="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                    </svg>
                                </div>

                                <div class="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt class="text-sm font-medium text-gray-500 truncate">Billable Expenses</dt>
                                        <dd>
                                            <div class="text-lg font-medium text-gray-900">{{ $filters.format_money(totals.unbilledBillableExpenses) }}</div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div class="bg-gray-50 px-5 py-3">
                            <div class="text-sm">
                                <inertia-link :href="$route('revenue.billable-expenses', [current_fiscal_period_id])" class="font-medium text-blue-700 hover:text-blue-900"> View all ({{ numberOf.billableExpenses }}) </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="p-5">
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <!-- Heroicon name: outline/scale -->
                                    <svg class="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                    </svg>
                                </div>
                                <div class="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt class="text-sm font-medium text-gray-500 truncate">Service Charges</dt>
                                        <dd>
                                            <div class="text-lg font-medium text-gray-900">{{ $filters.format_money(totals.unbilledServiceCharges) }}</div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div class="bg-gray-50 px-5 py-3">
                            <div class="text-sm">
                                <inertia-link :href="$route('revenue.brokered-services', [current_fiscal_period_id])" class="font-medium text-blue-700 hover:text-blue-900"> View all ({{ numberOf.brokeredServices }}) </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="p-5">
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <!-- Heroicon name: outline/scale -->
                                    <svg class="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                    </svg>
                                </div>
                                <div class="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt class="text-sm font-medium text-gray-500 truncate">Client Service Fees</dt>
                                        <dd>
                                            <div class="text-lg font-medium text-gray-900">{{ $filters.format_money(totals.unbilledClientServiceFees) }}</div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div class="bg-gray-50 px-5 py-3">
                            <div class="text-sm">
                                <inertia-link :href="$route('revenue.client-service-fees', [current_fiscal_period_id])" class="font-medium text-blue-700 hover:text-blue-900"> View all ({{ numberOf.clientServiceFees }}) </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="p-5">
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <!-- Heroicon name: outline/scale -->
                                    <svg class="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                    </svg>
                                </div>
                                <div class="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt class="text-sm font-medium text-gray-500 truncate">Location Fees</dt>
                                        <dd>
                                            <div class="text-lg font-medium text-gray-900">{{ $filters.format_money(totals.unbilledLocationFees) }}</div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div class="bg-gray-50 px-5 py-3">
                            <div class="text-sm">
                                <inertia-link :href="$route('revenue.location-fees', [current_fiscal_period_id])" class="font-medium text-blue-700 hover:text-blue-900"> View all ({{ numberOf.locationFees }}) </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="p-5">
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <!-- Heroicon name: outline/scale -->
                                    <svg class="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                    </svg>
                                </div>
                                <div class="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt class="text-sm font-medium text-gray-500 truncate">Client Account Fees</dt>
                                        <dd>
                                            <div class="text-lg font-medium text-gray-900">{{ $filters.format_money(totals.unbilledClientAccountFees) }}</div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div class="bg-gray-50 px-5 py-3">
                            <div class="text-sm">
                                <inertia-link :href="$route('revenue.client-account-fees', [current_fiscal_period_id])" class="font-medium text-blue-700 hover:text-blue-900"> View all ({{ numberOf.clientAccountFees }}) </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="p-5">
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <!-- Heroicon name: outline/scale -->
                                    <svg class="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                    </svg>
                                </div>
                                <div class="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt class="text-sm font-medium text-gray-500 truncate">Prior Period Adjustments</dt>
                                        <dd>
                                            <div class="text-lg font-medium text-gray-900">{{ $filters.format_money(totals.unbilledPriorPeriodAdjustments) }}</div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div class="bg-gray-50 px-5 py-3">
                            <div class="text-sm">
                                <inertia-link :href="$route('revenue.prior-period-adjustments', [current_fiscal_period_id])" class="font-medium text-blue-700 hover:text-blue-900"> View all ({{ numberOf.priorPeriodAdjustments }}) </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="p-5">
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <!-- Heroicon name: outline/scale -->
                                    <svg class="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                    </svg>
                                </div>
                                <div class="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt class="text-sm font-medium text-gray-500 truncate">Client Line Item Adjustments</dt>
                                        <dd>
                                            <div class="text-lg font-medium text-gray-900">{{ $filters.format_money(totals.unbilledClientLineItemAdjustments) }}</div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div class="bg-gray-50 px-5 py-3">
                            <div class="text-sm">
                                <inertia-link :href="$route('revenue.client-line-item-adjustments', [current_fiscal_period_id])" class="font-medium text-blue-700 hover:text-blue-900"> View all ({{ numberOf.clientLineItemAdjustments }}) </inertia-link>
                            </div>
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>

<script setup>
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Icon from '@/Shared/Icon.vue';
    import SubNav from '@/Pages/Revenue/SubNav.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import HorizontalSubNav from '../Revenue/HorizontalSubNav.vue';
    import { Head, router } from '@inertiajs/vue3';
    import { ref, inject, watch, onMounted, onUnmounted } from 'vue';

    const props = defineProps({
        filters: {
            type: Object,
            required: true
        },

        fiscalPeriods: {
            type: Array,
            required: true
        },

        fiscalPeriod: {
            type: Object,
            required: true
        },

        numberOf: {
            type: Object,
            required: true
        },

        totals: {
            type: Object,
            required: true
        },

        isGeneratingClientInvoices: {
            type: Boolean,
            required: true
        }
    });

    const state = ref('passive');
    const mounted = ref(false);
    const otherUsers =  ref([]);
    const current_fiscal_period_id = ref(props.fiscalPeriod.id);

    const route = inject('route');
    const toast = inject('toast');

    onMounted(() => {
        mounted.value = true;

        if (props.isGeneratingClientInvoices) {
            state.value = 'generating-invoices';
        }
    });

    onUnmounted(() => {
        Echo.leave(`revenue.${props.fiscalPeriod.id}`);
    });

    Echo.join(`revenue.${props.fiscalPeriod.id}`)
        .here((users) => {
            otherUsers.value = users;
        })
        .joining((user) => {
            otherUsers.value.push(user);
        })
        .leaving((user) => {
            otherUsers.value = otherUsers.value.filter(otherUser => otherUser.id !== user.id);
        })
        .listen('.App\\Events\\ClientInvoice\\CreateMissingClientInvoicesJobHasStarted', (e) => {
            state.value = 'generating-invoices';
        })
        .listen('.App\\Events\\ClientInvoice\\CreateMissingClientInvoicesJobHasFinished', (e) => {
            state.value = 'passive';
            toast.success('Client Invoices were successfully generated.');
        });

    function createClientLineItems() {
        if (state.value === 'passive') {
            state.value = 'generating-invoices';

            router.post(route('revenue.create-missing-client-invoices', props.fiscalPeriod.id), {}, {
                onFinish: () => {state.value = 'passive'}
            });
        }
    }

    watch(() => current_fiscal_period_id.value, (newValue, oldValue) => {
        state.value = 'loading';

        router.get(route('revenue.dashboard', newValue), {}, {
            preserveState: true,
            onFinish: () => state.value = 'passive',
        });
    });
</script>
