<template>
    <div v-bind="$attrs">
        <Popover class="hidden relative md:block">
            <PopoverButton class="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 font-semibold text-base text-gray-900 border border-gray-300 hover:bg-gray-50">
                Columns <icon class="size-4 shrink-0 fill-current" name="chevron-down" />
            </PopoverButton>

            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <PopoverPanel class="absolute pin-l z-10 mt-2 w-screen max-w-max origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <fieldset class="w-full">
                        <legend class="sr-only">Select Columns</legend>
                        <div class="text-base">
                            <div class="flex items-center pl-4 border-b border-gray-200">
                                <input type="checkbox" id="all" name="all" class="form-checkbox size-[1.125rem]" :checked="indeterminateColumns || value.length === headers.length" :indeterminate="indeterminateColumns" @change="value = $event.target.checked ? headers : excludedHeaders" />
                                <label for="all" class="form-label flex-1 px-4 py-3 cursor-pointer select-none font-medium">All</label>
                            </div>
                            <div class="flex items-center pl-4" v-for="header in allowedHeaders" :key="header">
                                <input type="checkbox" :id="header" :name="header" class="form-checkbox size-[1.125rem]" :value="header" v-model="value" />
                                <label :for="header" class="form-label flex-1 px-4 py-3 cursor-pointer select-none font-normal">{{ header }}</label>
                            </div>
                        </div>
                    </fieldset>
                </PopoverPanel>
            </transition>
        </Popover>

        <div class="relative md:hidden">
            <button type="button" class="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-base font-semibold text-gray-900 shadow-sm border border-gray-300 hover:bg-gray-50" @click="$refs.slideOver.show()">
                Columns <icon class="size-4 shrink-0 fill-current" name="chevron-down" />
            </button>

            <slide-over ref="slideOver">
                <template #header="{close}">
                    <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                        <div class="flex items-start justify-between">
                            <h2 class="text-lg font-medium text-white" id="slide-over-title">Show Columns</h2>
                            <div class="ml-3 flex h-7 items-center">
                                <button @click="close" type="button" class="rounded-md text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-d-orange-400">
                                    <span class="sr-only">Close panel</span>
                                    <Icon name="times" class="size-6 fill-current" />
                                </button>
                            </div>
                        </div>
                    </div>
                </template>

                <template #body>
                    <fieldset class="w-full">
                        <legend class="sr-only">Select Columns</legend>
                        <div class="text-lg">
                            <div class="relative flex gap-x-3 items-start py-3 border-b border-gray-300">
                                <div class="ml-2 flex h-6 items-center">
                                    <input type="checkbox" id="all" name="all" class="m-4 rounded border-gray-300 text-d-orange-600 focus:ring-d-orange-500" :checked="indeterminateColumns || value.length === headers.length" :indeterminate="indeterminateColumns" @change="value = $event.target.checked ? headers : excludedHeaders" />
                                </div>
                                <div class="min-w-0 flex-1 leading-6">
                                    <label for="all" class="select-none font-medium text-gray-900">All</label>
                                </div>
                            </div>
                            <div class="relative flex gap-x-3 items-start py-3" v-for="header in allowedHeaders" :key="header">
                                <div class="ml-2 flex h-6 items-center">
                                    <input type="checkbox" :id="header" :name="header" class="m-4 rounded border-gray-300 text-d-orange-600 focus:ring-d-orange-500" :value="header" v-model="value" />
                                </div>
                                <div class="min-w-0 flex-1 leading-6">
                                    <label :for="header" class="select-none font-normal text-gray-900">{{ header }}</label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </template>
            </slide-over>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';

    import Icon from '@/Shared/Icon.vue';
    import SlideOver from "@/Shared/SlideOver.vue";
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

    const emit = defineEmits(['update:modelValue', 'opened', 'closed']);

    defineOptions({
        inheritAttrs: false,
    });

    const props = defineProps({
        headers: {
            type: Array,
            required: true,
        },

        excludedHeaders: {
            type: Array,
            required: true,
        },

        modelValue: {
            type: Array,
            required: true,
        },
    });

    const indeterminateColumns = computed(() => {
        return value.value.length > props.excludedHeaders.length && value.value.length < props.headers.length;
    });

    const allowedHeaders = computed(() => {
        return props.headers.filter(header => !props.excludedHeaders.includes(header));
    });

    const value = computed({
        get() {
            return props.modelValue;
        },

        set(value) {
            emit('update:modelValue', value);
        },
    });
</script>
