<template>
    <Head :title="`Edit Service Termination Change - ${serviceActivity.service_id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">
                {{ serviceActivity.service_id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Service Termination Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 mb-12 text-base lg:text-lg">
        <div class="md:px-12">
            <div class="flex justify-between items-center my-6">
                <h1 class="text-2xl font-bold">Edit Service Termination</h1>

                <div class="flex items-center gap-x-4">
                    <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="btn btn-gray gap-x-2">
                        Cancel Edit
                    </inertia-link>
                    <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
                </div>
            </div>

            <fieldset class="border-t border-gray-300">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Change Details
                </legend>

                <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4 px-4">
                    <revert-input
                        v-model="form.termination_date"
                        class="col-span-3 md:col-span-1"
                        label="Termination Date"
                        mark-as-required
                    >
                        <date-input :min="minimumActualDate" v-model="form.termination_date" help-text="When should this service be terminated?" :errors="errors['termination_date']" mark-as-required/>
                    </revert-input>

                    <revert-input v-model="form.change_requester" class="col-span-3 md:col-span-1" label="Requested By" mark-as-required>
                        <select-input v-model="form.change_requester" help-text="Who requested this change?" :errors="errors['change_requester']">
                            <option value="vendor">{{ serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                            <option value="client">{{ serviceActivity.serviceSnapshot.location.clientCompany.name }}</option>
                            <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                        </select-input>
                    </revert-input>

                    <revert-input v-model="form.change_reason" class="col-span-3 md:col-span-1" label="Reason for Change" mark-as-required>
                        <select-input v-model="form.change_reason">
                            <option>Other</option>
                        </select-input>
                    </revert-input>
                </div>
            </fieldset>

            <template v-if="showPriorPeriodAdjustment">
                <div class="mt-12 px-4">
                    <div class="rounded-md bg-yellow-50 p-4 mt-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <icon name="exclamation-triangle" class="size-5 text-yellow-400 fill-current" />
                            </div>
                            <div class="ml-3 flex-1 text-base">
                                <h3 class="font-medium text-yellow-800">Prior Period Adjustment</h3>
                                <div class="mt-2 text-yellow-700">
                                    <div class="pr-2 space-y-2">
                                        <p>
                                            You are changing the termination date on this service from
                                            <span class="font-medium">{{ $filters.format_date(serviceActivity.serviceSnapshot.termination_date) }}</span> to
                                            <span class="font-medium">{{ $filters.format_date(form.termination_date) }}</span>.
                                        </p>
                                        <p v-if="priorPeriodAdjustment.lastLineItemBilled">
                                            The service was already billed
                                            <span class="font-medium">{{ $filters.format_money(lastBilledClientLineItemDetails?.lastLineItemBilled.total_amount) }}</span>
                                            for fiscal period
                                            <span class="font-medium">{{ $filters.format_date(lastBilledClientLineItemDetails?.lastLineItemBilled.origin_date) }}</span>.
                                        </p>
                                        <p class="mt-1">If you wish to bill a prior period adjustment for this change, navigate to the prior period adjustments page after saving this change.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="relative mt-12">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Other</span>
                </div>
            </div>

            <div class="px-4 mt-4 mb-8">
                <revert-input v-model="form.internal_change_notes" label="Internal Change Notes">
                    <textarea-input v-model="form.internal_change_notes"/>
                </revert-input>
            </div>

            <loading-button
                class="btn btn-orange mt-3"
                :loading="saving"
                @click="submitChange"
                :disabled="!form.termination_date || !form.change_reason"
            >
                Submit Change
            </loading-button>
        </div>
    </div>
</template>

<script setup>
/**
 * This page is a Wizard to help user along with service changes
 **/
import DateInput from '@/Shared/DateInput.vue';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import RevertInput from '@/Shared/RevertInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import axios from "axios";
import {format, startOfToday} from "date-fns";
import {ref, reactive, inject, watch, computed, onMounted} from 'vue';
import {router, usePage} from '@inertiajs/vue3';

/**
 * Props
 */
const props = defineProps({
    errors: {
        type: Object,
        default: () => ({})
    },

    serviceActivity: {
        type: Object,
        required: true
    },

    serviceCreationActivity: {
        type: Object,
        required: true
    },
});

/**
 * Injected
 */
const route = inject('route');
const page = usePage();

onMounted(() => {
    if (suggestPriorPeriodAdjustment()) {
        updateLastBilledClientLineItemDetails();
    }
});

/**
 * Data
 */
const saving = ref(false);
const lastBilledClientLineItemDetails = ref(null);
const today = format(startOfToday(), 'yyyy-MM-dd');
const mounted = ref(false);
const form = reactive({
    change_reason: props.serviceActivity.reason,
    change_requester: props.serviceActivity.change_requester,
    internal_change_notes: props.serviceActivity.internal_change_notes,
    termination_date: props.serviceActivity.serviceSnapshot.termination_date,
});

/**
 * Methods
 */
function submitChange() {
    saving.value = true;

    router.put(route('services.service-termination-changes.update', [props.serviceActivity.service_id, props.serviceActivity.id]), {
        ...form,
    }, {
        onFinish: () => saving.value = false
    });
}

function deleteServiceChange() {
    if (window.confirm('Are you sure you want to delete this service termination change?')) {
        router.delete(route('services.service-termination-changes.destroy', {
            serviceId: props.serviceActivity.service_id,
            serviceActivityId: props.serviceActivity.id
        }));
    }
}

function suggestPriorPeriodAdjustment() {
    if (!page.props.permissions.accessRevenueManagement) {
        return false;
    }

    if (props.serviceActivity.serviceSnapshot.is_pass_through) {
        return false;
    }

    if (!['Monthly', 'Quarterly', 'Yearly'].includes(props.serviceActivity.serviceSnapshot.client_billing_frequency)) {
        return false;
    }

    if (form.service_schedule_type === 'One Time') {
        return false;
    }

    if (!props.serviceActivity.service.serviceType.enabled_type_fields.includes('base_charges')) {
        return false;
    }

    if (form.termination_date === props.serviceActivity.serviceSnapshot.termination_date) {
        return false;
    }

    return true;
}

function updateLastBilledClientLineItemDetails() {
    axios.get(`/json/services/${props.service.id}/last-billed-client-line-item-details?maxOriginDate=${form.termination_date}`).then(response => {
        props.lastBilledClientLineItemDetails = response.data
    });
}

/**
 * Computed
 */
const minimumActualDate = computed(() => {
    if (props.serviceActivity.serviceSnapshot.effective_date > props.serviceCreationActivity.actual_date) {
        return props.serviceActivity.serviceSnapshot.effective_date;
    }

    return props.serviceCreationActivity.actual_date;
});

const showPriorPeriodAdjustment = computed(() => {
    return suggestPriorPeriodAdjustment() && props.lastBilledClientLineItemDetails && props.lastBilledClientLineItemDetails?.lastBillingPeriodEnd > form.termination_date
});

/**
 * Watchers
 */
watch(() => form.termination_date, () => {
    if (suggestPriorPeriodAdjustment()) {
        updateLastBilledClientLineItemDetails();
    }
});
</script>
