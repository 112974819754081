<template>
    <div :class="$attrs.class">
        <slot name="label">
            <label v-if="label" class="form-label" :for="id" :aria-required="markAsRequired">
                <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
            </label>
        </slot>

        <div class="input-group flex">
            <input ref="input" v-bind="{...$attrs, class: null}" :disabled="disabled" class="block w-full rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6" :class="{ error: errors, disabled: disabled }" :type="type" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
            <div v-if="variables && variables.length > 0" class="absolute inset-y-0 right-0 flex items-center">
                <label :for="id + '-variables'" class="sr-only">Insert Variable</label>
                <select
                    name="variables"
                    v-model="selectedVariable"
                    class="text-base h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-9 text-right text-gray-600 focus:ring-2 focus:ring-inset focus:ring-orange-500"
                    :id="id + '-variables'"
                    @change="insertVariable"
                >
                    <option value="" disabled>Insert Variable</option>
                    <option v-for="variable in variables" :key="variable" :value="variable">{{ variable }}</option>
                </select>
            </div>
        </div>

        <p v-if="helpText" class="form-help-text">{{ helpText }}</p>

        <div v-if="errors" class="form-error-text">{{ errors }}</div>
    </div>
</template>


<script>
    import { v4 as uuid } from 'uuid';

    export default {
        inheritAttrs: false,

        props: {
            id: {
                type: String,
                default() {
                    return `text-input-${uuid()}`;
                },
            },

            type: {
                type: String,
                default: 'text',
            },

            modelValue: [String, Number],

            label: String,

            helpText: {
                type: String,
                default: ''
            },

            errors: {
                type: String,
                default: '',
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            },

            variables: {
                type: Array
            }
        },

        data() {
            return {
                selectedVariable: "",
            }
        },

        emits: ['update:modelValue'],

        methods: {
            focus() {
                this.$refs.input.focus()
            },

            select() {
                this.$refs.input.select()
            },

            setSelectionRange(start, end) {
                this.$refs.input.setSelectionRange(start, end)
            },

            insertVariable(event) {
                const variable = event.target.value;
                const input = this.$refs.input;
                const start = input.selectionStart;
                const end = input.selectionEnd;
                const textBefore = input.value.substring(0, start);
                const textAfter = input.value.substring(end, input.value.length);
                const newText = `${textBefore}${variable}${textAfter}`;
                this.$emit('update:modelValue', newText);
                this.$nextTick(() => {
                    this.setSelectionRange(start + variable.length, start + variable.length);
                    this.focus();
                });
            }
        },
    }
</script>
