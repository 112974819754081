<template>
    <Head title="Custom Reports" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

	        <span>Reports</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Custom Reports</span>
        </nav>
    </Teleport>

    <div>
	    <horizontal-sub-nav current-tab="custom_reports" />
    </div>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="rounded-md bg-blue-50 p-4 my-3">
        <div class="flex">
            <div class="flex-shrink-0">
                <icon name="info-circle" class="size-5 text-d-blue-500 fill-current" />
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-d-blue-500">Please note you may view your CSV data exports <a :href="$route('user-settings.data-exports.index')">here</a>.</p>
                <p class="mt-3 text-sm md:mt-0 md:ml-6">
                    <a :href="$route('user-settings.data-exports.index')" class="whitespace-nowrap font-medium text-d-blue-500 hover:text-d-blue-600">Details <span aria-hidden="true">&rarr;</span></a>
                </p>
            </div>
        </div>
    </div>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :search-id="'search-custom-reports'"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('custom-reports.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="customReports.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ customReports.from }} - {{ customReports.to }} out of {{ customReports.total }} Custom Reports</div>

        <div class="col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
            <inertia-link :href="$route('custom-reports.create')" class="btn btn-orange mr-2 my-1">
                New
            </inertia-link>
        </div>
    </div>

    <div class="w-full, my-4">
        <div>
            <div class="overflow-x-auto">
                <table class="table table-condensed" ref="table">
                    <thead>
                    <tr>
                        <sortable-table-header field="name" :filters="filters" route-name="custom-reports.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Name</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Main Entity')" field="main_entity" :filters="filters" route-name="custom-reports.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Main Entity</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Description')" field="description" :filters="filters" route-name="custom-reports.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Description</sortable-table-header>
                        <th v-show="form.selected_headers.includes('Modified By')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Modified By</th>
                        <sortable-table-header v-show="form.selected_headers.includes('Modified At')" field="modified_at" :filters="filters" route-name="custom-reports.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Modified At</sortable-table-header>
                        <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr v-for="customReport in customReports.data" :key="customReport.id">
                            <td>
                                <inertia-link :href="$route('custom-reports.show', [customReport.id])" class="link mr-3" title="View this Custom Report">
                                    {{ customReport.name }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Main Entity')">{{ customReport.main_entity }}</td>
                            <td v-show="form.selected_headers.includes('Description')">{{ getShortDescription(customReport.description) }}</td>
                            <td v-show="form.selected_headers.includes('Modified By')">{{ customReport.modifiedBy.first_name + ' ' + customReport.modifiedBy.last_name}}</td>
                            <td v-show="form.selected_headers.includes('Modified At')">{{ $filters.format_date_time(customReport.modified_at) }}</td>
                            <td class="text-right">
                                <span class="inline-block">
                                    <inertia-link :href="$route('custom-reports.edit', [customReport.id])" class="link mr-3" title="Edit this Custom Report">
                                        Edit
                                    </inertia-link>

                                    <a v-if="$page.props.permissions.deleteCustomReports" href="" class="link" title="Delete this Custom Report" @click.prevent="removeCustomReport(customReport)">
                                        Delete
                                    </a>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="customReports.links" />
        </div>

        <div v-if="!customReports.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="file-alt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Custom Reports Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="custom-reports-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.main_entity_ids" multiple>
                        <ComboboxLabel class="form-label" for="mainEntities">Main Entity</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="main_entity_ids" name="main_entity_ids" class="form-input" @change="mainEntityComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="mainEntities.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="mainEntity in filteredMainEntityOptions" :key="mainEntity" :value="mainEntity" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ mainEntity }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import { throttle } from "lodash-es";
    import { has_search_filters } from "@/Shared/Utils/Filters";

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from "@/Shared/SlideOver.vue";
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from "@/Shared/SelectInput.vue";
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import IndexSearchForm from "@/Shared/IndexSearchForm.vue";
    import HorizontalSubNav from "../Reports/HorizontalSubNav.vue";
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';


    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';


    export default {
        components: {
            Head,
            Icon,
            SlideOver,
            Pagination,
            SelectInput,
            ColumnSelector,
            IndexSearchForm,
            HorizontalSubNav,
            SortableTableHeader,

            // Tailwind UI combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            customReports: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },

            mainEntities: {
                type: Array,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    selected_headers: this.filters.selected_headers,
                    per_page: this.filters.per_page,
                    main_entity_ids: this.filters.main_entity_ids,
                },
                headers: [],
                excludedHeaders: ['Actions', 'Name'],
                state: 'passive',
                mounted: false,
                mainEntityComboBoxQuery: '',
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;


            this.applyFormWatcher();
        },

        methods: {
            removeCustomReport(customReport) {
                let confirmed = confirm(`Are you sure you wish to delete ${customReport.name}?`);

                if (confirmed) {
                    this.$inertia.delete(this.$route('custom-reports.destroy', customReport.id));
                }
            },

            getShortDescription(description) {
                if (!description || description.length <= 50) {
                    return description;
                }

                return description.substring(0, 50) + '...';
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
                this.headers = headers;
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('custom-reports.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredMainEntityOptions() {
                return this.mainEntityComboBoxQuery === ''
                    ? this.mainEntities
                    : this.mainEntities.filter((mainEntity) => {
                        return mainEntity.toLowerCase().includes(this.mainEntityComboBoxQuery.toLowerCase());
                    });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.main_entity_ids,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>
