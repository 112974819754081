<template>
    <Head title="Rover Invoices" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Rover Invoices</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :search-id="'search-vendor-portal-invoices'"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('vendor-portal-invoices.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="w-full my-4">
        <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="vendorPortalInvoices.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">
                Showing {{ vendorPortalInvoices.from }} - {{ vendorPortalInvoices.to }} out of {{ vendorPortalInvoices.total }} Rover Invoices
            </div>
            <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-2 xl:col-end-5">
                <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!vendorPortalInvoices.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

        <div>
            <div class="overflow-x-auto relative">
                <div v-if="selectedInvoices.length > 0" class="absolute bg-white top-1.5 left-12 flex h-8 items-center space-x-3 sm:left-16">
                    <button v-if="$can('bulkFetch')" type="button" @click="fetchInvoices" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-d-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Fetch</button>
                </div>

                <table class="table table-condensed" ref="table">
                    <thead>
                        <tr>
                            <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                                <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6"
                                       :checked="indeterminate || selectedInvoices.length === availableInvoicesForSelection.length"
                                       :indeterminate="indeterminate"
                                       @change="$event.target.checked ? selectAllUnfetchedInvoices() : this.selectedInvoices = []"
                                       v-if="availableInvoicesForSelection.length > 0" />
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 w-1">PDF</th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Invoice Number</th>
                            <th v-show="form.selected_headers.includes('Vendor')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Vendor</th>
                            <th v-show="form.selected_headers.includes('Rover Account')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Rover Account</th>
                            <sortable-table-header v-show="form.selected_headers.includes('Date')" field="invoice_date" :filters="filters" route-name="vendor-portal-invoices.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Date</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Amount')" scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Amount</th>
                            <th v-show="form.selected_headers.includes('Status')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Status</th>
                            <th scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="vendorPortalInvoice in vendorPortalInvoices.data" :key="vendorPortalInvoice.id" class="">
                            <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                <template v-if="vendorPortalInvoice.status === 'Found'">
                                    <div v-if="selectedInvoices.includes(vendorPortalInvoice.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                                    <input v-if="vendorPortalInvoice.vendorPortalAccount.vendorPortalCredential.vendorPortal.enabled && !vendorPortalInvoice.vendorPortalAccount.archived_at" type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="vendorPortalInvoice.id" v-model="selectedInvoices" />
                                </template>
                            </td>
                            <td>
                                <a v-if="vendorPortalInvoice.status === 'Fetched' && vendorPortalInvoice.gatheredVendorInvoiceFile" :href="$route('documents.preview', {path: vendorPortalInvoice.gatheredVendorInvoiceFile.document_path})" class="flex items-center text-2xl" target="_blank">
                                    <icon name="file-pdf" class="inline fill-current text-red-500 hover:text-red-500 w-5 h-5" />
                                </a>

                                <template v-else-if="vendorPortalInvoice.status === 'Fetched' && !vendorPortalInvoice.gatheredVendorInvoiceFile">
                                    <icon name="ban" class="fill-current h-5 w-5 mr-3" />
                                </template>

                                <template v-else>
                                    -
                                </template>
                            </td>
                            <td class="text-left">{{ vendorPortalInvoice.invoice_number || 'N/A' }}</td>
                            <td v-show="form.selected_headers.includes('Vendor')">
                                {{ vendorPortalInvoice.vendorPortalAccount.vendorPortalCredential.vendorPortal.vendor_name }}
                            </td>
                            <td v-show="form.selected_headers.includes('Rover Account')" class="text-left" :class="{'italic': vendorPortalInvoice.vendorPortalAccount.archived_at}">
                                <inertia-link :href="$route('vendor-portal-accounts.show', vendorPortalInvoice.vendorPortalAccount.id)" class="link" title="View Account">
                                    {{ vendorPortalInvoice.vendorPortalAccount.account_number }}
                                </inertia-link>
                                <span v-if="vendorPortalInvoice.vendorPortalAccount.archived_at" class="ml-1">(Archived)</span>
                            </td>
                            <td v-show="form.selected_headers.includes('Date')">{{ $filters.format_date(vendorPortalInvoice.invoice_date) }}</td>
                            <td v-show="form.selected_headers.includes('Amount')" class="text-right">{{ $filters.format_money(vendorPortalInvoice.amount) }}</td>
                            <td v-show="form.selected_headers.includes('Status')">
                                <div class="flex items-center gap-x-2" :title="vendorPortalInvoice.status">
                                    <div :class="[statusIndicators[vendorPortalInvoice.status], 'flex-none rounded-full p-1']">
                                        <div class="h-1.5 w-1.5 rounded-full bg-current" />
                                    </div>
                                    {{ vendorPortalInvoice.status }}
                                </div>
                            </td>
                            <td class="text-right">
                                <inertia-link :href="$route('vendor-portal-invoices.show', vendorPortalInvoice.id)" class="link" title="View This Rover Invoice">
                                    View
                                </inertia-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination :links="vendorPortalInvoices.links" />
        </div>

        <div v-if="!vendorPortalInvoices.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="file-alt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Rover Invoices Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="vendor-portal-invoices-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4" >
                <div class="col-span-6 md:col-span-2">
                    <label for="status" class="sr-only">Status</label>
                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel class="form-label" for="statuses">Statuses</ComboboxLabel>
                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="statuses" name="statuses" class="form-input" @change="statusComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="statuses.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="status in filteredStatusOptions" :key="status.id" :value="status.text" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                        {{ status.text }}
                                    </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <icon name="heroicon-check" class="size-5 fill-current" />
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    import { throttle } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from '@/Shared/SlideOver.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import IndexSearchForm from '@/Shared/IndexSearchForm.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Head,
            Icon,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            IndexSearchForm,
            SortableTableHeader,

            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            vendorPortalInvoices: {
                type: Object,
                required: true
            },

            statuses: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    per_page: this.filters.per_page,
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    statuses: this.filters.statuses,
                    selected_headers: this.filters.selected_headers,
                },
                statusComboBoxQuery: '',
                selectedInvoices: [],
                saving: false,
                headers: [],
                excludedHeaders: ['PDF', 'Actions','Invoice Number'],
                mounted: false,
                statusIndicators: {
                    'Fetched': 'text-green-400 bg-green-400/10',
                    'Found': 'text-blue-400 bg-blue-400/10',
                    'Pending': 'text-yellow-400 bg-yellow-400/10',
                    'Detected Duplicate': 'text-purple-400 bg-purple-400/10',
                    'Fetch Failed': 'text-rose-400 bg-rose-400/10',
                    'Fetching Attempts Exceeded - Contact Vendor': 'text-red-400 bg-red-400/10',
                },
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.applyFormWatcher();
            this.filtersInUse = this.getFiltersUsed;
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.vendor-portal-invoices.index'), this.queryFilters, {
                    onFinish: () => { this.saving = false; }
                });
            },
            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            fetchInvoices() {
                this.$inertia.post(this.$route('vendor-portal-invoices.fetch-multiple'), {
                    vendor_portal_invoice_ids: this.selectedInvoices,
                }, {
                    onSuccess: () => {
                        this.selectedInvoices = []
                    },
                    onError: () => {
                        if (props.errors.trial_limits) {
                            toast.error(props.errors.trial_limits);
                        }
                    }
                });
            },

            selectAllUnfetchedInvoices() {
                this.selectedInvoices = this.availableInvoicesForSelection.filter(vendorPortalInvoice => vendorPortalInvoice.status !== 'Fetched')
                    .map(vendorPortalInvoice => vendorPortalInvoice.id)
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('vendor-portal-invoices.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = {...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.statuses
                    : this.statuses.filter((status) => {
                        return status.text.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            availableInvoicesForSelection() {
                return this.vendorPortalInvoices.data.filter((vendorPortalInvoice) => {
                    return vendorPortalInvoice.vendorPortalAccount.vendorPortalCredential.vendorPortal.enabled
                        && vendorPortalInvoice.status !== 'Fetched'
                        && !vendorPortalInvoice.vendorPortalAccount.archived_at;
                });
            },

            indeterminate() {
                return this.selectedInvoices.length > 0 && this.selectedInvoices.length < this.availableInvoicesForSelection.length;
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.statuses,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>
