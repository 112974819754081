<template>
    <Head title="Receipt Locations" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Receipt Locations</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="receipt-locations" />

                <div class="lg:col-span-9 px-4 sm:px-6">
                    <div class="flex my-4 items-center">
                        <div class="w-1/2">
                            <div class="my-2 mb-4">
                                <div class="input-group mt-0">
                                    <span class="input-group-item-left">
                                        <icon name="search" class="size-4 text-gray-400 fill-current" />
                                    </span>

                                    <input type="text" v-model="form.search" placeholder="Search" class="input-group-field">
                                </div>
                            </div>
                        </div>
                        <div class="ml-auto my-2 mb-4">
                            <inertia-link :href="$route('tenant-settings.receipt-locations.create')" class="btn btn-orange">
                                <icon name="plus" class="inline text-white fill-current h-4 w-4 mr-2" /> New
                            </inertia-link>
                        </div>
                    </div>

                    <div v-if="receiptLocations.data.length">
                        <div class="my-6">
                            (Showing <span class="font-semibold text-gray-900">{{ receiptLocations.data.length }}</span> out of <span class="font-semibold text-gray-900">{{ receiptLocations.total }}</span>)
                        </div>

                        <div class="overflow-x-auto">
                            <table class="table table-condensed">
                                <thead>
                                    <tr>
                                        <sortable-table-header field="id" class="font-semibold" :filters="filters" route-name="tenant-settings.receipt-locations.index">ID</sortable-table-header>
                                        <sortable-table-header field="name" class="font-semibold" :filters="filters" route-name="tenant-settings.receipt-locations.index">Name</sortable-table-header>
                                        <sortable-table-header field="gl_code" class="font-semibold" :filters="filters" route-name="tenant-settings.receipt-locations.index">GL Account</sortable-table-header>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="receiptLocation in receiptLocations.data" :key="receiptLocation.id">
                                        <td>{{ receiptLocation.id }}</td>
                                        <td>{{ receiptLocation.name }}</td>
                                        <td>{{ receiptLocation.gl_code }}</td>
                                        <td>
                                            <span class="inline-block">
                                                <inertia-link :href="$route('tenant-settings.receipt-locations.edit', [receiptLocation.id])" class="link" title="Edit Receipt Location">
                                                    Edit
                                                </inertia-link>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <pagination :links="receiptLocations.links" />
                    </div>

                    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                        <icon name="receipt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Receipt Location Found</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SubNav from '../SubNav.vue';
    import { throttle, pickBy } from 'lodash-es';

    export default {
        components: {
            Head,
            Icon,
            Pagination,
            SortableTableHeader,
            SubNav
        },

        props: {
            receiptLocations: {
                type: Object,
                required: true
            },
            filters: Object
        },

        data() {
            return {
                form: {
                    search: this.filters.search
                },
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        watch: {
            form: {
                handler: throttle(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route('tenant-settings.receipt-locations.index'), query, {preserveState: true});
                }, 150),

                deep: true,
            },
        }
    }
</script>
