<template>
    <Head :title="`Edit Service Type - ${props.serviceType.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.service-types.index')" class="breadcrumb-link">Service Types</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{props.serviceType.name}} ({{props.serviceType.id}})</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div v-if="props.serviceType.is_archived" class="col-span-6 flex">
        <div class="flex-shrink-0">
            <icon name="info-circle" class="h-6 w-6 text-blue-400 fill-current" />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-blue-700">This service type has been archived. No new services can be created from it.</p>
        </div>
    </div>

    <form @submit.prevent="submitForm" id="edit-service-type-form" class="grid grid-cols-6 gap-4">
        <div class="col-span-6 grid grid-cols-2 gap-4">
            <text-input v-if="props.serviceCount === 0" v-model="form.name" class="col-span-2 md:col-span-1" id="name" name="name" label="Name" :errors="props.errors.name" />
            <text-input v-model="form.type_description" class="col-span-2 md:col-span-1" id="type_description" name="type_description" label="Type Description" :errors="props.errors.type_description" />

            <text-input v-if="props.serviceCount === 0" title="This will be the prefix of your service to help people identify it better." v-model="form.service_id_prefix" class="col-span-2 md:col-span-1" id="service_id_prefix" name="service_id_prefix" label="Service ID Prefix" :errors="props.errors.service_id_prefix" mark-as-required />

            <select-input v-model="form.line_item_category_id" class="col-span-2 md:col-span-1" id="line_item_category_id" name="line_item_category_id" label="Line Item Category" :errors="props.errors.line_item_category_id" mark-as-required >
                <option v-for="category in props.lineItemCategories" :key="category.id" :value="category.id">{{ category.display_name }}</option>
            </select-input>
            <select-input v-if="props.serviceCount === 0" v-model="form.waste_generation_type" class="col-span-2 md:col-span-1" id="waste_generation_type" name="waste_generation_type" label="Waste Generation Type" :errors="props.errors.waste_generation_type" mark-as-required >
                <option v-for="(decorated, type) in props.wasteGenerationTypes" :key="type" :value="type">{{ decorated }}</option>
            </select-input>

            <text-input-with-variables v-model="form.service_description" :variables="descriptionVariables" class="col-span-2" label="Service Description" :errors="errors.service_description" mark-as-required/>
            <div class="col-span-2">ex: {{ preview.service_description }}</div>

            <text-input-with-variables label="Client Line Item Header Label" class="col-span-2" v-model="form.client_line_item_header_label" :variables="descriptionVariables" :errors="props.errors.client_line_item_header_label" />
            <div class="col-span-2">ex: {{ preview.client_line_item_header_label }}</div>
        </div>

        <hr class="col-span-6 border-1 my-8"/>

        <div class="col-span-6 grid grid-cols-6 gap-4">
            <toggle-switch-input v-if="props.serviceCount === 0" class="md:col-span-3 col-span-6" v-model="form['base_charges']" label="This service has a base/flat charge" :disabled="props.serviceCount > 0"/>
            <div class="md:col-span-3 col-span-6">
                <text-input-with-variables label="Client Line Item Description" v-model="form.client_line_item_description" :variables="descriptionVariables" :errors="props.errors.client_line_item_description" :disabled="props.serviceCount > 0" />
                <div>ex: {{ preview.client_line_item_description }}</div>
            </div>
        </div>

        <div class="col-span-6 grid grid-cols-6 gap-4">
            <toggle-switch-input v-if="props.serviceCount === 0" class="col-span-3" v-model="form['per_unit_charges']" label="This service has a per unit charge" />
        </div>

        <div class="col-span-6 grid grid-cols-6 gap-4">
            <toggle-switch-input v-if="props.serviceCount === 0" class="col-span-3" v-model="form['per_occurrence_charges']" label="This service has a per occurrence charge" />
        </div>

        <div class="col-span-6 flex flex-row justify-end gap-2">
            <button v-if="props.serviceType.is_archived" @click.prevent="unarchive" type="button" class="btn btn-red col-span-2">Unarchive</button>
            <button v-else-if="props.serviceCount > 0" @click.prevent="archive" type="button" class="btn btn-red col-span-2">Archive</button>
            <button v-else @click.prevent="remove" type="button" class="btn btn-red col-span-2">Remove</button>
            <loading-button :loading="state.value === 'saving'" class="btn btn-orange">
                Save Changes
            </loading-button>
        </div>
    </form>
</template>

<script setup>
    import { router, Head } from '@inertiajs/vue3';
    import {reactive, ref, inject, onMounted, watch} from 'vue';

    // Components
    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ToggleSwitchInput from "@/Shared/ToggleSwitchInput.vue";
    import QuillEditor from "@/Shared/QuillEditor.vue";
    import TextInputWithVariables from "../../../Shared/TextInputWithVariables.vue";
    import {debounce} from "lodash-es";
    import axios from "axios";

    // Inject
    const route = inject('route');

    // Props
    const props=defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        serviceType: {
            type: Object,
            required: true
        },

        serviceCount: {
            type: Number,
            required: true
        },

        fields: {
            type: Object,
            required: true
        },

        lineItemCategories: {
            type: Array,
            required: true
        },

        wasteGenerationTypes: {
            type: Object,
            required: true
        },

        descriptionVariables: {
            type: Array,
            required: true
        },

        preview: {
            type: Object,
            required: true
        }
    });

    // State
    var fieldValues=Object.keys(props.fields).map((field) => {
        return {
            [field]: props.serviceType.enabled_type_fields.includes(field)
        }
    }).reduce(function(result, current) {
        return Object.assign(result, current);
    }, {});

    const form = reactive({
        "name": props.serviceType.name,
        "type_description": props.serviceType.type_description,
        "service_description": props.serviceType.service_description,
        "client_line_item_header_label": props.serviceType.client_line_item_header_label,
        "client_line_item_description": props.serviceType.client_line_item_description,
        "service_id_prefix": props.serviceType.service_id_prefix,
        "line_item_category_id": props.serviceType.line_item_category_id,
        'waste_generation_type': props.serviceType.waste_generation_type,
        ...fieldValues,
    });
    const state = ref('passive');
    const mounted = ref(false);
    const toggleFields = Object.keys(props.fields)
        .filter((field) => {
            const ignore = ['base_charges', 'per_occurrence_charges', 'per_unit_charges'];
            return ignore.includes(field) === false;
        });
    const preview=reactive({
        service_description: props.preview.service_description,
        client_line_item_header_label: props.preview.client_line_item_header_label,
        client_line_item_description: props.preview.client_line_item_description,
    });

    // Mount
    onMounted(() => {
        mounted.value = true;
    });

    // Methods
    function remove() {
        let confirmed = confirm('Are you sure you want to remove this service type?');

        if (confirmed) {
            router.delete(route('tenant-settings.service-types.destroy', [props.serviceType.id]));
        }
    }
    function archive() {
        router.patch(route('tenant-settings.service-types.archive', [props.serviceType.id]));
    }
    function unarchive() {
        router.patch(route('tenant-settings.service-types.unarchive', [props.serviceType.id]));
    }

    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';
            router.put(route('tenant-settings.service-types.update', [props.serviceType.id]), form, {
                preserveScroll: true,
                onFinish: () => {
                    state.value = 'passive'
                },
            });
        }
    }

    watch(() => form.service_description, debounce((newValue) => {
        axios.get(route('tenant-settings.json.service-types.preview', {'template': newValue})).then((response) => {
            preview.service_description = response.data.value;
        });
    }, 1000))

    watch(() => form.client_line_item_header_label, debounce((newValue) => {
        axios.get(route('tenant-settings.json.service-types.preview', {'template': newValue})).then((response) => {
            preview.client_line_item_header_label = response.data.value;
        });
    }, 1000))

    watch(() => form.client_line_item_description, debounce((newValue) => {
        axios.get(route('tenant-settings.json.service-types.preview', {'template': newValue})).then((response) => {
            preview.client_line_item_description = response.data.value;
        });
    }, 1000))
</script>
