<template>
    <fieldset class="border-t border-gray-300" onkeypress="return event.keyCode !== 13;">
        <legend class="font-semibold text-xl text-d-blue-400 pr-4">
            Physical Address
        </legend>
        <p class="mt-1 text-gray-600">{{ description }}</p>

        <div class="grid grid-cols-2 gap-6 mt-6">
            <div class="col-span-2">
                <label class="form-label" :for="id">Search for Address</label>
                <div class="form-input-wrapper">
                    <g-map-autocomplete ref="autocomplete" :disabled="disabled === true" @place_changed="onPlaceChanged" placeholder="Search for Address" class="form-input" />
                </div>
                <p class="form-help-text">You may manually override sections of the address if necessary.</p>
            </div>

            <div class="col-span-2">
            </div>

            <text-input v-model="address.street_address" label="Street Address" :disabled="disabled === true"  class="col-span-2 sm:col-span-1 sm:col-start-1" mark-as-required @change="manualOverride" :errors="errors.street_address" />

            <select-input v-model="address.state_code" label="State/Province" :disabled="disabled"  class="col-span-2 sm:col-span-1" :errors="errors.state_code" @change="manualOverride" mark-as-required>
                <option v-for="(name, abbreviation) in statesAndProvinces" :key="abbreviation" :value="abbreviation">{{ name }}</option>
            </select-input>

            <text-input v-model="address.city" label="City" placeholder="City" :disabled="disabled" class="col-span-2 sm:col-span-1" :errors="errors.city" @change="manualOverride" mark-as-required />

            <text-input v-model="address.postal_code" label="Postal code" :disabled="disabled" class="col-span-2 sm:col-span-1" placeholder="Postal Code" :errors="errors.postal_code" @change="manualOverride" mark-as-required />

            <select-input v-model="address.country_code" label="Country" :disabled="disabled" class="col-span-2" :errors="errors.country_code" @change="manualOverride" mark-as-required>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
            </select-input>

            <div class="col-span-2">
                <label class="form-label">Latitude/Longitude</label>
                <g-map-map :options="mapOptions" :center="mapCenter" class="mt-2" style="width: 100%; height: 300px">
                    <g-map-marker
                    :position="{ lat: parseFloat(this.address.latitude), lng: parseFloat(this.address.longitude) }"
                    :draggable="true"
                    @dragend="onMarkerPositionChanged"
                    :disabled="disabled"
                    />
                </g-map-map>
            </div>
        </div>
    </fieldset>
</template>

<script>
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import statesAndProvinces from '@/Shared/StatesAndProvinces.js';
    import { v4 as uuid } from 'uuid';

    export default {
        components: {
            SelectInput,
            TextInput,
            ToggleSwitchInput
        },

        props: {
            id: {
                type: String,
                default() {
                    return `address-picker-${uuid()}`;
                },
            },

            modelValue: {
                type: Object,
            },

            legend: String,

            description: String,

            errors: {
                type: Object,

                default: function() {
                    return {
                        city: '',
                        country: '',
                        country_code: '',
                        postal_code: '',
                        state: '',
                        state_code: '',
                        street_address: ''
                    };
                }
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            let address = this.emptyAddress();

            if (this.modelValue && this.modelValue.full_address) {
                address.city = this.modelValue.city;
                address.country = this.modelValue.country;
                address.country_code = this.modelValue.country_code;
                address.postal_code = this.modelValue.postal_code;
                address.state = this.modelValue.state;
                address.state_code = this.modelValue.state_code;
                address.street_address = this.modelValue.street_address;
            }

            if (this.modelValue && this.modelValue.latitude && this.modelValue.longitude) {
                address.latitude = this.modelValue.latitude;
                address.longitude = this.modelValue.longitude;
            }

            return {
                autocomplete: {},
                mapCenter: {
					lat: parseFloat(address.latitude),
					lng: parseFloat(address.longitude)
				},
                address: address,
                zoom: this.modelValue && this.modelValue.latitude && this.modelValue.longitude ? 10 : 5,
                statesAndProvinces: statesAndProvinces,
                countries: {
                    US: 'United States',
                    CA: 'Canada'
                }
            };
        },

        methods: {
            /** @note ANY CHANGES to this, should be mirrored in the App\Functions\GeoCoding.php::parse as well
              * so that they work the same to produce consistent address objects.
             */
            formattedAddress(place) {
                let simple = {
                    administrative_area_level_1: {},
                    country: {},
                    locality: {},
                    sublocality: {},
                    subpremise: {},
                    neighborhood: {},
                    postal_code: {},
                    route: {},
                    street_number: {},
                };

                place.address_components.forEach(component => {
                    simple[component.types[0]] = {'long_name': component.long_name, 'short_name': component.short_name};
                });

                let streetNumber = simple.street_number.short_name;
                let streetRoute = simple.route.short_name;
                let streetAddress = streetNumber && streetRoute ? streetNumber + ' ' + streetRoute : undefined;

                if (simple.subpremise.short_name) {
                    streetAddress = streetAddress + ' ' + simple.subpremise.short_name;
                }

                let address = {
                    place_id: place.place_id,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                    street_number: streetNumber,
                    street_address: streetAddress,
                    city: simple.locality.long_name ?? simple.sublocality.long_name ?? simple.neighborhood.long_name,
                    state: simple.administrative_area_level_1.long_name,
                    state_code: simple.administrative_area_level_1.short_name,
                    postal_code: simple.postal_code.short_name,
                    country: simple.country.long_name,
                    country_code: simple.country.short_name,
                };

                return address;
            },

            onPlaceChanged(place) {
                this.address = this.formattedAddress(place);
                this.address.full_address = this.fullAddress;

                this.mapCenter = {
					lat: parseFloat(this.address.latitude),
					lng: parseFloat(this.address.longitude)
                };

                this.$emit('update:modelValue', this.address);
            },

            onMarkerPositionChanged(event) {
				this.address.latitude = event.latLng.lat();
				this.address.longitude = event.latLng.lng();
            },

            manualOverride() {
                this.$emit('update:modelValue', {
                    street_address: this.address.street_address,
                    city: this.address.city,
                    state: this.statesAndProvinces[this.address.state_code],
                    state_code: this.address.state_code,
                    postal_code: this.address.postal_code,
                    country: this.countries[this.address.country_code],
                    country_code: this.address.country_code,
                    latitude: this.address.latitude,
                    longitude: this.address.longitude,
                    place_id: null,
                    full_address: this.fullAddress
                });
            },

            emptyAddress() {
                return {
                    place_id: null,
                    street_number: '',
                    street_address: '',
                    city: '',
                    state: '',
                    state_code: '',
                    postal_code: '',
                    latitude: 39.8097343,
                    longitude: -98.555620,
                    country: '',
                    country_code: 'US',
                    full_address: ''
                }
            },
        },

        computed: {
            mapOptions: function () {
                return {
                    mapTypeId: 'roadmap',
                    zoom: this.zoom,
                }
            },

            markerOptions: function () {
                return {
                    draggable: true
                }
            },

            fullAddress: function() {
                return `${this.address.street_address}, ${this.address.city}, ${this.address.state_code}, ${this.address.postal_code}, ${this.address.country_code}`;
            }
        },
        watch: {
            modelValue: function (value) {
                if (value && value.full_address) {
                    this.address.city = value.city;
                    this.address.country = value.country;
                    this.address.country_code = value.country_code;
                    this.address.postal_code = value.postal_code;
                    this.address.state = value.state;
                    this.address.state_code = value.state_code;
                    this.address.street_address = value.street_address;
                }
                if (this.modelValue && this.modelValue.latitude && this.modelValue.longitude) {
                    this.address.latitude = this.modelValue.latitude;
                    this.address.longitude = this.modelValue.longitude;
                }
            }
        }
    }
</script>
