<template>
	<div v-bind="$attrs" class="overflow-hidden bg-gray-50 sm:rounded-lg shadow my-4 p-2">
		<div class="px-4 py-5 sm:p-8">
			<inertia-link :href="href">
				<div>
					<slot name="header">

					</slot>
				</div>
				<div>
					<slot name="body" >

					</slot>
				</div>

				<div>
					<slot name="footer">

					</slot>
				</div>
			</inertia-link>
		</div>
	</div>
</template>


<script>
	export default {
		props: {
			href: {
				type: String,
				required: true,
			}
		}
	}
</script>
