<template>
    <Head :title="`Edit Service Activation Change - ${serviceActivity.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">{{ serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Service Activation Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 mb-12 text-base lg:text-lg">
        <div class="md:px-12">
            <div class="flex justify-between items-center my-6">
                <h1 class="text-2xl font-bold">Edit Service Activation</h1>

                <div class="flex items-center gap-x-4">
                    <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="btn btn-gray gap-x-2">
                        Cancel Edit
                    </inertia-link>
                    <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
                </div>
            </div>

            <fieldset class="border-t border-gray-300">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Change Details
                </legend>

                <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
                    <revert-input v-model="form.actual_date" class="col-span-1 md:col-span-2" label="Effective Date" mark-as-required>
                        <date-input :min="serviceCreationActivity.actual_date" :max="today" v-model="form.actual_date" help-text="Service activation date" :errors="errors['actual_date']"/>
                    </revert-input>

                    <select-input v-model="form.change_requester" :errors="errors['change_requester']" class="col-span-1 md:col-span-2" label="Requested By" mark-as-required>
                        <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                    </select-input>

                    <select-input v-model="form.change_reason" class="col-span-1 md:col-span-2" label="Reason for Change" mark-as-required>
                        <option>Other</option>
                    </select-input>
                </div>
            </fieldset>

            <div class="relative mt-12">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Other</span>
                </div>
            </div>

            <div class="px-4 mt-4 mb-8">
                <revert-input v-model="form.internal_change_notes" label="Internal Change Notes">
                    <textarea-input rows="4" v-model="form.internal_change_notes" />
                </revert-input>
            </div>

            <loading-button class="btn btn-orange" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason">
                Submit Change
            </loading-button>
        </div>
    </div>
</template>

<script>
    /**
     * This page is a Wizard to help user along with service changes
     */
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import RevertInput from '@/Shared/RevertInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import { format, startOfToday } from "date-fns";
    import RevertibleBin from "@/Shared/Services/RevertibleBin.vue";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            DateInput,
            Icon,
            LoadingButton,
            RevertInput,
            RevertibleBin,
            SelectInput,
            TextareaInput,
            Head,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            serviceActivity: {
                type: Object,
                required: true
            },

            serviceCreationActivity: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    actual_date:            this.serviceActivity.actual_date,
                    change_reason:          this.serviceActivity.reason,
                    change_requester:       this.serviceActivity.change_requester,
                    internal_change_notes:  this.serviceActivity.internal_change_notes,
                },
                saving: false,
                today: format(startOfToday(), 'yyyy-MM-dd'),
                mounted: false,
            }
        },

        mounted() {
            if(!this.serviceActivity.reason || this.serviceActivity.reason === 'unknown') {
                this.form.change_reason = 'Other';
            }

            if(!this.serviceActivity.change_requester || this.serviceActivity.change_requester === 'unknown') {
                this.form.change_requester = 'broker';
            }

            this.mounted = true;
        },

        methods: {
            submitChange() {
                this.saving = true;

                // should we schedule this or fire it off immediately?
                // we can call different routes or we could just handle in the controller... not sure which is easiest
                this.$inertia.put(this.$route('services.service-activation-changes.update', [this.serviceActivity.service_id, this.serviceActivity.id]), {
                    ...this.form,
                }, {
                    onFinish: () => this.saving = false
                });
            },

            deleteServiceChange() {
                if (window.confirm('Are you sure you want to delete this service activation?')) {
                    this.$inertia.delete(this.$route('services.service-activation-changes.destroy', {serviceId: this.serviceActivity.service_id, serviceActivityId: this.serviceActivity.id}));
                }
            },
        },

        computed: {
            minimumActualDate() {
                if (this.serviceActivity.serviceSnapshot.effective_date > this.serviceCreationActivity.actual_date) {
                    return this.serviceActivity.serviceSnapshot.effective_date;
                }

                return this.serviceCreationActivity.actual_date;
            },
        },
    }
</script>
