<template>
    <div>
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select @change="goToPage" id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm">
                <option :value="$route('revenue.index')" :selected="'dashboard' === currentTab">Billable Revenue</option>
                <option :value="$route('client-invoices.index')" :selected="'invoices' === currentTab">Invoices</option>
                <option :value="$route('client-payments.index')" :selected="'payments' === currentTab">Payments</option>
                <option :value="$route('client-credit-memos.index')" :selected="'credits' === currentTab">Credits</option>
            </select>
        </div>

        <div class="hidden sm:block">
            <div class="border-b border-gray-200">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <inertia-link :href="$route('revenue.index')" :class="['dashboard' === currentTab ? 'border-d-orange-500 text-d-orange-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium']" :aria-current="'dashboard' === currentTab ? 'page' : undefined">Billable Revenue</inertia-link>
                    <inertia-link :href="$route('client-invoices.index')" :class="['invoices' === currentTab ? 'border-d-orange-500 text-d-orange-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium']" :aria-current="'all' === currentTab ? 'page' : undefined">Invoices</inertia-link>
                    <inertia-link :href="$route('client-payments.index')" :class="['payments' === currentTab ? 'border-d-orange-500 text-d-orange-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium']" :aria-current="'payments' === currentTab ? 'payments' : undefined">Payments</inertia-link>
                    <inertia-link :href="$route('client-credit-memos.index')" :class="['credits' === currentTab ? 'border-d-orange-500 text-d-orange-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium']" :aria-current="'credits' === currentTab ? 'credits' : undefined">Credits</inertia-link>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { router } from '@inertiajs/vue3';

    const props = defineProps({
        currentTab: {
            type: String,
            required: true
        }
    });

    function goToPage(e) {
        router.visit(e.target.value);
    }
</script>
