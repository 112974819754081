<template>
    <form @submit.prevent id="search-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4 items-end">
        <div class="col-span-9 md:col-span-7 lg:col-span-3">
            <label :for="searchId" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item-left">
                    <icon name="search" class="fill-current text-gray-400 size-4" />
                </span>
                <input
                    type="search"
                    :id="searchId"
                    :name="searchId"
                    v-model="searchModel"
                    placeholder="Search"
                    class="input-group-field"
                />
            </div>
        </div>

        <select-input
            class="col-span-9 md:col-span-2 lg:col-span-1"
            name="per_page"
            id="per-page"
            v-model="perPageModel"
            placeholder="15"
            label="Results / Page"
        >
            <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
        </select-input>

        <div class="col-span-9 md:col-span-5">
            <div class="flex gap-x-4">
                <button
                    type="button"
                    class="inline-flex divide-x divide-gray-300 text-gray-800 bg-white font-semibold items-center border border-gray-300 text-base rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray"
                    @click="$emit('show-filters')"
                >
                    <span class="inline-flex items-center px-3 py-2">
                        <icon name="filter" class="size-4 text-gray-400 fill-current" />
                        <span class="ml-2">Filters</span>
                    </span>
                    <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-base font-semibold text-d-accent-900 rounded-r-md">
                        {{ filtersInUse }}
                    </span>
                </button>

                <column-selector :headers="headers" :excludedHeaders="excludedHeaders" v-model="selectedHeadersModel" />

                <inertia-link :href="clearFiltersRoute" class="btn btn-gray">
                    Clear
                </inertia-link>
            </div>
        </div>
    </form>
</template>

<script setup>
    import { computed, ref } from 'vue';

    import Icon from '@/Shared/Icon.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";

    const props = defineProps({
        searchId: {
            type: String,
            default: 'search-input'
        },
        search: {
            type: String,
            default: ''
        },
        perPage: {
            type: [Number, String],
            default: 15
        },
        perPageOptions: {
            type: Array,
            default: () => [15, 25, 50, 100, 200]
        },
        headers: {
            type: Array,
            required: true
        },
        excludedHeaders: {
            type: Array,
            default: () => ['ID', 'Actions']
        },
        selectedHeaders: {
            type: Array,
            required: true
        },
        filtersInUse: {
            type: Number,
            required: true
        },
        clearFiltersRoute: {
            type: String,
            required: true
        }
    });

    const emit = defineEmits(['update:search', 'update:perPage', 'update:selectedHeaders', 'show-filters']);

    const searchModel = computed({
        get: () => props.search,
        set: (value) => emit('update:search', value)
    });

    const perPageModel = computed({
        get: () => props.perPage,
        set: (value) => emit('update:perPage', value)
    });

    const selectedHeadersModel = computed({
        get: () => props.selectedHeaders,
        set: (value) => emit('update:selectedHeaders', value)
    });
</script>
