<template>
    <fieldset>
        <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 my-4">
            <text-input class="col-span-2 md:col-span-1" v-model="form.quantity" min="0" type="number" label="Quantity" :errors="errors['bin.quantity']" :markAsRequired="fieldsAreRequired" />

            <text-input class="col-span-2 md:col-span-1" v-model="form.size" min="0" type="number" label="Size" :errors="errors['bin.size']" :markAsRequired="fieldsAreRequired" />

            <select-input class="col-span-2 md:col-span-1" v-model="form.size_metric" label="Size Metric" :errors="errors['bin.size_metric']" :markAsRequired="fieldsAreRequired">
                <option value="yards">Yards</option>
                <option value="gallons">Gallons</option>
                <option value="foot">Feet</option>
            </select-input>

            <select-input class="col-span-2 md:col-span-1" v-model="form.material_type" label="Material Type" :errors="errors['bin.material_type']" :markAsRequired="fieldsAreRequired">
                <option v-for="material_type in materialTypes" :value="material_type.name" :key="material_type.name">{{ material_type.name }}</option>
            </select-input>

            <select-input class="col-span-2 md:col-span-1" v-model="form.disposal_method" label="Disposal Method" :errors="errors['bin.disposal_method']" :markAsRequired="fieldsAreRequired">
                <option value="Composted">Composted</option>
                <option value="Incineration">Incineration</option>
                <option value="Landfill">Landfill</option>
                <option value="Recycling">Recycling</option>
                <option value="Reuse">Reuse</option>
            </select-input>

            <select-input class="col-span-2 md:col-span-1" v-model="form.type" label="Bin Type" :errors="errors['bin.type']" :markAsRequired="fieldsAreRequired">
                <option v-for="bin_type in binTypes" :value="bin_type.name" :key="bin_type.name">{{ bin_type.name }}</option>
            </select-input>
        </div>
    </fieldSet>

    <fieldset class="mt-4">
        <legend class="block w-full">
            <button class="w-full bg-gray-50 font-semibold text-d-blue-400 py-3 flex items-center justify-center gap-x-2" :class="collapsed ? 'rounded-lg' : 'rounded-t-lg'" @click="toggleAdvancedOptions">
                {{ collapsed ? 'Show' : 'Hide' }} Advanced Bin Options
                <icon class="inline h-4 w-4 fill-current cursor-pointer" :name="collapsed ? 'chevron-right' : 'chevron-down'" />
            </button>
        </legend>

        <div class="overflow-hidden rounded-b-lg bg-gray-50 border-t" v-if="!collapsed">
            <div class="px-4 py-5 sm:p-6">
                <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3">
                    <select-input v-model="form.is_compacted_or_baled" class="col-span-2 md:col-span-1" label="Is Compacted or Baled" :errors="errors['bin.is_compacted_or_baled']">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                        <option :value="null">Unsure</option>
                    </select-input>

                    <text-input v-model="form.compaction_ratio" class="col-span-2 md:col-span-1" :disabled="form.is_compacted_or_baled !== true" min="0" type="number" label="Compaction Ratio" :errors="errors['bin.compaction_ratio']" @change="recalculateWeightUnitsPerWeight" />

                    <div class="col-span-2 md:col-span-1">
                        <text-input v-if="$page.props.small_units === 'kilograms'" :disabled="!form.material_type" v-model="form.kilograms_per_yard" min="0" type="number" label="Kilograms Per yard" :errors="errors['bin.kilograms_per_yard']" />
                        <text-input v-else :disabled="!form.material_type" v-model="form.pounds_per_yard" min="0" type="number" label="Pounds Per Yard" :errors="errors['bin.pounds_per_yard']" />
                        <p class="text-xs pt-2 text-green-600" v-if="form.compaction_ratio > 1">A {{ form.compaction_ratio }}-to-1 compaction ratio has automatically been applied to the
                            {{ $page.props.small_units }} per yard.</p>
                        <p class="text-xs pt-2 text-green-600" v-if="form.size_metric !== 'yards'">Please note our system will handle unit
                            conversions from <span class="font-bold">{{ form.size_metric }} to cubic yards.</span></p>
                    </div>

                    <select-input v-model="form.has_casters" class="col-span-2 md:col-span-1" label="Has Casters" :errors="errors['bin.has_casters']">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                        <option :value="null">Unsure</option>
                    </select-input>

                    <select-input v-model="form.enclosure" class="col-span-2 md:col-span-1" label="Enclosure" :errors="errors['bin.enclosure']">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                        <option :value="null">Unsure</option>
                    </select-input>

                    <select-input v-model="form.requires_overhead_instructions" class="col-span-2 md:col-span-1" label="Overhead Instructions" :errors="errors['bin.requires_overhead_instructions']">
                        <option :value="true">Required</option>
                        <option :value="false">Not Required</option>
                        <option :value="null">Unsure</option>
                    </select-input>

                    <text-input v-model="form.container_access_times" class="col-span-2 md:col-span-1" label="Container Access Times" :errors="errors['bin.container_access_rimes']" />

                    <select-input v-model="form.configuration" class="col-span-2 md:col-span-1" label="Configuration" :errors="errors['bin.configuration']">
                        <option>Flat top</option>
                        <option>Slant top</option>
                        <option>Slotted</option>
                        <option>Other</option>
                        <option>Unsure</option>
                    </select-input>

                    <select-input v-model="form.owner" class="col-span-2 md:col-span-1" label="Asset Owner" :errors="errors['bin.owner']">
                        <option v-for="asset_owner in assetOwners" :value="asset_owner.id" :key="asset_owner.id">{{ asset_owner.name }}</option>
                    </select-input>

                    <select-input v-model="form.keys" class="col-span-2 md:col-span-1" label="Requires Keys" :errors="errors['bin.keys']">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                        <option :value="null">Unsure</option>
                    </select-input>

                    <select-input v-model="form.has_lids" class="col-span-2 md:col-span-1" label="Lids" :errors="errors['has_lids']">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                        <option :value="null">Unsure</option>
                    </select-input>

                    <select-input v-model="form.has_lock" class="col-span-2 md:col-span-1" label="Has Lock" :errors="errors['bin.has_lock']">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                        <option :value="null">Unsure</option>
                    </select-input>

                    <select-input v-model="form.secured_access" class="col-span-2 md:col-span-1" label="Secured Access" :errors="errors['bin.secured_access']">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                        <option :value="null">Unsure</option>
                    </select-input>

                    <select-input v-model="form.push_out_service" class="col-span-2 md:col-span-1" label="Push out Service" :errors="errors['bin.push_out_service']">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                        <option :value="null">Unsure</option>
                    </select-input>

                    <select-input v-model="form.side_door_to_bin" class="col-span-2 md:col-span-1" label="Side Door to Bin" :errors="errors['bin.side_door_to_bin']">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                        <option :value="null">Unsure</option>
                    </select-input>

                    <select-input v-model="form.compaction_method" class="col-span-2 md:col-span-1" label="Compaction Method" :errors="errors['bin.compaction_method']">
                        <option value="na">N/A</option>
                        <option value="auger">Auger</option>
                        <option value="hydraulic">Hydraulic</option>
                    </select-input>

                    <textarea-input rows="4" v-model="form.area_description" class="col-span-2 md:col-span-3" label="Area Description" :errors="errors['bin.area_description']" />
                </div>
            </div>
        </div>
    </fieldset>
</template>

<script>
    import TextInput from '@/Shared/TextInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import Icon from '@/Shared/Icon.vue';


	export default {
        components: {
            TextInput,
            DateInput,
            SelectInput,
            TextareaInput,
            Icon,
        },

		props: {
            propertyType: {
                required: false,
                default() {
                    return 'Commercial'
                }
            },

			modelValue: {
				required: true
            },

            materialTypes: {
                type: Array,
                required: true
            },

            binTypes: {
                type: Array,
                required: true
            },

            assetOwners: {
                type: Array,
                required: true
            },

            fieldsAreRequired: {
                type: Boolean,
                required: false,
                default: false
            },

            errors: {
                type: Object,
                default: () => ({})
            },
		},

		data() {
            let collapsed = true;
			return {
                collapsed:collapsed,
				form: { ...this.modelValue },
			}
		},

        methods: {
            materialType() {
                return this.materialTypes.filter((materialType) => materialType.name === this.form.material_type)[0];
            },

            recalculateWeightUnitsPerWeight() {
				if (this.$page.props.small_units === 'kilograms') {
					if (this.propertyType === 'Multi-family') {
	                    this.form.kilograms_per_yard = parseFloat((this.form.compaction_ratio * this.materialType().multifamily_kilograms_per_yard).toFixed(12));
	                } else if (this.propertyType === 'Commercial') {
	                    this.form.kilograms_per_yard = parseFloat((this.form.compaction_ratio * this.materialType().commercial_kilograms_per_yard).toFixed(12));
	                }
				} else {
	                if (this.propertyType === 'Multi-family') {
	                    this.form.pounds_per_yard = parseFloat((this.form.compaction_ratio * this.materialType().multifamily_pounds_per_yard).toFixed(12));
	                } else if (this.propertyType === 'Commercial') {
	                    this.form.pounds_per_yard = parseFloat((this.form.compaction_ratio * this.materialType().commercial_pounds_per_yard).toFixed(12));
	                }
				}
            },
            toggleAdvancedOptions() {
                this.collapsed = !this.collapsed;
            }
        },

		watch: {
            'form.type': {
                handler() {
                    let compactedOrBaledTypes = ['Compactor - Apartment', 'Compactor - Self-Contained', 'Compactor - Stationary', 'Compactor - Unknown', 'Compactor - Vertical Baler', 'RL Comp', 'FL Comp'];

                    this.form.is_compacted_or_baled = compactedOrBaledTypes.indexOf(this.form.type) !== -1;
                },
                deep: true
            },

            'form.material_type': {
                handler() {
                    this.form.disposal_method = this.materialType().default_disposal_method
                    this.form.compaction_ratio = this.form.is_compacted_or_baled ? (this.materialType().default_compaction_ratio ?? 2.6) : 1
                    this.recalculateWeightUnitsPerWeight()
                },
                deep: true
            },

            'form.is_compacted_or_baled': {
                handler() {
                    this.form.compaction_ratio = this.form.is_compacted_or_baled ? (this.materialType().default_compaction_ratio ?? 2.6) : 1;
                    this.recalculateWeightUnitsPerWeight();
                },
                deep: true
            },

            propertyType: {
                handler() {
                    if (this.form.material_type && this.form.compaction_ratio !== null) {
                        this.recalculateWeightUnitsPerWeight();
                    }
                }
            },

			form: {
				handler() {
					this.$emit('update:modelValue', this.form)
                    this.$emit('change', this.form)
				},
				deep: true
			}
		}
	}
</script>
