<template>
    <Head title="Accounting Settings" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Accounting Settings</span>
        </nav>
    </Teleport>

    <div class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="accounting-integrations" />

                <div class="lg:col-span-9 pt-8">
                    <div class="sm:px-6">
                        <div>
                            <h2 class="text-lg leading-6 font-medium text-gray-900">Accounting Integration</h2>
                            <p class="mt-1 text-sm text-gray-500">
                                Configure settings for third party accounting systems.
                            </p>
                        </div>

                        <div class="mt-2 divide-y divide-gray-200">
                            <div class="mt-6 grid grid-cols-12 gap-6">
                                <select-input class="col-span-12" v-model="form.system" label="Accounting System" :errors="errors['system']">
                                    <option :value="null">None</option>
                                    <option v-for="(label, value) in accountingSystems" :value="value" :key="value">{{ label }}</option>
                                </select-input>

                                <template v-if="!unsaved && form.system == 'quick_books'">
                                    <div v-if="!hasToken || needToRefreshAccess" class="col-span-12 grid grid-cols-3 gap-x-2">
                                        <a class="col-span-3 sm:col-span-1" href="#" @click="oauthLogin">
                                            <img src="/images/C2QB_green_btn_lg.png" width="178" />
                                        </a>
                                        <div class="col-span-3 sm:col-span-2 text-red-600">It doesn't look like we have a valid token, please reconnect to fetch a new token!</div>
                                    </div>

                                    <div v-else class="col-span-12">
                                        <button class="btn btn-orange" @click="oauthLogin">Refresh Token</button>
                                    </div>

                                    <select-input v-if="hasToken" class="col-span-12 sm:col-span-6" v-model="form.payable" label="Accounts Payable (GL Account)" :errors="errors['payable']">
                                        <option v-for="glCode in gl_accounts" :value="glCode.external_accounting_id" :key="glCode.external_accounting_id">{{ glCode.name }}</option>
                                    </select-input>

                                    <select-input v-if="hasToken" class="col-span-12 sm:col-span-6" v-model="form.receivable" label="Accounts Receivable (GL Account)" :errors="errors['receivable']">
                                        <option v-for="glCode in gl_accounts" :value="glCode.external_accounting_id" :key="glCode.external_accounting_id">{{ glCode.name }}</option>
                                    </select-input>
                                </template>

                                <template v-if="!unsaved && form.system == 'quick_books_desktop'">
                                    <select-input class="col-span-12 sm:col-span-6" v-model="form.payable" label="Accounts Payable (GL Account)" :errors="errors['payable']">
                                        <option v-for="glCode in gl_accounts" :value="glCode.external_accounting_id" :key="glCode.external_accounting_id">{{ glCode.name }}</option>
                                    </select-input>

                                    <select-input class="col-span-12 sm:col-span-6" v-model="form.receivable" label="Accounts Receivable (GL Account)" :errors="errors['receivable']">
                                        <option v-for="glCode in gl_accounts" :value="glCode.external_accounting_id" :key="glCode.external_accounting_id">{{ glCode.name }}</option>
                                    </select-input>
                                </template>
                            </div>
                        </div>


                        <div class="mt-4 py-4 px-4 flex justify-end sm:px-6">
                            <loading-button type="submit" class="btn btn-orange" @click="updateSettings" :loading="saving">
                                Save
                            </loading-button>
                        </div>

                        <div class="flex justify-end ">
                            <div v-if="unsaved" class="block text-red-600 ">Please save to persist your changes.</div>
                        </div>

                        <hr class="my-4"/>


                        <div class="py-6">
                            <div class="hidden lg:block">
                                <div class="border-b border-gray-200">
                                    <nav class="-mb-px flex">
                                        <a @click="currentTab = 'gl_accounts'" href="#" class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm" :class="{'border-d-orange-500 text-d-orange-500': currentTab === 'gl_accounts', 'border-transparent text-gray-500 hover:text-d-blue-500 hover:border-d-blue-500' : currentTab != 'gl_accounts'}" >
                                            GL Accounts
                                        </a>

                                        <a @click="currentTab = 'vendors'" href="#" class="whitespace-nowrap ml-8 py-4 px-1 border-b-2  font-medium text-sm" :class="{'border-d-orange-500 text-d-orange-500': currentTab === 'vendors', 'border-transparent text-gray-500 hover:text-d-blue-500 hover:border-d-blue-500': currentTab != 'vendors'}">
                                            Vendors
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
	                    <div class="sm:hidden py-4">
		                    <label for="tabs" class="sr-only">Select a tab</label>
		                    <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
		                    <select @change="changeCurrentTab" id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-d-orange-500 focus:outline-none focus:ring-d-orange-500 sm:text-sm">
			                    <option value="gl_accounts" :selected="currentTab === 'gl_accounts'"> Gl Accounts </option>
			                    <option value="vendors" :selected="currentTab === 'vendors'"> Vendors </option>
		                    </select>
	                    </div>

                        <div v-if="currentTab === 'gl_accounts'">
                            <div class="flex items-start justify-between">
                                <h2 class="text-lg leading-6 font-medium text-gray-900">GL Accounts</h2>

                                <div v-if="accountingSettings.system === 'quick_books'">
                                    <loading-button v-if="!(!hasToken || needToRefreshAccess)" type="submit" class="btn btn-gray float-right" @click="refreshAccounts" :loading="saving">
                                        Refresh Accounts
                                    </loading-button>
                                </div>

                                <div v-else class="flex items-end gap-1">
                                    <text-input v-model="newAccountName" label="Add New Account" placeholder="Account Name" :errors="errors['name']" />
                                    <loading-button :loading="saving" class="btn btn-gray" @click="addNewGLAccount">Add</loading-button>
                                </div>
                            </div>

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(glCode, index) in gl_accounts" :key="glCode">
                                        <td >{{ glCode.name }}</td>
                                        <td>
                                            <a href="#" v-if="allowAccountRemovals" class="link" @click="removeGLAccount(glCode.id)">
                                                <icon name="trash" class="inline text-red-600 fill-current h-4 w-4" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div v-if="currentTab === 'vendors'">
                            <div class="flex items-start justify-between">
                                <h2 class="text-lg leading-6 font-medium text-gray-900">Vendors</h2>

                                <div v-if="accountingSettings.system === 'quick_books'">
                                    <loading-button v-if="!(!hasToken || needToRefreshAccess)" type="submit" class="btn btn-gray float-right" @click="refreshVendors" :loading="saving">
                                        Refresh Vendors
                                    </loading-button>
                                </div>

                                <div v-else class="flex items-end gap-1">
                                    <text-input v-model="newVendorName" label="Add New Vendor" placeholder="Vendor Name" :errors="errors['name']" />
                                    <loading-button  :loading="saving" class="btn btn-gray" @click="addNewVendor">Add</loading-button>
                                </div>
                            </div>

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(vendor, index) in vendors" :key="index">
                                        <td >{{ vendor.name ?? '' }}</td>
                                        <td>
                                            <a href="#" class="link" v-if="allowVendorRemovals" @click="removeVendor(vendor.id)">
                                                <icon name="trash" class="inline text-red-600 fill-current h-4 w-4" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import OAuth from '@/Shared/Utils/OAuth.js';
    import SubNav from '../SubNav.vue';

    export default {
        components: {
            Head,
            Icon,
            TextInput,
            SelectInput,
            ToggleSwitchInput,
            AddressPicker,
            LoadingButton,
            SubNav
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },
            accountingSystems: Object,
            authorizationUrl: String,
            hasToken: Boolean,
            needToRefreshAccess: Boolean,
            gl_accounts: Array,
            vendors: Array,

            accountingSettings: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                saving: false,
                unsaved: false,
                newAccountName: null,
                newVendorName: null,
                currentTab: 'gl_accounts',
                form: {... this.accountingSettings},
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
			changeCurrentTab(e) {
				this.currentTab = e.target.value;
			},
            updateSettings() {
                this.saving = true;

                this.$inertia
                    .put(this.$route('tenant-settings.accounting-integrations.update'), this.form, {
                        preserveScroll: true,
                        onFinish: () => { this.saving = false, this.unsaved = false }
                    });
            },

            addNewGLAccount() {
                this.$inertia
                    .post(this.$route('tenant-settings.accounting-integrations.gl-accounts.store'), {'name': this.newAccountName}, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.saving = false
                            this.newAccountName = ''
                        }
                    });
            },

            removeGLAccount(id) {
                this.$inertia
                    .delete(this.$route('tenant-settings.accounting-integrations.gl-accounts.destroy', id), {}, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.saving = false
                        }
                    });
            },

            addNewVendor() {
                this.$inertia
                    .post(this.$route('tenant-settings.accounting-integrations.vendors.store'), {'name': this.newVendorName}, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.saving = false
                            this.newVendorName = ''
                        }
                    });
            },

            removeVendor(id) {
                this.$inertia
                    .delete(this.$route('tenant-settings.accounting-integrations.vendors.destroy', id), {}, {
                        preserveScroll: true,
                        onFinish: () => {
                            this.saving = false
                        }
                    });
            },

            refreshAccounts() {
                this.saving = true;

                this.$inertia
                    .put(this.$route('tenant-settings.accounting-integrations.refresh-accounts'), {}, {
                        onFinish: () => {
                            this.saving = false
                            this.form = {... this.accountingSettings}
                        }
                    });
            },

            refreshVendors() {
                this.saving = true;

                this.$inertia
                    .put(this.$route('tenant-settings.accounting-integrations.refresh-vendors'), {}, {
                        onFinish: () => {
                            this.saving = false
                            this.form = {... this.accountingSettings}
                        }
                    });
            },

            oauthLogin() {
                let oauth = new OAuth(this.authorizationUrl)
                oauth.loginPopup()
            },
        },

        computed: {
            allowVendorRemovals() {
                return this.accountingSettings?.system?.includes(['quick_books_desktop'])
            },

            allowAccountRemovals() {
                return this.accountingSettings?.system?.includes(['quick_books_desktop'])
            },
        },

        watch: {
            'form.system': function() {
                this.unsaved = true
            },
        }
    }
</script>
