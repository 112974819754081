<template>
    <Head title="Gathered Vendor Invoice Files" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Gather - Vendor Invoice Files</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :search-id="'search-gathered-vendor-invoice-files'"
        :clear-filters-route="$route('gathered-vendor-invoice-files.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="gatheredVendorInvoiceFiles.data.length" class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ gatheredVendorInvoiceFiles.data.length }} out of {{ gatheredVendorInvoiceFiles.total }} Gathered Vendor Invoice Files</div>

        <div class="col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
            <div class="flex items-center">
                <inertia-link v-if="$can('create')" class="btn btn-orange mr-2" :href="$route('gathered-vendor-invoice-files.create')">
                    Create New Batch
                </inertia-link>

                <div v-if="$page.props.permissions.extractVendorInvoices" class="inline-block">
                    <loading-button class="btn btn-gray mr-2" :loading="isExtractingFiles" @click="extractGatheredVendorInvoiceFiles" :disabled="numberOfFilesReadyForExtraction === 0 || isExtractingFiles">
                        Extract Gathered Files ({{ numberOfFilesReadyForExtraction }})
                    </loading-button>
                </div>
                <div class="inline-block">
                    <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!gatheredVendorInvoiceFiles.data.length">
                        Export to CSV
                    </loading-button>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-6">
        <div class="overflow-x-auto">
            <transition
                enter-active-class="transition-height ease-in-out duration-500"
                enter-from-class="h-0"
                enter-to-class="h-full"
                leave-active-class="transition-height ease-in-out duration-500"
                leave-from class="h-full"
                leave-to-class="h-0">
                <div v-if="gatheredVendorInvoiceFiles.data.length && selectedGatheredVendorInvoiceFileIds.length" class="bg-white flex h-10 items-center sm:left-14">
                    <span v-if="selectedGatheredVendorInvoiceFileIds.length === 1" class="mr-8">{{  `${selectedGatheredVendorInvoiceFileIds.length} item selected` }}</span>
                    <span v-else class="mr-8">{{  `${selectedGatheredVendorInvoiceFileIds.length} items selected` }}</span>

                    <div class="flex space-x-3">
                        <button type="button" @click="archiveSelectedFiles" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyNonArchivedSelected">Archive</button>
                        <button type="button" @click="unarchiveSelectedFiles" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyArchivedSelected">Unarchive</button>

                        <!--Used a form action here because we don't want inertia to handle the redirect for the zip download-->
                        <form :action="$route('gathered-vendor-invoice-files.bulk-download-selected-pdf')" method="POST">
                            <input type="hidden" name="_token" :value="csrfToken">
                            <input v-for="(gathered_vendor_invoice_file_id, index) in selectedGatheredVendorInvoiceFileIds" type="hidden" :name="`gathered_vendor_invoice_file_ids[${index}]`" :value="gathered_vendor_invoice_file_id">
                            <button type="submit" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="selectedGatheredVendorInvoiceFileIds.length === 0">Download</button>
                        </form>
                    </div>
                </div>
            </transition>

            <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                            <input type="checkbox" :disabled="!gatheredVendorInvoiceFiles.data.length" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :checked="gatheredVendorInvoiceFiles.data.length && (indeterminate || selectedGatheredVendorInvoiceFileIds.length === gatheredVendorInvoiceFiles.data.length)" :indeterminate="indeterminate" @change="selectedGatheredVendorInvoiceFileIds = $event.target.checked ? gatheredVendorInvoiceFiles.data.map((gatheredVendorInvoiceFile) => gatheredVendorInvoiceFile.id) : []" />
                        </th>
                        <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1">PDF</th>
                        <sortable-table-header scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" field="document_name" :filters="filters" route-name="gathered-vendor-invoice-files.index" v-show="form.selected_headers.includes('File Name')">File Name</sortable-table-header>
                        <sortable-table-header scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" field="batch_name" :filters="filters" route-name="gathered-vendor-invoice-files.index" v-show="form.selected_headers.includes('Batch Name')">Batch Name</sortable-table-header>
                        <sortable-table-header scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" field="document_size" :filters="filters" route-name="gathered-vendor-invoice-files.index" v-show="form.selected_headers.includes('File Size')">File Size</sortable-table-header>
                        <sortable-table-header scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" field="source" :filters="filters" route-name="gathered-vendor-invoice-files.index" v-show="form.selected_headers.includes('Source')">Source</sortable-table-header>
                        <sortable-table-header scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" field="status" :filters="filters" route-name="gathered-vendor-invoice-files.index" v-show="form.selected_headers.includes('Status')">Status</sortable-table-header>
                        <sortable-table-header scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" field="archived_at" :filters="filters" route-name="gathered-vendor-invoice-files.index" v-show="form.selected_headers.includes('Archived At')">Archived At</sortable-table-header>
                        <sortable-table-header scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" field="created_at" :filters="filters" route-name="gathered-vendor-invoice-files.index" v-show="form.selected_headers.includes('Created At')">Created At</sortable-table-header>
                        <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="gatheredVendorInvoiceFile in gatheredVendorInvoiceFiles.data" :key="gatheredVendorInvoiceFile.id">
                        <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                            <div v-if="selectedGatheredVendorInvoiceFileIds.includes(gatheredVendorInvoiceFile.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                            <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="gatheredVendorInvoiceFile.id" v-model="selectedGatheredVendorInvoiceFileIds" />
                        </td>
                        <td>
                            <a :href="$route('documents.preview', {path: gatheredVendorInvoiceFile.document_path})" class="flex items-center text-2xl p-1" target="_blank">
                                <icon name="file-pdf" class="inline fill-current text-red-500 hover:text-red-500 w-5 h-5" />
                            </a>
                        </td>
                        <td v-show="form.selected_headers.includes('File Name')"><p :title="gatheredVendorInvoiceFile.document_name">{{ $filters.limit_string(gatheredVendorInvoiceFile.document_name, 25) }}</p></td>
                        <td v-show="form.selected_headers.includes('Batch Name')">{{ gatheredVendorInvoiceFile.batch_name }}</td>
                        <td v-show="form.selected_headers.includes('File Size')">{{ toHumanSize(gatheredVendorInvoiceFile.document_size) }}</td>
                        <td v-show="form.selected_headers.includes('Source')">{{ gatheredVendorInvoiceFile.source }}</td>
                        <td v-show="form.selected_headers.includes('Status')">{{ gatheredVendorInvoiceFile.status }}</td>
                        <td v-show="form.selected_headers.includes('Archived At')">{{ $filters.format_date_time(gatheredVendorInvoiceFile.archived_at) }}</td>
                        <td v-show="form.selected_headers.includes('Created At')">{{ $filters.format_date_time(gatheredVendorInvoiceFile.created_at) }}</td>
                        <td class="text-right">
                            <inertia-link data-cy="gathered-vendor-invoice-files.index.show-link" :href="$route('gathered-vendor-invoice-files.show', gatheredVendorInvoiceFile.id)" class="link" title="View This Gathered Vendor Invoice File">
                                View
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="gatheredVendorInvoiceFiles.links" />
    </div>

    <div v-if="!gatheredVendorInvoiceFiles" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="money-check-alt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Gathered Vendor Invoice Files Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="gathered-vendor-invoice-file-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <label class="form-label">Created At</label>

                    <date-input v-model="form.created_at_begin" :errors="errors.created_at_begin" />

                    <span class="inline-block mt-2 ml-3 text-base text-gray-900">thru</span>

                    <date-input v-model="form.created_at_end" :errors="errors.created_at_end" class="col-span-1" />
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.sources" multiple>
                        <ComboboxLabel for="sources" class="form-label">Source</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput
                                    name="sources"
                                    id="sources"
                                    class="form-input"
                                    @change="sourceComboBoxQuery = $event.target.value"
                                    :display-value="comboBoxDisplayValue"
                                />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredSourceOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredSourceOptions" :key="option" :value="option" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                        {{ option }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <icon name="heroicon-check" class="size-5 fill-current" />
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel for="statuses" class="form-label">Invoice Status</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput
                                    name="statuses"
                                    id="statuses"
                                    class="form-input"
                                    @change="statusComboBoxQuery = $event.target.value"
                                    :display-value="comboBoxDisplayValue"
                                />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredStatusOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredStatusOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                        {{ option }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <icon name="heroicon-check" class="size-5 fill-current" />
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <select-input id="archived" name="archived" class="col-span-1" v-model="form.archived" label="Archived">
                    <option value="except">Hide Archived</option>
                    <option value="include">Include Archived</option>
                    <option value="only">Only Archived</option>
                </select-input>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import { pickBy, throttle } from "lodash-es";

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import DateInput from "@/Shared/DateInput.vue";
    import SlideOver from '@/Shared/SlideOver.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import IndexSearchForm from "@/Shared/IndexSearchForm.vue";
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import BooleanCheckboxInput from '@/Shared/BooleanCheckboxInput.vue';

    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            Head,
            DateInput,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            ColumnSelector,
            IndexSearchForm,
            ToggleSwitchInput,
            SortableTableHeader,
            BooleanCheckboxInput,

            // Tailwind UI combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {

            filters: Object,

            csrfToken: {
                type: String,
                required: true,
            },

            gatheredVendorInvoiceFiles: {
                type: Object,
                required: true
            },

            sources: {
                type: Object,
                required: true
            },

            statuses: {
                type: Object,
                required: true
            },

            numberOfFilesReadyForExtraction: {
                type: Number,
                required: true,
            },

            isBulkExtracting: {
                type: Boolean,
                required: true,
            },

            errors: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    per_page: this.filters.per_page,
                    sources: this.filters.sources,
                    statuses: this.filters.statuses,
                    archived: this.filters.archived,
                    created_at_begin: this.filters.created_at_begin,
                    created_at_end: this.filters.created_at_end,
                    selected_headers: this.filters.selected_headers,
                },
                saving: false,
                isExtractingFiles: this.isBulkExtracting, // This will be true if we are already extracting
                statusComboBoxQuery: '',
                sourceComboBoxQuery: '',
                selectedGatheredVendorInvoiceFileIds: [],
                mounted: false,
                headers: [],
                excludedHeaders: ['PDF', 'Actions'],
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            toHumanSize(fileSize) {
                let megabytes = Math.round(fileSize/(1024*1024) * 100) / 100
                let kilobytes = Math.round(fileSize/(1024) * 100) / 100

                if (megabytes > 1) return megabytes + 'MB';
                return kilobytes + 'KB';
            },

            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.gathered-vendor-invoice-files.index'), {...this.filters}, {
                    onFinish: () => { this.saving = false; }
                });
            },

            extractGatheredVendorInvoiceFiles() {
                if (this.isExtractingFiles) {
                    return;
                }

                this.isExtractingFiles = true
                this.$inertia.post(this.$route('gathered-vendor-invoice-files.bulk-extract'));
            },

            comboBoxDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            archiveSelectedFiles() {
                this.$inertia.post(this.$route('gathered-vendor-invoice-files.archive-selected'), {
                    gathered_vendor_invoice_file_ids: this.selectedGatheredVendorInvoiceFileIds,
                }, {
                    onSuccess: () => this.selectedGatheredVendorInvoiceFileIds = []
                });
            },

            unarchiveSelectedFiles() {
                this.$inertia.post(this.$route('gathered-vendor-invoice-files.unarchive-selected'), {
                    gathered_vendor_invoice_file_ids: this.selectedGatheredVendorInvoiceFileIds,
                }, {
                    onSuccess: () => this.selectedGatheredVendorInvoiceFileIds = []
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if (this.mounted) {
                            let query = pickBy(this.form);
                            query = Object.keys(query).length ? query : {remember: 'forget'};
                            this.$inertia.get(this.$route('gathered-vendor-invoice-files.index'), query, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;

                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.statuses
                    : this.statuses.filter((status) => {
                        return status.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            filteredSourceOptions() {
                return this.sourceComboBoxQuery === ''
                    ? this.sources
                    : this.sources.filter((source) => {
                        return source.toLowerCase().includes(this.sourceComboBoxQuery.toLowerCase());
                    });
            },

            indeterminate() {
                return this.selectedGatheredVendorInvoiceFileIds.length > 0 && this.selectedGatheredVendorInvoiceFileIds.length < this.gatheredVendorInvoiceFiles.data.length;
            },

            onlyNonArchivedSelected() {
                return this.selectedGatheredVendorInvoiceFileIds.every((id) => {
                    return !this.gatheredVendorInvoiceFiles.data.find((file) => file.id === id)?.archived_at;
                });
            },

            onlyArchivedSelected() {
                return this.selectedGatheredVendorInvoiceFileIds.every((id) => {
                    return this.gatheredVendorInvoiceFiles.data.find((file) => file.id === id)?.archived_at;
                });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.sources,
                    form.statuses,
                    form.archived,
                    form.created_at_begin,
                    form.created_at_end,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            },
        },
    }
</script>
