<template>
    <Head :title="`Edit Client Contract Change - ${serviceActivity.service_id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">
                {{ serviceActivity.service_id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Client Contract Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 mb-12 text-base lg:text-lg">
        <div class="md:px-12">
            <div class="flex justify-between items-center my-6">
                <h1 class="text-2xl font-bold">Edit Client Billing Change</h1>

                <div class="flex items-center gap-x-4">
                    <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="btn btn-gray gap-x-2">
                        Cancel Edit
                    </inertia-link>
                    <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
                </div>
            </div>

            <fieldset class="border-t border-gray-300">
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Change Details
                </legend>

                <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-3 px-4">
                    <revert-input v-model="form.change_requester" label="Requested By" mark-as-required>
                        <select-input v-model="form.change_requester" :errors="errors['change_requester']">
                            <option value="vendor">{{ serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                            <option value="client">{{ serviceActivity.serviceSnapshot.location.clientCompany.name }}</option>
                            <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                        </select-input>
                    </revert-input>

                    <revert-input v-model="form.actual_date" label="Effective Date" mark-as-required>
                        <date-input :min="serviceCreationActivity.actual_date" v-model="form.actual_date" help-text="Date of contract change" :errors="errors['actual_date']"/>
                    </revert-input>

                    <revert-input v-model="form.change_reason" label="Why are you making this change?" mark-as-required>
                        <select-input v-model="form.change_reason">
                            <option>Other</option>
                            <option>Savings</option>
                        </select-input>
                    </revert-input>

                    <revert-input v-model="form.client_contract_id" label="Client Contract" mark-as-required>
                        <select-input v-model="form.client_contract_id" :errors="errors['client_contract_id']">
                            <option v-if="clientContracts.length === 0" :value="null">No contracts available for this Client</option>
                            <option
                                v-for="clientContract in clientContracts"
                                :key="clientContract.id"
                                :value="clientContract.id"
                            >{{ clientContract.id + " - Integration " + clientContract.integration_date ?? " - " }}
                            </option>
                        </select-input>
                    </revert-input>

                    <revert-input v-model="form.is_pass_through" label="Is Pass Through?" mark-as-required>
                        <select-input v-model="form.is_pass_through" :errors="errors['is_pass_through']">
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select-input>
                    </revert-input>

                    <revert-input v-model="form.client_billing_frequency" label="Client Billing Frequency" mark-as-required >
                        <select-input
                            :disabled="form.is_pass_through === true && serviceHasClientBaseCharge"
                            v-model="form.client_billing_frequency"
                            placeholder="Client Billing Frequency"
                            :errors="errors['client_billing_frequency']"
                        >
                            <option v-for="clientBillingFrequency in clientBillingFrequencies" :key="clientBillingFrequency" :value="clientBillingFrequency" >
                                {{ clientBillingFrequency }}
                            </option>
                        </select-input>
                    </revert-input>

                    <revert-input v-if="form.is_pass_through === false && serviceHasClientBaseCharge" v-model="form.client_base_charge" label="Client Base Charge" mark-as-required >
                        <money-input
                            v-model="form.client_base_charge"
                            :defaultCurrency="serviceActivity.serviceSnapshot.location.clientCompany.currency"
                            placeholder="Client Base Charge"
                            :errors="errors['client_base_charge']"
                        />
                    </revert-input>

                    <revert-input v-if="form.is_pass_through === false && serviceHasClientPerUnitCharge" v-model="form.client_per_unit_charge" label="Client Per Unit Charge" :help-text="clientPerUnitHelpText" mark-as-required >
                        <money-input
                            v-model="form.client_per_unit_charge"
                            :defaultCurrency="serviceActivity.serviceSnapshot.location.clientCompany.currency"
                            placeholder="Client Per Unit Charge"
                            :errors="errors['client_per_unit_charge']"
                        />
                    </revert-input>

                    <revert-input v-if="form.is_pass_through === false && serviceHasClientPerOccurrenceCharge" v-model="form.client_per_occurrence_charge" label="Client Per Occurrence Charge (Haul Amount)" mark-as-required >
                        <money-input
                            v-model="form.client_per_occurrence_charge"
                            :defaultCurrency="serviceActivity.serviceSnapshot.location.clientCompany.currency"
                            placeholder="Client Per Occurrence Charge"
                            :errors="errors['client_per_occurrence_charge']"
                        />
                    </revert-input>
                </div>
            </fieldset>

            <template v-if="showPriorPeriodAdjustment">
                <div class="mt-12 px-4">
                    <div class="rounded-md bg-yellow-50 p-4 mt-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <icon name="exclamation-triangle" class="size-5 text-yellow-400 fill-current" />
                            </div>
                            <div class="ml-3 flex-1 text-base">
                                <h3 class="font-medium text-yellow-800">Prior Period Adjustment</h3>
                                <div class="mt-2 text-yellow-700">
                                    <div class="pr-2 space-y-2">
                                        <p>
                                            You are changing the price on this service from
                                            <span class="font-medium">{{ $filters.format_money(serviceActivity.serviceSnapshot.client_base_charge) }}</span> to
                                            <span class="font-medium">{{ $filters.format_money(form.client_base_charge) }}</span> on
                                            <span class="font-medium">{{ $filters.format_date(form.actual_date) }}</span >.
                                        </p>
                                        <p v-if="priorPeriodAdjustment.lastLineItemBilled">
                                            The service was billed for fiscal period
                                            <span class="font-medium">{{ $filters.format_date(priorPeriodAdjustment.lastLineItemBilled.origin_date) }}</span> already for
                                            <span class="font-medium">{{ $filters.format_money(priorPeriodAdjustment.lastLineItemBilled.total_amount) }}</span>
                                        </p>
                                        <p>If you wish to bill a prior period adjustment for this change, navigate to the prior period adjustments page after saving this change.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="relative mt-12">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Other</span>
                </div>
            </div>

            <div class="px-4 mt-4 mb-8">
                <revert-input v-model="form.internal_change_notes" label="Internal Change Notes">
                    <textarea-input rows="4" v-model="form.internal_change_notes"/>
                </revert-input>
            </div>

            <loading-button class="btn btn-orange" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason">
                Submit Change
            </loading-button>
        </div>
    </div>
</template>

<script setup>
/**
 * This page is a Wizard to help user along with service changes
 */
import DateInput from '@/Shared/DateInput.vue';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import MoneyInput from '@/Shared/MoneyInput.vue';
import RevertInput from '@/Shared/RevertInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import {format, startOfToday} from "date-fns";
import axios from "axios";
import {reactive, ref, inject, computed, onMounted, watch} from 'vue';
import {Head, router, usePage} from '@inertiajs/vue3';

/**
 * Props
 */
const props = defineProps({
    clientBillingFrequencies: {
        type: Array,
        required: true
    },

    clientContracts: {
        type: Array,
        required: true
    },

    serviceCreationActivity: {
        type: Object,
        required: true
    },

    errors: {
        type: Object,
        default: () => ({})
    },

    serviceActivity: {
        type: Object,
        required: true
    },
});

/**
 * Injected
 */
const route = inject('route');
const page = usePage();

onMounted(() => {
    mounted.value = true;
});

/**
 * Data
 */
const saving = ref(false);
const priorPeriodAdjustment = ref(null);
const today = format(startOfToday(), 'yyyy-MM-dd');
const mounted = ref(false);
const form = reactive({
    actual_date: props.serviceActivity.actual_date,
    change_reason: props.serviceActivity.reason,
    change_requester: props.serviceActivity.change_requester,
    internal_change_notes: props.serviceActivity.internal_change_notes,
    client_contract_id: props.serviceActivity.serviceSnapshot.client_contract_id,
    is_pass_through: props.serviceActivity.serviceSnapshot.is_pass_through,
    client_billing_frequency: props.serviceActivity.serviceSnapshot.client_billing_frequency,
    client_base_charge: props.serviceActivity.serviceSnapshot.client_base_charge ?? null,
    client_per_unit_charge: props.serviceActivity.serviceSnapshot.client_per_unit_charge ?? null,
    client_per_occurrence_charge: props.serviceActivity.serviceSnapshot.client_per_occurrence_charge ?? null,
});

/**
 * Methods
 */
function submitChange() {
    saving.value = true;

    router.put(route('services.client-contract-changes.update', [props.serviceActivity.service_id, props.serviceActivity.id]), {
        ...form,
    }, {
        onFinish: () => saving.value = false
    });
}

function suggestPriorPeriodAdjustment() {
    const conditions = [
        page.props.permissions.accessRevenueManagement,
        !form.is_pass_through,
        ['Monthly', 'Quarterly', 'Yearly'].includes(form.client_billing_frequency),
        form.service_schedule_type !== 'One Time',
        serviceHasClientBaseCharge,
        props.serviceActivity.serviceSnapshot.client_base_charge !== form.client_base_charge,
    ];

    return conditions.every(condition => condition === true);
}

function updatePriorPeriodAdjustmentDetails() {
    axios.get(`/json/services/${props.serviceActivity.service_id}/prior-period-adjustments/create-from-price-change?change_date=${form.actual_date}&adjusted_amount=${form.client_base_charge.amount / 100}`).then(response => {
        props.priorPeriodAdjustment = response.data
    });
}

function deleteServiceChange() {
    if (window.confirm('Are you sure you want to delete this client contract change?')) {
        router.delete(route('services.client-contract-changes.destroy', {
            serviceId: props.serviceActivity.service_id,
            serviceActivityId: props.serviceActivity.id
        }));
    }
}

/**
 * Computed
 */
const serviceHasClientBaseCharge = computed(() => {
    return props.serviceActivity.service?.serviceType?.enabled_type_fields.includes('base_charges');
});

const serviceHasClientPerUnitCharge = computed(() => {
    return props.serviceActivity.service?.serviceType?.enabled_type_fields.includes('per_unit_charges');
});

const serviceHasClientPerOccurrenceCharge = computed(() => {
    return props.serviceActivity.service?.serviceType?.enabled_type_fields.includes('per_occurrence_charges');
});

const showPriorPeriodAdjustment = computed(() => {
    return suggestPriorPeriodAdjustment()
        && props.priorPeriodAdjustment?.proratedAmount?.amount
        && props.priorPeriodAdjustment.proratedAmount.amount != 0
});

const clientPerUnitHelpText = computed(() => {
    return serviceHasClientPerUnitCharge
        ? "Disposal Amount Per Ton"
        : "";
});

/**
 * Watchers
 */
watch(() => form.client_base_charge, () => {
    if (suggestPriorPeriodAdjustment()) {
        updatePriorPeriodAdjustmentDetails();
    }
});

watch(() => form.actual_date, () => {
    if (suggestPriorPeriodAdjustment()) {
        updatePriorPeriodAdjustmentDetails();
    }
});

watch(() => form.is_pass_through, (newValue, oldValue) => {
    form.client_billing_frequency = newValue === true && serviceHasClientBaseCharge
        ? 'Per Vendor Invoice'
        : props.serviceActivity.serviceSnapshot.client_billing_frequency;
});
</script>
