<template>
    <div :class="$attrs.class">
        <label v-if="label" class="form-label" :for="id">
            <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
        </label>

        <div class="mt-2">
            <textarea :id="id" ref="input" v-bind="{...$attrs, class: null}" :disabled="disabled" class="form-textarea" :class="{ error: errors }" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
        </div>

        <p v-if="helpText" class="form-help-text">{{ helpText }}</p>

        <div v-if="errors" class="form-error-text">{{ errors }}</div>
    </div>
</template>

<script>
    import autosize from 'autosize';
    import { v4 as uuid } from 'uuid';

    export default {
        inheritAttrs: false,

        props: {
            id: {
                type: String,
                default() {
                    return `textarea-input-${uuid()}`;
                },
            },

            modelValue: String,

            label: String,

            helpText: {
                type: String,
                default: ''
            },

            errors: {
                type: String,
                default: '',
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },

            autosize: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false
            }
        },

        mounted() {
            if (this.autosize) {
                autosize(this.$refs.input)
            }
        },

        methods: {
            focus() {
                this.$refs.input.focus()
            },

            select() {
                this.$refs.input.select()
            }
        }
    }
</script>
