<template>
    <div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
            <div class="md:col-span-3">
                <date-input
                    v-model="actual_date"
                    label="Date of Change"
                    helpText="When should this change happen?"
                    :min="earliestServiceActivity.actual_date"
                    :errors="errors['actual_date']"
                    mark-as-required
                />
            </div>

            <div class="md:col-span-3" v-if="actual_date && actual_date !== today">
                <div class="rounded-md bg-yellow-50 p-4" v-if="actual_date && actual_date < today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-yellow-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-yellow-700">
                                NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).
                            </p>
                        </div>
                    </div>
                </div>
                <div class="rounded-md bg-blue-50 p-4" v-if="actual_date && actual_date > today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-blue-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-blue-700">
                                NOTE: This will insert a scheduled change that will not affect the service until the chosen date. You may cancel this change at any time prior to the chosen date.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Ignore this for now unless other options are needed -->
            <!-- <div :class="[actual_date && actual_date === today ? 'md:col-span-3' : 'col-span-1 md:col-span-6']">
                <select-input v-model="change_reason" label="Reason for Change" :errors="errors['change_reason']" :markAsRequired="true">
                    <option>Other</option>
                </select-input>
            </div> -->

            <!-- If the above input returns, then just put the class as md:col-span-3 -->
            <div :class="[actual_date && actual_date === today ? 'md:col-span-3' : 'col-span-1 md:col-span-6']">
                <select-input
                    v-model="form.vendor_account_id"
                    label="Vendor Account"
                    :errors="errors['vendor_account_id']"
                    mark-as-required
                >
                    <option v-if="!vendorAccounts.length" :value="null">No accounts available for this Vendor</option>
                    <option v-if="vendorAccounts.length" :value="null" selected>No account</option>
                    <option v-for="vendorAccount in vendorAccounts" :value="vendorAccount.id">
                        {{ vendorAccount.account_number + " - " + vendorAccount.status }}
                    </option>
                </select-input>
            </div>
        </div>

        <div class="border-t border-gray-300 mt-8 pt-8 px-4">
            <textarea-input rows="4" v-model="internal_change_notes" label="Internal Change Note" :errors="errors['internal_change_notes']" />
        </div>

        <loading-button class="rounded-md bg-d-orange-500 px-3 py-2 mt-12 text-base font-semibold text-white shadow-sm hover:bg-d-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-d-orange-500 disabled:opacity-50" :loading="saving" @click="submitChange" :disabled="!actual_date || !change_reason">
            Submit Change
        </loading-button>
    </div>
</template>

<script setup>
    import { router } from '@inertiajs/vue3';
    import { ref, inject, reactive } from 'vue';
    import { format, startOfToday } from "date-fns";

    import Icon from '@/Shared/Icon.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';

    /**
     * Props
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        service: {
            type: Object,
            required: true
        },

        who: {
            type: String,
            required: true
        },

        vendorAccounts: {
            type: Array,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        }
    });

    /**
     * Injected
     */
    const route = inject('route');

    /**
     * Data
     */
    const saving = ref(false);
    const actual_date = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const change_reason = ref('Other');
    const change_requester = ref(props.who);
    const internal_change_notes = ref(null);
    const today = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const form = reactive({
        vendor_account_id: props.service.vendor_account_id,
    });

    /**
     * Methods
     */
    function submitChange() {
        saving.value = true;

        router.post(route('services.vendor-account-changes.store', [props.service.id]), {
            ...form,
            'actual_date': actual_date.value,
            'change_reason': change_reason.value,
            'change_requester': props.who,
            'internal_change_notes': internal_change_notes.value,
        }, {
            onFinish: () => saving.value = false
        });
    }
</script>
