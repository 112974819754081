<template>
    <Head title="Bulk Imports" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Bulk Imports</span>
        </nav>
    </Teleport>

    <div class="relative">
        <div class="bg-white rounded-lg overflow-hidden pb-8">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="bulk-imports" />

                <div class="lg:col-span-9 px-4 sm:px-6">
                    <div class="grid grid-cols-6 my-4 items-center">
                        <div class="col-span-6 lg:col-span-2">
                            <div class="my-2">
                                <div class="input-group mt-0">
                                    <span class="input-group-item-left">
                                        <icon name="search" class="size-4 text-gray-400 fill-current" />
                                    </span>

                                    <input type="text" v-model="form.search" placeholder="Search" class="input-group-field">
                                </div>
                            </div>
                        </div>

                        <div class="mx-auto lg:mx-0 lg:ml-auto lg:col-span-4 grid-rows-1 flex flex-reverse">
                            <div class="col-span-1 justify-content-center">
                                <inertia-link :href="$route('tenant-settings.bulk-imports.create')" class="lg:mx-1 btn btn-orange">
                                    New
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="flex items-center justify-between">
                            <div class="my-6" v-if="bulkImports.data.length">
                                (Showing <span class="font-semibold text-gray-900">{{ bulkImports.data.length }}</span> out of <span class="font-semibold text-gray-900">{{ bulkImports.total }}</span>)
                            </div>
                            <div class="my-6" v-else>
                                0 Results
                            </div>

                            <div class="flex items-center">
                                <div class="">
                                    <a :href="$route('tenant-settings.bulk-imports.download-example-file')" class="mx-1 btn btn-gray">
                                        <icon name="download-arrow" class="fill-current h-4 w-4 mr-2 lg:mx-1" />
                                        Example
                                    </a>
                                </div>
                                <div class="">
                                    <a :href="$route('tenant-settings.bulk-imports.download-template-file')" class="mx-1 btn btn-gray">
                                        <icon name="download-arrow" class="fill-current h-4 w-4 mr-2 lg:mx-1" />
                                        Template
                                    </a>
                                </div>
                                <div class="">
                                    <a :href="$route('tenant-settings.bulk-imports.download-helper-file')" class="btn btn-gray">
                                        <icon name="download-arrow" class="fill-current h-4 w-4 mr-2 lg:mx-1" />
                                        Helper
                                    </a>
                                </div>
                                <div v-if="metadata.length > 0" class="mx-1 col-span-1">
                                    <button type="button" class="btn btn-gray " @click="$refs.searchFilterSlideOver.show()">
                                        Metadata
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div v-if="bulkImports.data.length">
                            <div class="overflow-x-auto">
                                <table class="table table-condensed">
                                    <thead>
                                        <tr>
                                            <th class="font-semibold">ID</th>
                                            <sortable-table-header field="created_at" class="font-semibold" :filters="filters" route-name="tenant-settings.bulk-imports.index">Timestamp</sortable-table-header>
                                            <sortable-table-header field="file_name" class="font-semibold" :filters="filters" route-name="tenant-settings.bulk-imports.index">File Name</sortable-table-header>
                                            <th class="font-semibold">Status</th>
                                            <th class="font-semibold">Type</th>
                                            <th class="font-semibold">User</th>
                                            <th class="font-semibold">Errors</th>
                                            <th class="font-semibold">Error File</th>
                                            <th class="font-semibold"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="bulkImport in bulkImports.data" :key="bulkImport.id">
                                            <td>{{ bulkImport.id }}</td>
                                            <td>{{ $filters.format_date_time(bulkImport.created_at) }}</td>
                                            <td><a class="link" :href="$route('tenant-settings.bulk-imports.download', bulkImport.id)" target="_blank">{{ bulkImport.file_name }}</a></td>
                                            <td>{{ bulkImport.status }}</td>
                                            <td>{{ bulkImport.type  }}</td>
                                            <td>{{ bulkImport.user ? bulkImport.user.name : "Unknown" }}</td>
                                            <td>
                                                <span v-if="bulkImport.status !== 'Queued'">{{ bulkImport.error_count }}</span>
                                            </td>
                                            <td>
                                                <a class="link" v-if="bulkImport.error_count > 0" :href="$route('tenant-settings.bulk-import-errors.show', bulkImport.id)" target="_blank">(Download Errors)</a>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <pagination :links="bulkImports.links" />
                        </div>

                        <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
                            <icon name="upload" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl break-after-column">No Bulk Imports Found</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <slide-over ref="searchFilterSlideOver" class="overflow-x-auto">
            <template #header="{close}">
                <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">Available Metadata</h2>
                        <div class="ml-3 flex h-7 items-center">
                            <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <span class="sr-only">Close panel</span>
                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </template>

            <template #body>
                <table class="table table-fixed">
                    <thead >
                    <tr>
                        <th>Key</th>
                        <th>Rules</th>
                    </tr>
                    </thead>
                    <tbody class="w-1/2">
                    <tr v-for="data in sortedMetaData" >
                        <td class="text-sm w-1/4 break-words"><p v-if="data.required" class="inline text-red-500">* </p>{{ data.key }} </td>
                        <td class="text-sm ">{{ data.rules }}</td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </slide-over>
    </div>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SubNav from '../SubNav.vue';
    import { throttle, pickBy } from 'lodash-es';
    import SlideOver from "../../../Shared/SlideOver.vue";

    export default {
        components: {
            Icon,
            Pagination,
            SortableTableHeader,
            SubNav,
            SlideOver,
            Head,
        },

        props: {
            bulkImports: {
                type: Object,
                required: true,
            },

            filters: Object,

            metadata: {
                type: Array,
                required: false,
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search
                },
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        watch: {
            form: {
                handler: throttle(function() {
                    let query = pickBy(this.form);
                    query = Object.keys(query).length ? query : {remember: 'forget'};

                    this.$inertia.get(this.$route('tenant-settings.bulk-imports.index'), query, {preserveState: true});
                }, 150),

                deep: true,
            },
        },

        methods: {
            changeNavigation(e) {
                this.$inertia.get(e.target.value);
            }
        },

        computed: {
            sortedMetaData() {
                return this.metadata.sort((a, b) => {
                    if (a.required && !b.required) {
                        return -1;
                    }

                    if (!a.required && b.required) {
                        return 1;
                    }

                    return 0;
                })
            }
        }

    }
</script>
