<template>
    <div :class="$attrs.class">
        <slot name="label">
            <label v-if="label" class="form-label" :for="id" :aria-required="markAsRequired">
                <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
	            <tooltip-icon v-if="tooltip" :tooltip="tooltip" :type="tooltipType"/>
            </label>
        </slot>

        <div class="form-input-wrapper">
            <input ref="input" v-bind="{...$attrs, class: null}" :disabled="disabled" class="form-input" :class="{ error: errors, disabled: disabled }" :type="type" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
        </div>

        <p v-if="helpText" class="form-help-text">{{ helpText }}</p>

        <div v-if="errors" class="form-error-text">{{ errors }}</div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { v4 as uuid } from 'uuid';

    import Icon from "./Icon.vue";
    import TooltipIcon from "./TooltipIcon.vue";

    const props = defineProps({
        id: {
            type: String,
            default: () => `text-input-${uuid()}`
        },
        type: {
            type: String,
            default: 'text'
        },
        modelValue: [String, Number],
        label: String,
        helpText: {
            type: String,
            default: ''
        },
        errors: {
            type: String,
            default: ''
        },
        markAsRequired: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        tooltip: {
            default: ''
        },
        tooltipType: {
            type: String,
            default: 'text'
        }
    });

    const emit = defineEmits(['update:modelValue']);

    const input = ref(null);

    const focus = () => input.value.focus();
    const blur = () => input.value.blur();
    const select = () => input.value.select();
    const setSelectionRange = (start, end) => input.value.setSelectionRange(start, end);
</script>
