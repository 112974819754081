<template>
    <Head title="Vendor Accounts" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Accounts</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :search-id="'search-vendor-accounts'"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :per-page-options="[15, 25, 50, 100, 200]"
        :clear-filters-route="$route('vendor-accounts.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div class="w-full my-4">
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="vendorAccounts.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ vendorAccounts.from }} - {{ vendorAccounts.to }} out of {{ vendorAccounts.total }} Vendor Accounts</div>

            <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
                <inertia-link :href="$route('vendor-accounts.create')" class="btn btn-orange mr-2">
                    New
                </inertia-link>

                <loading-button :loading="state === 'exporting'" class="btn btn-gray" @click="exportData" :disabled="!vendorAccounts.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

        <div>
            <div class="relative overflow-x-auto">
                <transition
                    enter-active-class="transition-height ease-in-out duration-500"
                    enter-from-class="h-0"
                    enter-to-class="h-full"
                    leave-active-class="transition-height ease-in-out duration-500"
                    leave-from class="h-full"
                    leave-to-class="h-0">
                    <div v-show="vendorAccounts.data.length && selectedVendorAccounts.length > 0" class="bg-white flex h-10 items-center sm:left-14">
                        <span v-if="selectedVendorAccounts.length === 1" class="mr-8">{{  `${selectedVendorAccounts.length} item selected` }}</span>
                        <span v-else class="mr-8">{{  `${selectedVendorAccounts.length} items selected` }}</span>

                        <div class="flex space-x-3">
                            <button type="button" @click="activateSelectedAccounts" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyNonActiveSelected">Activate</button>
                            <button type="button" @click="terminateSelectedAccounts" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyNonTerminatedSelected">Terminate</button>
                        </div>
                    </div>
                </transition>
                <table class="table table-condensed" ref="table">
                    <thead>
                        <tr>
                            <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                                <input type="checkbox" :disabled="!vendorAccounts.data.length" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :checked="vendorAccounts.data.length && (indeterminate || selectedVendorAccounts.length === vendorAccounts.data.length)" :indeterminate="indeterminate" @change="selectedVendorAccounts = $event.target.checked ? vendorAccounts.data.map((vendorAccount) => vendorAccount.id) : []" />
                            </th>
                            <sortable-table-header field="account_number_normalized" class="font-semibold" :filters="filters" route-name="vendor-accounts.index">Account Number</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Vendor')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Vendor</th>
                            <th v-show="form.selected_headers.includes('Vendor Contact')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Vendor Contact</th>
                            <th v-show="form.selected_headers.includes('Last Invoice Date')" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Last Invoice Date</th>
                            <th v-show="form.selected_headers.includes('Non Terminated Services')" scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Non Terminated Services</th>
                            <th v-show="form.selected_headers.includes('Status')" scope="col" class="w-36 px-3 py-3.5 text-left font-semibold text-gray-900">Status</th>
                            <th scope="col" class="w-36 px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="vendorAccount in vendorAccounts.data" :key="vendorAccount.id">
                            <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                <div v-if="selectedVendorAccounts.includes(vendorAccount.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                                <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="vendorAccount.id" v-model="selectedVendorAccounts" />
                            </td>
                            <td>
                                <inertia-link data-cy="vendor-accounts.index.show-link" :href="$route('vendor-accounts.show', [vendorAccount.id])" class="link mr-3" :title="`View Account ${vendorAccount.account_number}`">
                                    {{ vendorAccount.account_number }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Vendor')">
                                <inertia-link :href="$route('vendors.edit', vendorAccount.vendor_id)" class="link">
                                    {{ vendorAccount.vendor.name ?? '' }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Vendor Contact')">
                                {{ vendorAccount.contact ? vendorAccount.contact.display_name : null }}
                                {{ vendorAccount.contact && vendorAccount.contact.email_address ? ('(' + vendorAccount.contact.email_address + ')') : null }}
                                {{ vendorAccount.contact && vendorAccount.contact.primary_phone ? ('(' + vendorAccount.contact.primary_phone + ')') : null }}
                            </td>
                            <td v-show="form.selected_headers.includes('Last Invoice Date')">{{ vendorAccount.last_invoice_date }}</td>
                            <td v-show="form.selected_headers.includes('Non Terminated Services')" class="text-right">{{ getNonTerminatedServicesCount(vendorAccount.id) }}</td>
                            <td v-show="form.selected_headers.includes('Status')">
                                {{ vendorAccount.status }}
                            </td>
                            <td class="text-right">
                                <span class="inline-block">
                                    <inertia-link :href="$route('vendor-accounts.show', [vendorAccount.id])" class="link mr-3" title="View This Vendor Account">
                                        View
                                    </inertia-link>
                                </span>
                                <span class="inline-block">
                                    <inertia-link :href="$route('vendor-accounts.edit', [vendorAccount.id])" class="link" title="Edit This Vendor Account">
                                        Edit
                                    </inertia-link>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <pagination :links="vendorAccounts.links" />
        </div>

        <div v-if="!vendorAccounts.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="truck" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Vendor Accounts Found</span>
        </div>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="vendor-account-filter-form" name="vendor-account-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel class="form-label" for="statuses">Statuses</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput id="statuses" name="statuses" class="form-input" @change="statusComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="statuses.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="status in filteredStatusOptions" :key="status" :value="status" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ status }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <select-input id="with-trashed" name="with_trashed" class="col-span-6 sm:col-span-1" v-model="form.with_trashed" label="With Deleted">
                    <option value="except">Hide</option>
                    <option value="include">Include</option>
                    <option value="only">Only</option>
                </select-input>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import { throttle } from 'lodash-es';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from '@/Shared/SlideOver.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from "@/Shared/LoadingButton.vue";
    import ColumnSelector from '@/Shared/ColumnSelector.vue';
    import IndexSearchForm from '@/Shared/IndexSearchForm.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import BooleanCheckboxInput from '@/Shared/BooleanCheckboxInput.vue';
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';

    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            Head,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            ColumnSelector,
            IndexSearchForm,
            SortableTableHeader,
            BooleanCheckboxInput,
            CopyToClipboardButton,

            // Tailwind UI combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            nonTerminatedServicesCount: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },

            filters: {
                type: Object,
                required: true
            },

            vendorAccounts: {
                type: Object,
                required: true
            },

            statuses: {
                type: Array,
                required : true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    statuses: this.filters.statuses,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    with_trashed: this.filters.with_trashed,
                    selected_headers: this.filters.selected_headers,
                },
                state: 'passive',
                statusComboBoxQuery: '',
                selectedVendorAccounts: [],
                headers: [],
                excludedHeaders: ['Account Number', 'Actions'],
                mounted: false,
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.vendor-accounts.index'), this.queryFilters, {
                    onFinish: () => { this.state = 'passive'; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            getNonTerminatedServicesCount(vendorAccountId) {
                return this.nonTerminatedServicesCount[vendorAccountId] ?? 0;
            },

            activateSelectedAccounts() {
                this.$inertia.post(this.$route('vendor-accounts.activate-selected'), {
                    vendor_account_ids: this.selectedVendorAccounts,
                }, {
                    onSuccess: () => this.selectedVendorAccounts = []
                });
            },

            terminateSelectedAccounts() {
                this.$inertia.post(this.$route('vendor-accounts.terminate-selected'), {
                    vendor_account_ids: this.selectedVendorAccounts,
                }, {
                    onSuccess: () => this.selectedVendorAccounts = []
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('vendor-accounts.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = {...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredStatusOptions() {
                return this.statusComboBoxQuery === ''
                    ? this.statuses
                    : this.statuses.filter((status) => {
                        return status.toLowerCase().includes(this.statusComboBoxQuery.toLowerCase());
                    });
            },

            indeterminate() {
                return this.selectedVendorAccounts.length > 0 && this.selectedVendorAccounts.length < this.vendorAccounts.data.length;
            },

            onlyNonTerminatedSelected() {
                return this.selectedVendorAccounts.every((id) => {
                    return this.vendorAccounts.data.find((account) => account.id === id)?.status !== 'Terminated';
                });
            },

            onlyNonActiveSelected() {
                return this.selectedVendorAccounts.every((id) => {
                    return this.vendorAccounts.data.find((account) => account.id === id)?.status !== 'Active';
                });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.statuses,
                    form.with_trashed,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>
