<template>
    <transition enter-active-class="duration-500 sm:duration-700" leave-active-class="duration-500 sm:duration-700">
        <div v-show="isOpen" class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
            <transition
                enter-active-class="ease-in-out duration-500"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in-out duration-500"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                    <div v-show="isOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            </transition>

            <div @click="conditionallyToggleSidebar" class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex pl-10 sm:pl-16">
                        <transition enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from-class="translate-x-full"
                            enter-to-class="translate-x-0"
                            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from-class="translate-x-0"
                            leave-to-class="translate-x-full">
                                <div ref="slideOver" v-show="isOpen" class="pointer-events-auto w-screen" :class="maxWidth">
                                    <div class="flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white shadow-xl">

                                        <slot name="header" :close="close">
                                            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                                                <div class="flex items-start justify-between">
                                                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                                                    <div class="ml-3 flex h-7 items-center">
                                                        <button @click="close" type="button" class="rounded-md text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-d-orange-400">
                                                            <span class="sr-only">Close panel</span>
                                                            <Icon name="times" class="size-6 fill-current" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </slot>

                                        <div class="relative mt-6 flex-1 px-4 sm:px-6">
                                            <div class="absolute inset-0 px-4 sm:px-6">
                                                <slot name="body"></slot>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
    import { ref } from 'vue';
    import Icon from '@/Shared/Icon.vue';

    defineExpose({ show, close });

    /**
     * Props
     */
    defineProps({
        maxWidth: {
            type: String,
            default: 'max-w-md'
        }
    });

    /**
     * Emits
     */
    const emit = defineEmits(['closed', 'opened']);

    /**
     * Data
     */
    const isOpen = ref(false);
    const slideOver = ref(null);

    /**
     * Methods
     */
    function show() {
        isOpen.value = true;

        emit('opened');
    }

    function close() {
        isOpen.value = false;

        emit('closed');
    }

    function conditionallyToggleSidebar(e) {
        if (!slideOver.value.contains(e.target)) {
            close();
        }
    }
</script>
