<template>
    <Head title="Line Item Category Rules" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Line Item Categories</span>
        </nav>
    </Teleport>

    <main class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="line-item-categories" />

                <div class="lg:col-span-9 px-4 sm:px-6">

	                <horizontal-sub-nav current-tab="categorization_rules" />

                    <div class="divide-y divide-gray-200">
                        <!-- Vendor Fee Categorization Table -->
                        <div class="pt-8">
                            <h3 class="text-xl text-gray-700">Vendor Fee Categorizations</h3>

                            <div class="overflow-x-auto">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="font-semibold w-auto">Fee Name</th>
                                            <th class="font-semibold w-1/3">Line Item Category</th>
                                            <th class="font-semibold w-1/6">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="vendorFee in vendorFees" :key="vendorFee.id">
                                            <td>{{ vendorFee.name }}</td>
                                            <td :class="{'text-red-500': !vendorFee.lineItemCategorization}">
                                                {{ vendorFee.lineItemCategorization ? vendorFee.lineItemCategorization.category.name : 'Not Set' }}
                                            </td>
                                            <td>
                                                <a v-if="vendorFee.lineItemCategorization" href="" @click.prevent="showEditVendorFeeCategorizationModal(vendorFee)" class="link">
                                                    Edit
                                                </a>

                                                <a v-else href="" class="link" @click.prevent="showAddVendorFeeCategorizationModal(vendorFee)">
                                                    Add
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- Vendor Sales Tax Type Categorization Table -->
                        <div class="pt-8">
                            <h3 class="text-xl text-gray-700">Vendor Sales Tax Type Categorizations</h3>

                            <div class="overflow-x-auto">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th class="font-semibold w-auto">Sales Tax Type</th>
                                        <th class="font-semibold w-1/3">Line Item Category</th>
                                        <th class="font-semibold w-1/6">Actions</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr v-for="vendorSalesTaxType in vendorSalesTaxTypes" :key="vendorSalesTaxType.id">
                                        <td>{{ vendorSalesTaxType.name }}</td>
                                        <td :class="{'text-red-500': !vendorSalesTaxType.lineItemCategorization}">
                                            {{ vendorSalesTaxType.lineItemCategorization ? vendorSalesTaxType.lineItemCategorization.category.name : 'Not Set' }}
                                        </td>
                                        <td>
                                            <a v-if="vendorSalesTaxType.lineItemCategorization" href="" @click.prevent="showEditVendorSalesTaxTypeCategorizationModal(vendorSalesTaxType)" class="link">
                                                Edit
                                            </a>

                                            <a v-else href="" class="link" @click.prevent="showAddVendorSalesTaxTypeCategorizationModal(vendorSalesTaxType)">
                                                Add
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <!-- Vendor Fee Categorization !-->
    <modal class="max-w-2xl" ref="vendorFeeCategorizationModal" role="dialog" @closed="emptyVendorFeeTypeCategorizationModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl flex items-center">
                    {{ modalAction }} Categorization
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                 <form role="form">
                    <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                        <select-input v-model="vendorFeeCategorizationForm.line_item_category_id" class="col-span-1" placeholder="select a category" label="Category" @keyup.enter.prevent="updateVendorFeeCategorization">
                            <option v-for="category in lineItemCategories" :key="category.id" :value="category.id">{{ category.display_name }}</option>
                        </select-input>
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4 flex">
                <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                <loading-button :loading="saving" class="btn btn-orange" @click="modalCallback">Save Changes</loading-button>
            </div>
        </template>
    </modal>

    <!-- Vendor Sales Tax Type Categorization !-->
    <modal class="max-w-2xl" ref="vendorSalesTaxTypeCategorizationModal" role="dialog" @closed="emptyVendorSalesTaxTypeCategorizationModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl flex items-center">
                    {{ modalAction }} Categorization
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon aria-hidden="true" name="times" class="w-6 h-6 text-gray-500" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="p-4">
                <form role="form">
                    <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                        <select-input v-model="vendorSalesTaxTypeCategorizationForm.line_item_category_id" class="col-span-1" placeholder="select a category" label="Category" @keyup.enter.prevent="updateVendorSalesTaxTypeCategorization">
                            <option v-for="category in lineItemCategories" :key="category.id" :value="category.id">{{ category.display_name }}</option>
                        </select-input>
                    </div>
                </form>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4 flex">
                <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                <loading-button :loading="saving" class="btn btn-orange" @click="modalCallback">Save Changes</loading-button>
            </div>
        </template>
    </modal>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import Modal from '@/Shared/Modal.vue';
    import SubNav from '../SubNav.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import HorizontalSubNav from "../../TenantSettings/LineItemCategories/HorizontalSubNav.vue";

    export default {
        components: {
	        HorizontalSubNav,
            Head,
            Icon,
            Pagination,
            SortableTableHeader,
            Modal,
            SubNav,
            SelectInput,
            LoadingButton
        },

        props: {
            lineItemCategories: {
                type: Array,
                required: true
            },

            vendorFees: {
                type: Array,
                required: true
            },

            vendorSalesTaxTypes: {
                type: Array,
                required: true
            },

            vendorFeeLineItemCategorizations: {
                type: Array,
                required: true
            },

            vendorSalesTaxTypeLineItemCategorizations: {
                type: Array,
                required: true
            },
        },

        data() {
            return {
                vendorFeeCategorizationForm: {
                    vendor_fee_id: '',
                    line_item_category_id: ''
                },
                vendorSalesTaxTypeCategorizationForm: {
                    vendor_sales_tax_type_id: '',
                    line_item_category_id: ''
                },
                modalAction: '',
                modalCallback: () => {},
                saving: false,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            showAddVendorFeeCategorizationModal(vendorFee) {
                this.vendorFeeCategorizationForm.vendor_fee_id = vendorFee.id;
                this.modalAction = 'Add';
                this.modalCallback = this.storeVendorFeeCategorization;
                this.$refs.vendorFeeCategorizationModal.show();
            },

            storeVendorFeeCategorization() {
                this.saving = true;

                this.$inertia
                    .post(this.$route('tenant-settings.vendor-fee-line-item-categorizations.store'), this.vendorFeeCategorizationForm, {
                        preserveScroll: true,
                        onSuccess: () => this.$refs.vendorFeeCategorizationModal.close(),
                        onFinish: () => this.saving = false
                    });
            },

            showEditVendorFeeCategorizationModal(vendorFee) {
                this.vendorFeeCategorizationForm.vendor_fee_id = vendorFee.id;
                this.vendorFeeCategorizationForm.line_item_category_id = vendorFee.lineItemCategorization.line_item_category_id;
                this.modalAction = 'Edit';
                this.modalCallback = this.updateVendorFeeCategorization;

                this.$refs.vendorFeeCategorizationModal.show();
            },

            updateVendorFeeCategorization() {
                let categorization = this.vendorFeeLineItemCategorizations
                    .find(categorization => categorization.vendor_fee_id == this.vendorFeeCategorizationForm.vendor_fee_id);

                this.saving = true;

                this.$inertia
                    .put(this.$route('tenant-settings.vendor-fee-line-item-categorizations.update', categorization.id), this.vendorFeeCategorizationForm, {
                        preserveScroll: true,
                        onSuccess: () => this.$refs.vendorFeeCategorizationModal.close(),
                        onFinish: () => this.saving = false
                    });
            },

            emptyVendorFeeTypeCategorizationModal() {
                this.vendorFeeCategorizationForm.vendor_fee_id = '';
                this.vendorFeeCategorizationForm.line_item_category_id = '';
            },

            showAddVendorSalesTaxTypeCategorizationModal(vendorSalesTaxType) {
                this.vendorSalesTaxTypeCategorizationForm.vendor_sales_tax_type_id = vendorSalesTaxType.id;
                this.modalAction = 'Add';
                this.modalCallback = this.storeVendorSalesTaxTypeCategorization;
                this.$refs.vendorSalesTaxTypeCategorizationModal.show();
            },

            storeVendorSalesTaxTypeCategorization() {
                this.saving = true;

                this.$inertia
                    .post(this.$route('tenant-settings.vendor-sales-tax-type-line-item-categorizations.store'), this.vendorSalesTaxTypeCategorizationForm, {
                        preserveScroll: true,
                        onSuccess: () => this.$refs.vendorSalesTaxTypeCategorizationModal.close(),
                        onFinish: () => this.saving = false
                    });
            },

            showEditVendorSalesTaxTypeCategorizationModal(vendorSalesTaxType) {
                this.vendorSalesTaxTypeCategorizationForm.vendor_sales_tax_type_id = vendorSalesTaxType.id;
                this.vendorSalesTaxTypeCategorizationForm.line_item_category_id = vendorSalesTaxType.lineItemCategorization.line_item_category_id;
                this.modalAction = 'Edit';
                this.modalCallback = this.updateVendorSalesTaxTypeCategorization;

                this.$refs.vendorSalesTaxTypeCategorizationModal.show();
            },

            updateVendorSalesTaxTypeCategorization() {
                let categorization = this.vendorSalesTaxTypeLineItemCategorizations
                    .find(categorization => categorization.vendor_sales_tax_type_id === this.vendorSalesTaxTypeCategorizationForm.vendor_sales_tax_type_id);

                this.saving = true;

                this.$inertia
                    .put(this.$route('tenant-settings.vendor-sales-tax-type-line-item-categorizations.update', categorization.id), this.vendorSalesTaxTypeCategorizationForm, {
                        preserveScroll: true,
                        onSuccess: () => this.$refs.vendorSalesTaxTypeCategorizationModal.close(),
                        onFinish: () => this.saving = false
                    });
            },

            emptyVendorSalesTaxTypeCategorizationModal() {
                this.vendorSalesTaxTypeCategorizationForm.vendor_sales_tax_type_id = '';
                this.vendorSalesTaxTypeCategorizationForm.line_item_category_id = '';
            },

            changeNavigation(e) {
                this.$inertia.get(e.target.value);
            }
        }
    }
</script>
