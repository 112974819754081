<template>
    <div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 px-4">
            <div class="md:col-span-3">
                <date-input
                    v-model="actual_date"
                    label="Date of Change"
                    helpText="When should this change happen?"
                    :min="earliestServiceActivity.actual_date"
                    :errors="errors['actual_date']"
                    mark-as-required
                />
            </div>

            <div class="md:col-span-3" v-if="actual_date && actual_date !== today">
                <div class="rounded-md bg-yellow-50 p-4" v-if="actual_date && actual_date < today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-yellow-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-yellow-700">
                                NOTE: This will insert a historical record that something was incorrect and will update various reporting data. This correction will also show up in the historical time line of the service which may help with auditing. This correction will retroactively alter the service, but will not alter any related data (such as old invoice line items).
                            </p>
                        </div>
                    </div>
                </div>
                <div class="rounded-md bg-blue-50 p-4" v-if="actual_date && actual_date > today">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <icon name="info-circle" class="size-5 text-blue-400 fill-current" />
                        </div>
                        <div class="ml-3 flex-1">
                            <p class="text-base text-blue-700">
                                NOTE: This will insert a scheduled change that will not affect the service until the chosen date. You may cancel this change at any time prior to the chosen date.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="[actual_date && actual_date === today ? 'md:col-span-3' : 'col-span-1 md:col-span-6']">
                <select-input v-model="change_reason" label="Reason for Change" :errors="errors['change_reason']" :markAsRequired="true">
                    <option>Savings</option>
                    <option>Other</option>
                </select-input>
            </div>

            <div :class="[actual_date && actual_date === today ? 'md:col-span-6' : 'md:col-span-3']">
                <select-input v-model="form.vendor_contract_selection" label="Vendor Contract" :errors="errors['vendor_contract_selection']" mark-as-required >
                    <option value='missing-contract'>Missing contract</option>
                    <option :value=null>No contract</option>
                    <option v-for="vendorContract in vendorContracts" :value="vendorContract.id">
                        {{ vendorContract.display_id + " - " + vendorContract.status + " - Effective " + vendorContract.effective_date ?? " - " }}
                    </option>
                </select-input>
            </div>

            <div class="md:col-span-2">
                <select-input v-model="form.service_account_type" label="Service Account Type" :errors="errors['service_account_type']" markAsRequired >
                    <option>Permanent</option>
                    <option>Temporary</option>
                </select-input>
            </div>

            <div class="md:col-span-2">
                <select-input v-model="form.service_schedule_type" label="Service Schedule Type" :errors="errors['service_schedule_type']" markAsRequired >
                    <option>Scheduled</option>
                    <option>On Call</option>
                    <option v-if="form.service_account_type==='Temporary'">One Time</option>
                </select-input>
            </div>

            <div class="md:col-span-2" v-if="serviceHasVendorBaseCharge">
                <money-input
                    v-model="form.vendor_base_charge"
                    label="Vendor Base Charge"
                    placeholder="Vendor Base Charge"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors['vendor_base_charge']"
                    mark-as-required
                />
            </div>

            <div class="md:col-span-2" v-if="serviceHasVendorPerUnitCharge">
                <money-input
                    v-model="form.vendor_per_unit_charge"
                    class="col-span-2 md:col-span-1"
                    placeholder="Vendor Per Unit Charge"
                    label="Vendor Per Unit Charge"
                    :helpText="vendorPerUnitHelpText"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors['vendor_per_unit_charge']"
                    mark-as-required
                />
            </div>

            <div class="md:col-span-2" v-if="serviceHasVendorPerOccurrenceCharge">
                <money-input
                    v-model="form.vendor_per_occurrence_charge"
                    label="Vendor Per Occurrence Charge"
                    helpText="(Haul Amount)"
                    placeholder="Vendor Per Occurrence Charge"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors['vendor_per_occurrence_charge']"
                    mark-as-required
                />
            </div>

            <div class="md:col-span-2" v-if="serviceHasUnitsIncludedPerOccurrence">
                <text-input
                    type="number"
                    v-model="form.units_included_per_occurrence"
                    label="Units Included Per Occurrence"
                    helpText="(Tons Included Per Haul)"
                    :errors="errors['units_included_per_occurrence']"
                />
            </div>
        </div>

        <div class="border-t border-gray-300 mt-8 pt-8 px-4">
            <textarea-input rows="4" v-model="internal_change_notes" label="Internal Change Note" :errors="errors['internal_change_notes']" />
        </div>

        <loading-button class="rounded-md bg-d-orange-500 px-3 py-2 mt-12 text-base font-semibold text-white shadow-sm hover:bg-d-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-d-orange-500 disabled:opacity-50" :loading="saving" @click="submitChange" :disabled="!actual_date || !change_reason">
            Submit Change
        </loading-button>
    </div>
</template>

<script setup>
    import { router } from '@inertiajs/vue3';
    import { format, startOfToday } from "date-fns";
    import { reactive, ref, inject, computed } from 'vue';

    import Icon from '@/Shared/Icon.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';

    /**
     * Props
     */
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        },

        service: {
            type: Object,
            required: true
        },

        vendorContracts: {
            type: Array,
            required: true
        },

        who: {
            type: String,
            required: true
        },

        earliestServiceActivity: {
            type: Object,
            required: true
        }
    });

    /**
     * Injected
     */
    const route = inject('route');

    /**
     * Data
     */
    const saving = ref(false);
    const actual_date = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const change_reason = ref(null);
    const change_requester = ref(props.who);
    const internal_change_notes = ref(null);
    const today = ref(format(startOfToday(), 'yyyy-MM-dd'));
    const form = reactive({
        vendor_contract_selection: props.service.vendor_contract_selection,
        service_account_type: props.service.service_account_type,
        service_schedule_type: props.service.service_schedule_type,
        vendor_base_charge: props.service.vendor_base_charge ?? null,
        vendor_per_unit_charge: props.service.vendor_per_unit_charge ?? null,
        vendor_per_occurrence_charge: props.service.vendor_per_occurrence_charge ?? null,
        units_included_per_occurrence: props.service.units_included_per_occurrence ?? null,
    });

    /**
     * Methods
     */
    function submitChange() {
        saving.value = true;

        router.post(route('services.vendor-contract-changes.store', [props.service.id]), {
            ...form,
            'actual_date': actual_date.value,
            'change_reason': change_reason.value,
            'change_requester': props.who,
            'internal_change_notes': internal_change_notes.value,
        }, {
            onFinish: () => saving.value = false
        });
    }

    /**
     * Computed
     */
    const serviceHasVendorBaseCharge = computed(() => {
        return props.service?.serviceType?.enabled_type_fields.includes('base_charges');
    });

    const serviceHasVendorPerUnitCharge = computed(() => {
        return props.service?.serviceType?.enabled_type_fields.includes('per_unit_charges');
    });

    const serviceHasVendorPerOccurrenceCharge = computed(() => {
        return props.service?.serviceType?.enabled_type_fields.includes('per_occurrence_charges');
    });

    const serviceHasUnitsIncludedPerOccurrence = computed(() => {
        return props.service?.serviceType?.enabled_type_fields.includes('units_included_per_occurrence');
    });

    const vendorPerUnitHelpText = computed(() => {
        return serviceHasVendorPerUnitCharge
            ? "Disposal Amount Per Ton"
            : null;
    });
</script>
