<template>
    <div :class="$attrs.class">
        <slot name="label">
            <label v-if="label" class="form-label" :for="id">
                <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
	            <icon v-if="icon" :name="icon" class="inline fill-current h-4 w-4 mb-1 ml-1" :class="iconColor"/>
            </label>

        </slot>

        <select :id="id" ref="input" v-model="selected" v-bind="{...$attrs, class: null}" :disabled="disabled" class="form-select" :class="{ error: errors }">
            <slot />
        </select>

        <p v-if="helpText" class="form-help-text">{{ helpText }}</p>

        <div v-if="errors" class="form-error-text">{{ errors }}</div>
    </div>
</template>

<script>
    import { v4 as uuid } from 'uuid';
    import Icon from "./Icon.vue";

    export default {
	    components: {Icon},
        inheritAttrs: false,

        props: {
            id: {
                type: String,
                default() {
                    return `select-input-${uuid()}`;
                },
            },

            modelValue: [String, Number, Boolean, Object, Array],

            label: String,

            helpText: {
                type: String,
                default: ''
            },

            errors: {
                type: String,
                default: '',
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            },

			icon: {
				type: String,
				required: false,
			},

	        iconColor: {
		        type: String,
		        default: 'text-gray-400',
		        required: false,
	        }
        },

        // emits: ['update:modelValue'],

        data() {
            return {
                selected: this.modelValue,
            }
        },

        watch: {
            modelValue(newValue, oldValue) {
                this.selected = newValue;
            },

            selected(newValue, oldValue) {
                this.$emit('update:modelValue', newValue)
            },
        },

        methods: {
            focus() {
                this.$refs.input.focus()
            },

            select() {
                this.$refs.input.select()
            }
        }
    }
</script>
