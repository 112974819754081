<template>
    <Head :title="`Edit Vendor Account Change - ${serviceActivity.service_id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">
                {{ serviceActivity.service_id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Vendor Account Change</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="mt-6 mb-12 text-base lg:text-lg">
        <div class="md:px-12">
            <div class="flex justify-between items-center my-6">
                <h1 class="text-2xl font-bold">Edit Vendor Account Change</h1>

                <div class="flex items-center gap-x-4">
                    <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="btn btn-gray gap-x-2">
                        Cancel Edit
                    </inertia-link>
                    <button @click.prevent="deleteServiceChange" class="btn btn-red">Delete</button>
                </div>
            </div>

            <fieldset>
                <legend class="font-semibold text-xl text-d-blue-400 pr-4">
                    Change Details
                </legend>

                <div class="grid gap-x-4 gap-y-6 grid-cols-3 my-4 px-4">
                    <revert-input v-model="form.change_requester" class="col-span-2 md:col-span-1" label="Requested By" mark-as-required>
                        <select-input v-model="form.change_requester" :errors="errors['change_requester']">
                            <option value="vendor">{{ serviceActivity.serviceSnapshot.vendor.name ?? '' }}</option>
                            <option value="client">{{ serviceActivity.serviceSnapshot.location.clientCompany.name }}</option>
                            <option value="broker">{{ $page.props.authUser.tenant_name }}</option>
                        </select-input>
                    </revert-input>

                    <revert-input v-model="form.actual_date" class="col-span-3 md:col-span-1" label="Effective Date" mark-as-required>
                        <date-input :min="serviceCreationActivity.actual_date" v-model="form.actual_date" help-text="Date of vendor account change" :errors="errors['actual_date']"/>
                    </revert-input>

                    <revert-input v-model="form.change_reason" class="col-span-3 md:col-span-1" label="Why are you making this change?" mark-as-required>
                        <select-input v-model="form.change_reason">
                            <option>Other</option>
                        </select-input>
                    </revert-input>

                    <revert-input class="col-span-3" label="Vendor Account" v-model="form.vendor_account_id" mark-as-required>
                        <div v-if="!vendorAccounts.length" class="py-2 text-gray-700">No accounts available for this vendor</div>
                        <select-input-2 v-else v-model="form.vendor_account_id" :options="vendorAccounts" :errors="errors['vendor_account_id']">
                            <template #option-label="vendorAccount">{{ vendorAccount.account_number }} - {{ vendorAccount.status }}</template>
                        </select-input-2>
                    </revert-input>
                </div>
            </fieldset>

            <div class="relative mt-10">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-start">
                    <span class="bg-white font-semibold text-xl text-d-blue-400 pr-4">Other</span>
                </div>
            </div>

            <div class="px-4 mt-4 mb-8">
                <revert-input v-model="form.internal_change_notes" class="col-span-2" label="Internal Change Notes">
                    <textarea-input v-model="form.internal_change_notes"/>
                </revert-input>
            </div>

            <loading-button class="btn btn-orange mt-3" :loading="saving" @click="submitChange" :disabled="!form.actual_date || !form.change_reason">
                Submit Change
            </loading-button>
        </div>
    </div>
</template>

<script setup>
/**
 * This page is a Wizard to help user along with service changes
 */
import DateInput from '@/Shared/DateInput.vue';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import RevertInput from '@/Shared/RevertInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import SelectInput2 from "@/Shared/SelectInput2.vue";
import {format, startOfToday} from "date-fns";
import {reactive, ref, inject, onMounted} from 'vue';
import {Head, router, usePage} from '@inertiajs/vue3';

/**
 * Props
 */
const props = defineProps({
    errors: {
        type: Object,
        default: () => ({})
    },

    serviceActivity: {
        type: Object,
        required: true
    },

    serviceCreationActivity: {
        type: Object,
        required: true
    },

    vendorAccounts: {
        type: Array,
        required: true
    }
});

/**
 * Injected
 */
const route = inject('route');
const page = usePage();

onMounted(() => {
    mounted.value = true;
});

/**
 * Data
 */
const saving = ref(false);
const today = ref(format(startOfToday(), 'yyyy-MM-dd'));
const mounted = ref(false);
const form = reactive({
    actual_date: props.serviceActivity.actual_date,
    change_reason: props.serviceActivity.reason,
    change_requester: props.serviceActivity.change_requester,
    internal_change_notes: props.serviceActivity.internal_change_notes,
    vendor_account_id: props.serviceActivity.serviceSnapshot.vendor_account_id,
});

/**
 * Methods
 */
function submitChange()
{
    saving.value = true;

    router.put(route('services.vendor-account-changes.update', [props.serviceActivity.service_id, props.serviceActivity.id]), {
        ...form,
    }, {
        onFinish: () => saving.value = false
    });
}

function deleteServiceChange()
{
    if (window.confirm('Are you sure you want to delete this vendor account change?')) {
        router.delete(route('services.vendor-account-changes.destroy', {
            serviceId: props.serviceActivity.service_id,
            serviceActivityId: props.serviceActivity.id
        }));
    }
}
</script>
