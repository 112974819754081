<template>
    <div ref="pdfDocument">
        <template v-if="pdf" as="div" class="pdf-document">
                <div class="absolute flex items-center opacity-95 bg-gray-100 border-2 p-1">
                    <button @click="zoomOut" class="btn btn-gray">-</button>
                <button @click="zoomIn" class="btn btn-gray mx-2">+</button>
                <button @click="openInNewTab" class="btn btn-gray ml-8"><icon name="file-pdf" class="fill-current h-4 w-4 my-0.5" /></button>
            </div>

            <pdf-page
                v-for="page in pages"
                :scale="scale"
                :key="page.pageNumber"
                :page="page"
            />
        </template>
        <template v-else-if="loading">
            <div class="flex flex-col items-center justify-center mt-16">
                <icon name="spinner" class="size-12 text-gray-400 fill-current animate-spin" />
            </div>
        </template>
        <template v-else>
            <div class="flex flex-col items-center justify-center mt-16">
                <icon name="circle-question" class="size-12 text-d-orange-300 fill-current" />
                <div class="mt-4 text-center">
                    <h3 class="text-lg font-medium text-gray-900">PDF Unable to Load</h3>
                    <p class="mt-1 text-base text-gray-500">Unable to find and render the PDF file.</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
    // PDFDocument renders an entire PDF inline using PDF.js and <canvas>. Currently, it does
    // not support rendering of selected pages (but could be easily updated to do so).

    // Import methods and other needs
    import {onMounted, ref} from "vue";
    import range from 'lodash-es/range';
    import * as pdfjsLib from 'pdfjs-dist';
    import workerUrl from 'pdfjs-dist/legacy/build/pdf.worker.mjs?worker&url';

    // Import our components
    import PdfPage from './PdfPage.vue';
    import Icon from '@/Shared/Icon.vue';

    // Define incoming properties
    const props = defineProps({
        url: {
            type: String,
            required: true,
        }
    });

    // Start of our worker workaround setup
    // See: https://github.com/vitejs/vite/issues/13680#issuecomment-1819274694
    const js = `import ${JSON.stringify(new URL(workerUrl, import.meta.url))}`
    const blob = new Blob([js], { type: "application/javascript" })

    // Things for the page
    const pdfDocument = ref(null);
    const pdf = ref(undefined);
    const scale = ref(1.6);
    const pos = ref({ top: 0, left: 0, x: 0, y: 0 });
    const pages = ref([]);
    const loading = ref(false);

    // Some simple functions
    const zoomIn = () => scale.value += 0.2;
    const zoomOut = () => scale.value -= 0.2;
    const openInNewTab = () => window.open(props.url);

    // Things to do upon the component being mounted
    onMounted(() => {
        pdfDocument.value.addEventListener('mousedown', mouseDownHandler);
    });

    // Define our functions
    function WorkaroundWorker(options) {
        const objURL = URL.createObjectURL(blob)
        const worker = new Worker(objURL, { type: "module", name: options?.name })
        worker.addEventListener("error", () => {
            URL.revokeObjectURL(objURL)
        })
        return worker;
    }

    function fetchPDF() {
        loading.value = true;
        const loadingTask = pdfjsLib.getDocument(props.url);

        loadingTask.promise.then(loadedPdf => {
            pdf.value = loadedPdf;
            pages.value = [];

            const promises = range(1, loadedPdf.numPages + 1).map(number => loadedPdf.getPage(number));
            return Promise.all(promises).then(pdfPages => pages.value = pdfPages);
        }).catch(error => {
            console.log('An exception occurred while fetching the PDF');
            console.error(error.message);
        }).finally(() => {
            loading.value = false;
        });
    }

    function mouseDownHandler(e) {
        pos.value = {
            // The current scroll
            left: pdfDocument.value.scrollLeft,
            top: pdfDocument.value.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        };

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    }

    function mouseUpHandler() {
        pdfDocument.value.style.removeProperty('user-select');

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    }

    function mouseMoveHandler(e) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.value.x;
        const dy = e.clientY - pos.value.y;

        // Scroll the element
        pdfDocument.value.scrollTop = pos.value.top - dy;
        pdfDocument.value.scrollLeft = pos.value.left - dx;
    }

    // Things that happen in the "created" portion of the lifecycle can just be run as if you are calling it
    // See: https://stackoverflow.com/questions/64897835/what-is-an-equivalent-of-created-in-the-vue-js-composition-api
    pdfjsLib.GlobalWorkerOptions.workerPort = new WorkaroundWorker({name: "pdf.worker"});
    fetchPDF();
</script>
<style>
.pdf-document {
    cursor: grab;
    overflow: scroll;
}
</style>
