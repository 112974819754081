<template>
    <Head title="Vendor Invoices" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Audit - Vendor Invoices</span>
        </nav>
    </Teleport>

    <index-search-form
        v-model:search="form.search"
        v-model:per-page="form.per_page"
        v-model:selected-headers="form.selected_headers"
        :headers="headers"
        :filters-in-use="filtersInUse"
        :excluded-headers="excludedHeaders"
        :search-id="'search-vendor-invoices'"
        :per-page-options="[15, 25, 50, 100, 200, 500]"
        :clear-filters-route="$route('vendor-invoices.index', {remember: 'forget'})"
        @show-filters="$refs.searchFilterSlideOver.show()"
    />

    <div v-if="otherUsers.length > 0" class="flex -space-x-2 overflow-hidden mb-1">
        <template v-for="user in otherUsers" :key="user.id">
            <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.name">
            <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
            </span>
        </template>
    </div>

    <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="invoices.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ invoices.from }} - {{ invoices.to }} out of {{ invoices.total }} Vendor Invoices</div>

        <div class="col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end">
            <div class="flex items-center">
                <loading-button :loading="state === 'exporting'" class="btn btn-gray mr-2" @click="exportData" :disabled="!invoices.data.length">
                    Export to CSV
                </loading-button>

                <loading-button :loading="state === 'posting-approved'" class="btn btn-gray mr-2" :class="{disabled: state !== 'passive'}" @click="postApprovedInvoices" title="Post All Invoices">
                    Post All Approved
                </loading-button>

                <loading-button :loading="state === 'force-posting-approved'" class="btn btn-gray" :class="{disabled: state !== 'passive'}" @click="forcePostApprovedInvoices" title="Force Post All Invoices">
                    Force Post All Approved
                </loading-button>
            </div>
        </div>
    </div>

    <div class="mt-6">
        <div class="overflow-x-auto">
            <transition
                enter-active-class="transition-height ease-in-out duration-500"
                enter-from-class="h-0"
                enter-to-class="h-full"
                leave-active-class="transition-height ease-in-out duration-500"
                leave-from class="h-full"
                leave-to-class="h-0">
                <div v-show="invoices.data.length && selectedVendorInvoiceIds.length > 0" class="bg-white flex h-10 items-center sm:left-14">
                    <span v-if="selectedVendorInvoiceIds.length === 1" class="mr-8">{{  `${selectedVendorInvoiceIds.length} item selected` }}</span>
                    <span v-else class="mr-8">{{  `${selectedVendorInvoiceIds.length} items selected` }}</span>

                    <div class="flex space-x-3">
                        <button type="button" @click="approveCheckedInvoices" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyAuditedSelected">Approve</button>
                        <button type="button" @click="postCheckedInvoices" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyApprovedSelected">Post</button>
                        <button type="button" @click="archiveInvoices" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyNonArchivedSelected">Archive</button>
                        <button type="button" @click="unarchiveInvoices" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyArchivedSelected">Unarchive</button>

                        <!--Used a form action here because we don't want inertia to handle the redirect for the zip download-->
                        <form :action="$route('vendor-invoices.bulk-download-selected-pdf')" method="POST">
                            <input type="hidden" name="_token" :value="csrfToken">
                            <input v-for="(vendor_invoice_id, index) in selectedVendorInvoiceIds" type="hidden" :name="`vendor_invoice_ids[${index}]`" :value="vendor_invoice_id">
                            <button type="submit" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="selectedVendorInvoiceIds.length === 0">Download</button>
                        </form>
                    </div>
                </div>
            </transition>

            <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <th scope="col" class="relative px-7 sm:w-12 sm:px-6">
                            <input type="checkbox" :disabled="!invoices.data.length" class="absolute left-4 top-1/2 -mb-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :checked="invoices.data.length && (indeterminate || selectedVendorInvoiceIds.length === invoices.data.length)" :indeterminate="indeterminate" @change="selectedVendorInvoiceIds = $event.target.checked ? invoices.data.map((invoice) => invoice.id) : []" />
                        </th>
                        <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">PDF</th>
                        <sortable-table-header v-show="form.selected_headers.includes('Vendor')" field="vendors.name" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Vendor</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Account Number')" field="account_number" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Account Number</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Invoice Date')" field="invoice_date" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Invoice Date</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Fiscal Period')" field="fiscal_period" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Fiscal Period</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Invoice Number')" field="invoice_number" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Invoice Number</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Total Charges')" field="total_current_charges" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Total Charges</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Adjusted Charges')" field="adjusted_total_current_charges" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Adjusted Charges</sortable-table-header>
                        <th v-show="form.selected_headers.includes('Adjustment Amount')" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Adjustment Amount</th>
                        <sortable-table-header v-show="form.selected_headers.includes('Status')" field="status" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Status</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Created Date')" field="vendor_invoices.created_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Created Date</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Approved Date')" field="vendor_invoices.approved_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Approved Date</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Posted Date')" field="vendor_invoices.posted_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Posted Date</sortable-table-header>
                        <sortable-table-header  v-show="form.selected_headers.includes('Archived At')" field="vendor_invoices.archived_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Archived At</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Auto Approved')" field="vendor_invoices.auto_approved" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-invoices.index">Auto Approved</sortable-table-header>
                        <th class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 w-1/12">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="invoice in invoices.data" :key="invoice.id">
                        <td class="relative px-7 sm:w-12 sm:px-6">
                            <div v-if="selectedVendorInvoiceIds.includes(invoice.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                            <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="invoice.id" v-model="selectedVendorInvoiceIds" />
                        </td>
                        <td>
                            <a :href="$route('vendor-invoices.pdf', invoice.id)" class="flex items-center text-2xl" target="_blank">
                                <icon name="file-pdf" class="inline fill-current text-red-500 hover:text-red-500 w-5 h-5" />
                            </a>
                        </td>
                        <td v-show="form.selected_headers.includes('Vendor')">
                            <inertia-link data-cy="vendor-invoices.index.show-link" :href="$route('vendors.show', invoice.vendorAccount.vendor_id)" class="link" :title="invoice.vendorAccount.vendor.name ?? ''">
                                {{ invoice.vendorAccount.vendor.name ?? ''}}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Account Number')">
                            <inertia-link :href="$route('vendor-invoices.index', { search: invoice.vendorAccount.account_number })" class="link" :title="invoice.vendorAccount.account_number">
                                {{ invoice.vendorAccount.account_number }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Invoice Date')">
                            {{ $filters.format_date(invoice.invoice_date) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Fiscal Period')">
                            {{ $filters.format_date(invoice.fiscal_period) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Invoice Number')">{{ invoice.invoice_number }}</td>
                        <td v-show="form.selected_headers.includes('Total Charges')" class="text-right">{{ $filters.format_money(invoice.total_current_charges) }}</td>
                        <td v-show="form.selected_headers.includes('Adjusted Charges')" class="text-right">{{ $filters.format_money(invoice.adjusted_total_current_charges) }}</td>
                        <td v-show="form.selected_headers.includes('Adjustment Amount')" class="text-right">{{ $filters.format_money(subtract_money(invoice.adjusted_total_current_charges, invoice.total_current_charges)) }}</td>
                        <td v-show="form.selected_headers.includes('Status')">
                            {{ invoice.status }}
                        </td>
                        <td v-show="form.selected_headers.includes('Created Date')">
                            {{ $filters.format_date(invoice.created_at) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Approved Date')">
                            {{ $filters.format_date(invoice.approved_at) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Posted Date')">
                            {{  $filters.format_date(invoice.posted_at)  }}
                        </td>
                        <td v-show="form.selected_headers.includes('Archived At')">
                            {{ $filters.format_date_time(invoice.archived_at) }}
                        </td>
                        <td v-show="form.selected_headers.includes('Auto Approved')">
                            {{ invoice.auto_approved ? 'Yes' : 'No' }}
                        </td>
                        <td class="text-right">
                            <inertia-link :href="$route('vendor-invoices.edit', [invoice.id])" class="link" title="Edit This Vendor Invoice">
                                Edit
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="invoices.links" />
    </div>

    <div v-if="!invoices.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="file-invoice" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Invoices Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #body>
            <form @submit.prevent id="vendor-invoice-filter-form" name="vendor-invoice-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
                <div class="col-span-1">
                    <select-input label="Filter by Date Type" v-model="form.date_type">
                        <option value="approved_at">Approved Date</option>
                        <option value="created_at">Created Date</option>
                        <option value="fiscal_period">Fiscal Period</option>
                        <option value="invoice_date">Invoice Date</option>
                        <option value="posted_at">Posted Date</option>
                    </select-input>
                </div>

                <div class="col-span-1">
                    <date-input v-if="form.date_type === 'invoice_date'" label="After Invoice Date" v-model="form.after_invoice_date" />
                    <date-input v-if="form.date_type === 'fiscal_period'"label="After Fiscal Period Date" v-model="form.after_fiscal_period" />
                    <date-input v-if="form.date_type === 'created_at'" label="After Created Date" v-model="form.after_created_at" />
                    <date-input v-if="form.date_type === 'approved_at'" label="After Approved Date" v-model="form.after_approved_at" />
                    <date-input v-if="form.date_type === 'posted_at'" label="After Posted Date" v-model="form.after_posted_at" />
                </div>

                <div class="col-span-1">
                    <date-input v-if="form.date_type === 'invoice_date'" label="Before Invoice Date" v-model="form.before_invoice_date" />
                    <date-input v-if="form.date_type === 'fiscal_period'" label="Before Fiscal Period Date" v-model="form.before_fiscal_period" />
                    <date-input v-if="form.date_type === 'created_at'" label="Before Created Date" v-model="form.before_created_at" />
                    <date-input v-if="form.date_type === 'approved_at'" label="Before Approved Date" v-model="form.before_approved_at" />
                    <date-input v-if="form.date_type === 'posted_at'" label="Before Posted Date" v-model="form.before_posted_at" />
                </div>

                <div class="col-span-1">
                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel for="statuses" class="block font-medium leading-6 text-gray-900">Invoice Status</ComboboxLabel>

                        <div class="relative">
                            <div class="form-input-wrapper">
                                <ComboboxInput
                                    name="statuses"
                                    id="statuses"
                                    class="form-input"
                                    @change="statusComboBoxQuery = $event.target.value"
                                    :display-value="statusComboDisplayValue"
                                />
                            </div>

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <icon name="heroicon-selector" class="size-5 text-gray-400 fill-current" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredStatusOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredStatusOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                        {{ option }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <icon name="heroicon-check" class="size-5 fill-current" />
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <select-input id="adjustments" name="adjustments" class="col-span-1" v-model="form.adjustments" label="Adjustments">
                    <option value="except">Hide Adjusted</option>
                    <option value="include">Include Adjusted</option>
                    <option value="only">Only Adjusted</option>
                </select-input>

                <select-input id="archived" name="archived" class="col-span-1" v-model="form.archived" label="Archived">
                    <option value="except">Hide Archived</option>
                    <option value="include">Include Archived</option>
                    <option value="only">Only Archived</option>
                </select-input>

                <select-input id="auto-approved" name="auto-approved" class="col-span-1" v-model="form.auto_approved" label="Auto Approved">
                    <option value="except">Hide Auto Approved</option>
                    <option value="include">Show All</option>
                    <option value="only">Show Only Auto Approved</option>
                </select-input>
            </form>
        </template>
    </slide-over>
</template>

<script>
    // Import Methods
    import axios from 'axios';
    import { throttle, truncate } from 'lodash-es';
    import { filteredStatusOptions } from './Computed';
    import { statusComboDisplayValue } from './Methods';
    import { subtract_money } from "@/Shared/Utils/Money.js";
    import { has_search_filters } from '@/Shared/Utils/Filters.js';

    // Import Components
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import DateInput from '@/Shared/DateInput.vue';
    import SlideOver from '@/Shared/SlideOver.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import IndexSearchForm from "@/Shared/IndexSearchForm.vue";
    import InlineTextInput from '@/Shared/InlineTextInput.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';


    // Tailwind UI combobox
    import {
        Combobox,
        ComboboxInput,
        ComboboxLabel,
        ComboboxButton,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Head,
            Icon,
            DateInput,
            SlideOver,
            Pagination,
            SelectInput,
            LoadingButton,
            ColumnSelector,
            IndexSearchForm,
            InlineTextInput,
            SortableTableHeader,

            //Tailwind UI combobox
            Combobox,
            ComboboxInput,
            ComboboxLabel,
            ComboboxButton,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            invoices: {
                type: Object,
                required: true
            },

            statuses: {
                type: Array,
                required: true
            },

            filters: Object,

            isPosting: {
                type: Boolean,
                required: true,
            },

            isForcePosting: {
                type: Boolean,
                required: true,
            },

            csrfToken: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    statuses: this.filters.statuses,
                    adjustments: this.filters.adjustments,
                    date_type: this.filters.date_type ?? 'created_at',
                    after_invoice_date: this.filters.after_invoice_date,
                    before_invoice_date: this.filters.before_invoice_date,
                    after_fiscal_period: this.filters.after_fiscal_period,
                    before_fiscal_period: this.filters.before_fiscal_period,
                    after_created_at: this.filters.after_created_at,
                    before_created_at: this.filters.before_created_at,
                    after_approved_at: this.filters.after_approved_at,
                    before_approved_at: this.filters.before_approved_at,
                    after_posted_at: this.filters.after_posted_at,
                    before_posted_at: this.filters.before_posted_at,
                    archived: this.filters.archived,
                    auto_approved: this.filters.auto_approved,
                    selected_headers: this.filters.selected_headers,
                },
                otherUsers: [],
                statusComboBoxQuery: '',
                state: 'passive',
                selectedVendorInvoiceIds: [],
                mounted: false,
                headers: [],
                excludedHeaders: ['PDF', 'Actions'],
                filtersInUse: 0,
            }
        },

        mounted() {
            if (this.isPosting) {
                this.state = 'posting-approved';
            } else if (this.isForcePosting) {
                this.state = 'force-posting-approved';
            }

            Echo.join(`vendor_invoices.index.${this.$page.props.authUser.current_tenant_id}`)
                // Presence
                .here((users) => {
                    this.otherUsers = users;
                })
                .joining((user) => {
                    this.otherUsers.push(user);
                })
                .leaving((user) => {
                    this.otherUsers = this.otherUsers.filter(otherUser => otherUser.id !== user.id);
                })

                // Posting
                .listen('.App\\Events\\VendorInvoice\\PostInvoicesJobHasStarted', (e) => {
                    this.state = 'posting-approved';
                })
                .listen('.App\\Events\\VendorInvoice\\PostInvoicesJobHasFinished', (e) => {
                    this.$inertia.reload({
                        preserveScroll: true,
                        only: ['invoices'],
                        onSuccess: () => {
                            this.state = 'passive';
                            this.$toast.success('Invoice has been successfully posted to Accounting.');
                        },
                    })
                })

                // Force Posting
                .listen('.App\\Events\\VendorInvoice\\ForcePostInvoicesJobHasStarted', (e) => {
                    this.state = 'force-posting-approved';
                })
                .listen('.App\\Events\\VendorInvoice\\ForcePostInvoicesJobHasFinished', (e) => {
                    this.$inertia.reload({
                        preserveScroll: true,
                        only: ['invoices'],
                        onSuccess: () => {
                            this.state = 'passive';
                            this.$toast.success('Invoice has been successfully posted to Accounting.');
                        },
                    })
                });

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0)
                this.form.selected_headers = this.headers;

            this.mounted = true;
            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        unmounted() {
            Echo.leave(`vendor_invoices.index.${this.$page.props.authUser.current_tenant_id}`);
        },

        methods: {
            subtract_money,
            truncate,

            exportData() {
                this.state = 'exporting';

                this.$inertia.post(this.$route('csv.vendor-invoices.index'), this.queryFilters, {
                    onFinish: () => { this.state = 'passive'; }
                });
            },

            postApprovedInvoices() {
                if (this.invoices.total !== 0 && this.state === 'passive') {
                    this.state = 'posting-approved';

                    this.$inertia.post(this.$route('vendor-invoices.post-all'), {}, {
                        onSuccess: () => {
                            //
                        }
                    });
                }
            },

            forcePostApprovedInvoices() {
                if (this.invoices.total !== 0 && this.state === 'passive') {
                    this.state = 'force-posting-approved';

                    this.$inertia.post(this.$route('vendor-invoices.force-post-all'), {}, {
                        onSuccess: () => {
                            //
                        }
                    });
                }
            },

            approveCheckedInvoices() {
                if (this.selectedVendorInvoiceIds.length !== 0 && this.state === 'passive') {
                    this.$inertia.post(this.$route('vendor-invoices.approve-checked'), {
                        vendor_invoice_ids: this.selectedVendorInvoiceIds,
                    }, {
                        preserveState: true,
                        onSuccess: () => {
                            this.selectedVendorInvoiceIds = [];
                        }
                    });
                }
            },

            postCheckedInvoices() {
                if (this.selectedVendorInvoiceIds.length !== 0 && this.state === 'passive') {
                    this.state = 'posting-checked';

                    axios.post(this.$route('vendor-invoices.post-checked'), {ids: this.selectedVendorInvoiceIds})
                        .then(response => {
                            this.$toast.success(response.data.success_message);
                        })
                        .catch(error => {
                            if (error.response.data) {
                                let failedInvoices = error.response.data.invoices ?? [];

                                if (failedInvoices.length == this.selectedVendorInvoiceIds.length) {
                                    this.$toast.error(error.response.data.error_message);
                                } else {
                                    this.$toast.warning(error.response.data.error_message);
                                }

                                failedInvoices.forEach(invoice => {
                                    let existingInvoice = this.invoices.data.find(item => item.id == invoice.id);
                                    existingInvoice = { ...existingInvoice, ...invoice };
                                });
                            } else {
                                this.$toast.error('Unable to complete your request. Please check the server logs for more information.');
                            }
                        })
                        .finally(response => {
                            this.state = 'passive';
                            this.selectedVendorInvoiceIds = [];
                        });
                }
            },

            archiveInvoices() {
                this.$inertia.post(this.$route('vendor-invoices.archive-selected'), {
                    vendor_invoice_ids: this.selectedVendorInvoiceIds,
                }, {
                    onSuccess: () => this.selectedVendorInvoiceIds = []
                });
            },

            unarchiveInvoices() {
                this.$inertia.post(this.$route('vendor-invoices.unarchive-selected'), {
                    vendor_invoice_ids: this.selectedVendorInvoiceIds,
                }, {
                    onSuccess: () => this.selectedVendorInvoiceIds = []
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));

                this.headers = headers;
            },

            statusComboDisplayValue,

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('vendor-invoices.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        watch: {
            'form.date_type'(newValue, oldValue) {
                switch (oldValue) {
                    case 'invoice_date':
                        var afterDate = this.form.after_invoice_date;
                        this.form.after_invoice_date = null;
                        var beforeDate = this.form.before_invoice_date;
                        this.form.before_invoice_date = null;
                        break;
                    case 'fiscal_period':
                        var afterDate = this.form.after_fiscal_period;
                        this.form.after_fiscal_period = null;
                        var beforeDate = this.form.before_fiscal_period;
                        this.form.before_fiscal_period = null;
                        break;
                    case 'created_at':
                        var afterDate = this.form.after_created_at;
                        this.form.after_created_at = null;
                        var beforeDate = this.form.before_created_at;
                        this.form.before_created_at = null;
                        break;
                    case 'approved_at':
                        var afterDate = this.form.after_approved_at;
                        this.form.after_approved_at = null;
                        var beforeDate = this.form.before_approved_at;
                        this.form.before_approved_at = null;
                        break;
                    case 'posted_at':
                        var afterDate = this.form.after_posted_at;
                        this.form.after_posted_at = null;
                        var beforeDate = this.form.before_posted_at;
                        this.form.before_posted_at = null;
                        break;
                }

                switch (newValue) {
                    case 'invoice_date':
                        this.form.after_invoice_date = afterDate;
                        this.form.before_invoice_date = beforeDate;
                        break;
                    case 'fiscal_period':
                        this.form.after_fiscal_period = afterDate;
                        this.form.before_fiscal_period = beforeDate;
                        break;
                    case 'created_at':
                        this.form.after_created_at = afterDate;
                        this.form.before_created_at = beforeDate;
                        break;
                    case 'approved_at':
                        this.form.after_approved_at = afterDate;
                        this.form.before_approved_at = beforeDate;
                        break;
                    case 'posted_at':
                        this.form.after_posted_at = afterDate;
                        this.form.before_posted_at = beforeDate;
                        break;
                }
            }
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            filteredStatusOptions,

            indeterminate() {
                return this.selectedVendorInvoiceIds.length > 0 && this.selectedVendorInvoiceIds.length < this.invoices.data.length;
            },

            onlyAuditedSelected() {
                return this.selectedVendorInvoiceIds.length > 0
                    && this.selectedVendorInvoiceIds.length === this.invoices.data.length
                    && this.invoices.data.every(vendorInvoice => vendorInvoice.audited_at);
            },

            onlyApprovedSelected() {
                return this.selectedVendorInvoiceIds.length > 0
                    && this.selectedVendorInvoiceIds.length === this.invoices.data.length
                    && this.invoices.data.every(vendorInvoice => vendorInvoice.status === 'Approved');
            },

            onlyNonArchivedSelected() {
                return this.selectedVendorInvoiceIds.every((id) => {
                    return !this.invoices.data.find((invoice) => invoice.id === id)?.archived_at;
                });
            },

            onlyArchivedSelected() {
                return this.selectedVendorInvoiceIds.every((id) => {
                    return this.invoices.data.find((invoice) => invoice.id === id)?.archived_at;
                });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.statuses,
                    form.adjustments,
                    form.date_type,
                    form.after_approved_at,
                    form.before_approved_at,
                    form.after_created_at,
                    form.before_created_at,
                    form.after_fiscal_period,
                    form.before_fiscal_period,
                    form.after_invoice_date,
                    form.before_invoice_date,
                    form.after_posted_at,
                    form.before_posted_at,
                    form.archived,
                    form.auto_approved,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>
