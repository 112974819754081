<template>
    <div :class="$attrs.class">
        <label v-if="label" class="form-label" :for="id">
            <span v-if="markAsRequired" class="text-red-500">*</span> {{ label }}
        </label>

        <div class="input-group">
            <div class="input-group-item-left">
                <icon :name="currencyIcon" class="fill-current text-gray-500 size-4" />
            </div>

            <input type="text" v-bind="{...$attrs, class: null}" :value="displayValue" placeholder="0.00" class="input-group-field" :class="{ error: errors }" @blur="updateAmount">

            <div class="input-group-item-right">
                <span class="text-gray-500 sm:text-sm">{{ defaultCurrency }}</span>
            </div>
        </div>

        <p v-if="helpText" class="form-help-text">{{ helpText }}</p>

        <div v-if="errors" class="form-error-text">{{ errors }}</div>
    </div>
</template>

<script>
    import { v4 as uuid } from 'uuid';

    import Icon from '@/Shared/Icon.vue';
    import SelectInput from "./SelectInput.vue";

    export default {
        inheritAttrs: false,

        props: {
            id: {
                type: String,
                default() {
                    return `money-input-${uuid()}`;
                },
            },

            modelValue: {
                required: true
            },

            label: String,

            helpText: {
                type: String,
                default: ''
            },

            errors: {
                type: String,
                default: ''
            },

            markAsRequired: {
                type: Boolean,
                default: false
            },

            defaultCurrency: {
                type: String,
            },

            min: {
                type: Number,
                default: null
            },

            max: {
                type: Number,
                default: null
            },

            forceWholeNumbers: {
                type: Boolean,
                default: false
            }
        },

        emits: [
            'update:modelValue',
            'updated'
        ],

        data() {
            let options = ['USD', 'CAD', 'EUR'];
            return {
                floatAmount: this.modelValue && this.modelValue.amount !== null ? parseInt(this.modelValue.amount, 10) / 100 : null,
                amount: this.modelValue && this.modelValue.amount !== null ? parseInt(this.modelValue.amount, 10) : null,
                currency: this.modelValue && this.modelValue.currency ? this.modelValue.currency : this.defaultCurrency,
                options,
                selected: options[this.modelValue && this.modelValue.currency ? this.modelValue.currency : this.defaultCurrency]
            };
        },

        methods: {
            updateAmount(e) {
                let newValue = null;
                let newAmount = null;
                let floatAmount = this.sanitize(e.target.value);

                if (floatAmount !== null) {
                    newAmount = (floatAmount * 100).toFixed(0);

                    // enforce min value
                    if (this.min !== null && newAmount < this.min) {
                        newAmount = this.min;
                    }
                    // enforce max value
                    if (this.max !== null && newAmount > this.max) {
                        newAmount = this.max;
                    }

                    newValue = {amount: newAmount, currency: this.currency};
                }

                // Update our data
                this.floatAmount = floatAmount;
                this.amount = newAmount;

                this.$emit('update:modelValue', newValue);   // Emit and update event for use of v-model with this component.
                this.$emit('updated', newValue);
            },

            sanitize(value) {
                if (!value) {
                    return null;
                }

                // Remove all non-numeric characters except for decimal point
                let sanitized = value.replace(/[^0-9.-]/g, '');

                // convert our sanitized text to a float
                if (sanitized === '0' || sanitized === '0.00') {
                    return 0;
                } else if (sanitized || sanitized === '0') {
                    let floatValue = parseFloat(sanitized);

                    // round the float to the nearest whole number if we are forcing whole numbers
                    if (this.forceWholeNumbers && !Number.isNaN(floatValue)) {
                        return Math.round(floatValue);
                    }

                    if ( ! Number.isNaN(floatValue)) {
                        return floatValue;
                    }
                }

                return null;
            }
        },

        watch: {
            // whenever value changes, this function will update our data fields.
            modelValue: function (newValue, oldValue) {
                this.floatAmount = newValue && newValue.amount !== null ? parseInt(newValue.amount, 10) / 100 : null;
                this.amount = newValue && newValue.amount !== null ? parseInt(newValue.amount, 10) : null;
                this.currency = newValue && newValue.currency ? newValue.currency : this.defaultCurrency
            },
            'this.defaultCurrency'(newValue, oldValue) {
                this.currency = newValue;
            }
        },

        computed: {
            currencyIcon() {
                switch(this.currency) {
                    case 'USD':
                        return 'dollar-sign';
                    case 'EUR':
                        return 'euro-sign'
                    case 'CAD':
                        return 'dollar-sign'
                    default:
                        console.error(`Unknown currency type ${this.currency}`);
                        return '';
                    }
            },

            displayValue() {
                if(this.forceWholeNumbers) {
                    return (this.floatAmount !== null) ? this.floatAmount.toFixed(0) : null;
                }
                return (this.floatAmount !== null) ? this.floatAmount.toFixed(2) : null;
            }
        },

        components: {
            SelectInput,
            Icon
        }
    }
</script>
